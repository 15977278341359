function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.95em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${color} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.95em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${color} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.95em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${color} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.95em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${color}`
      }
    }
  };
}

const toolbarStyles = theme => ({
  root: {
    width: "100%",
  },
  // monthlyDataButton: {
  //   margin: "0 auto",
  //   width: "650px",
  //   maxHeight: "40px",
  //   padding: "auto",
  //   verticalAlign: "center",
  //   textAlign: "center",
  //   borderRadius: "5px",
  //   cursor: "pointer"
  // },
  monthSelectorPaper: {
    margin: "0 auto",
    width: "650px",
    height: "50px",
    maxHeight: "50px",
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "baseline",
    borderRadius: "5px",
    background: "#FFC20F"
  },
  todaysData: {
    marginRight: "12px",
    width: "200px",
    paddingTop: "6px",
    verticalAlign: "center",
    textAlign: "center",
    borderRadius: "10px",
    cursor: "pointer"
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.text.main,
          backgroundColor: "#FFC20F"
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },

  spacer: {
    flex: "1 2 100%"
  },
  spacerCurrentMonth: {
    flex: "1 2 100%"
  },

  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  },

  //Table Toolbar Icon styles
  tableToolbarIcon: {
    width: 24,
    height: 24,
    borderRadius: "50%"
  },

  /* Style for the CSV download icon when selected */
  csvDownloadSingle: {
    margin: "auto 0px",
  },

  csvDownloadMultiple: {
    marginRight: "48px",
    paddingTop: "12px"
  },

  buttonInvitation: {
    margin: theme.spacing.unit,
    width: "200px",
    border: "2px solid"
  },

  textFieldInvitation: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },

  rootTabsTable: {
    paddingRight: 0,
    width: "100%",
    overflowX: "auto"
  },

  button: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },

  rootPaperOne: {
    padding: theme.spacing.unit * 2,
    marginBottom: 10,
    width: "100%"
  },

  rootPaperTwo: {
    flexGrow: 1,
    //maxWidth: 1319,
    backgroundColor: theme.palette.background.paper
  },

  tabsAppBar: {
    width: 1335,
    maxWidth: 1335,
    minWidth: 500
  },

  grid: {
    width: "20%"
  },

  dateFromAndTo: {
    display: "flex"
  },

  textFieldPort: {
    marginLeft: theme.spacing.unit * 7,
    marginRight: theme.spacing.unit * 7,
    width: 300
  },

  menu: {
    width: 200
  },

  buttonMargin: {
    margin: "auto",
    width: 200,
    height: 40
  },

  portNameText: {
    margin: "auto"
  },

  selectedMonthDatePicker: {
    marginLeft: theme.spacing.unit,
    background: "#a4a3a5",
    width: 150,
  },

  textFieldSelectedMonth: {
    textAlign: "center",
  },

  othersTablePaper: {
    position: "fixed",
    width: "91.7%",
    overflowX: "auto"
  },

});

export default toolbarStyles;

/*
  1> Explanation of < flex: 1 1 100% > - With this I am making 'spacer' to stay full-width of the available space. And then adding two 'spacer' class to both side of the '{currentMonth} month's Exports' to make the currentMonth get centered within the available space

  A) The 100% in the above is the 'flex-basis', a sub-property of the Flexible Box Layout module. It specifies the initial size of the flexible item, before any available space is distributed according to the flex factors.

  B) flex - This is the shorthand for flex-grow, flex-shrink and flex-basis combined. Defaults is 0 1 auto.

  B) In other words, the general syntax -
  flex : flex-grow | flex-shrink | flex-basis
  (https://developer.mozilla.org/en-US/docs/Web/CSS/flex)

  C) The general way flex work - I need to add display:flex to the parent tag and then flex:1 to the child to enable the child to expand to 100% of parent.

  */
