let styles = theme => ({
    topSpacing: {
      marginTop: 20,
    },
    table: {
      border: "1px solid black",
    },
    tableCellColorBlackAndBorder: {
        paddingLeft: "0px",
        paddingRight: "0px",
        borderBottom: "1px solid white",
        borderRight: "1px solid white",
        background: "#000",
        color: "#fff",
    },
    tableCellColorGreyAndBorder: {
        paddingLeft: "0px",
        paddingRight: "0px",
        borderBottom: "1px solid white",
        borderRight: "1px solid white",
        background: "#414141",
        color: "#fff",
        width: "auto"
    },
    tableCellColorWhiteAndBorder: {
        padding: "0px",
        borderBottom: "1px solid white",
        borderRight: "1px solid white",
        background: "#c1c1c1",
        color: "#000",
        width: "auto",
        whiteSpace: "wrap"
    },
    tableCellColorGreyAndBorderAndPadding: {
        padding: "0px",
        borderBottom: "1px solid white",
        borderRight: "1px solid white",
        background: "#414141",
        color: "#fff",
    },

    tableCellBottomRightBorder: {
        padding: "0px",
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
    },
});

export default styles;
