import React from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import {styles} from "../commonStyles/ModuleItemListStyles";
import toolbarStyles from "../commonStyles/toolbarStyles";
import combineStyles from "../commonStyles/combineStyles";
import {withStyles} from "@material-ui/core";

// make sure the 'tableHeaderProp' below is the property name of the mongodb schema's property name of the relevant model
const rows = [
    {
        tableHeaderProp: "name",
        disablePadding: true,
        label: "Commodity Name"
    },
    {
        tableHeaderProp: "type",
        disablePadding: false,
        label: "Commodity Type"
    },
    {
        tableHeaderProp: "category",
        disablePadding: false,
        label: "Commodity Category"
    }
];

class TableHeadCommodity extends React.Component {
    state = {};

    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {
            onSelectAllClick,
            order,
            orderBy,
            numSelected,
            page,
            rowsPerPage,
            count,
            noOfItemsInCurrentPage
        } = this.props;

        const {classes} = this.props;

        return (
            <TableHead>
                <TableRow>
                    <Tooltip title={noOfItemsInCurrentPage > 1 ? `Select all ${noOfItemsInCurrentPage}` : `Select the item `}>
                        <TableCell padding="checkbox">
                            <Checkbox
                                indeterminate={
                                    page !== Math.max((0, Math.ceil(count / rowsPerPage) - 1))
                                        ? numSelected > 0 && numSelected < rowsPerPage
                                        : numSelected > 0 && numSelected < noOfItemsInCurrentPage
                                }
                                checked={
                                    noOfItemsInCurrentPage === 0
                                        ? false
                                        : page !== Math.max(0, Math.ceil(count / rowsPerPage) - 1)
                                        ? numSelected === rowsPerPage
                                        : noOfItemsInCurrentPage < rowsPerPage
                                            ? numSelected === noOfItemsInCurrentPage
                                            : numSelected === rowsPerPage
                                }
                                onChange={onSelectAllClick}
                            />
                        </TableCell>
                    </Tooltip>
                    {rows.map(
                        row => (
                            <TableCell
                                className={classes.row}
                                key={row.tableHeaderProp}
                                align="center"
                                padding={row.disablePadding ? "none" : "default"}
                                sortDirection={orderBy === row.tableHeaderProp ? order : false}
                            >
                                <Tooltip title={<h6>Sort by {row.label}</h6>}>
                                    <TableSortLabel
                                        active={orderBy === row.tableHeaderProp}
                                        direction={order}
                                        onClick={this.createSortHandler(row.tableHeaderProp)}
                                    >
                                        <Typography variant="h3">{row.label}</Typography>
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        ),
                        this
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

TableHeadCommodity.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired
};

const combinedStyles = combineStyles(styles, toolbarStyles);

export default withStyles(combinedStyles)(TableHeadCommodity);

/* The expresseion < Math.max(0, Math.ceil(count / rowsPerPage) - 1) > is the index value of the variable 'page' ONLY when I am on the last page. So the condition //#endregion
< page === Math.max(0, Math.ceil(count / rowsPerPage) - 1) >  WILL ONLY BE TRUE on the last page.


Note, - The variable 'page' is a built-in prop of TablePagination API (https://material-ui.com/api/table-pagination/) - and the value of 'page' is a zero-based index of the current page.
 */
