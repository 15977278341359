import React from "react";
import history from "../../history";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import {withStyles} from "@material-ui/core/styles";
import {
    AppBar,
    Collapse,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    // MenuItem,
    MenuList,
    Paper,
    Switch,
    Toolbar,
    Typography
} from "@material-ui/core";
import Popover from '@material-ui/core/Popover';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {ExpandLess, ExpandMore, Menu} from "@material-ui/icons";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AllRoutes from "../../Routes/DashboardRoutes";
import axios from "axios";
import setAxiosAuthheaders from "../DashBoardViewComponents/UtilFunctions/setAxiosAuthheaders";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Logout from "@material-ui/icons/ExitToApp";
// import ExitToApp from "@material-ui/icons/ExitToApp";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import PortLogo from "../../Images/ApLogo/portlogo.png";
import DashBoardIcon from "../../Images/Icons/dashboard.png";
import AnalyticsIcon from "../../Images/Icons/analytics.png";
import ReportsIcon from "../../Images/Icons/reports.png";
import KpiIcon from "../../Images/Icons/kpiIcon.png";
import AdminIcon from "../../Images/Icons/admin.png";
import PortsIcon from "../../Images/Icons/ports.png";
// import UsersIcon from "../../Images/Icons/users.png";
import ImportsIcon from "../../Images/Icons/imports.png";
import ExportsIcon from "../../Images/Icons/exports.png";
import ImportsExportsIcon from "../../Images/Icons/importExport.png";
import RevenueIcon from "../../Images/Icons/revenue.png";
import OthersIcon from "../../Images/Icons/others.png";
// import OtherVesselsIcon from "../../Images/Icons/other-vessels.png";
// import VesselDataIcon from "../../Images/Icons/vessel-data.png";
// import BerthThroughPutIcon from "../../Images/Icons/berth-through-put.png";
// import BerthOccupancyIcon from "../../Images/Icons/berth-occupancy.png";
// import BerthingTurnAroundTimeIcon from "../../Images/Icons/turn-around-time.png";
// import TruckingTripIcon from "../../Images/Icons/trucking-trips.png";
// import ParcelSizeIcon from "../../Images/Icons/parcel-size.png";
// import AvgPreBerthingWaitingIcon from "../../Images/Icons/average-pre-berth-waiting.png";
// import DevelopmentWorksIcon from "../../Images/Icons/development-works.png";
// import IncomeAndPortDuesIcon from "../../Images/Icons/income-port-dues.png";
// import OnBoardPortsIcon from "../../Images/Icons/on-board-port.png";
import OptionsIcon from "../../Images/Icons/options.png";
import CommoditiesIcon from "../../Images/Icons/commodities.png";
// import VesselTypesIcon from "../../Images/Icons/vessel-types.png";

const drawerWidth = 280;

const styles = theme => ({
    root: {
        display: "flex"
    },
    appRoot: {
        flexGrow: 1
    },
    title: {
        flexGrow: 1
    },
    profileNameIcon: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        marginRight: 10
    },
    fontSizeStyle: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        marginRight: 10
    },
    profileIconButton: {
        width: 274,
        borderRadius: 0,
        padding: "0 12px"
    },
    profileButtonIconText: {
        marginLeft: 10
    },
    myAccountIconLight: {
        paddingRight: "15px",
        fontSize: "35px",
        height: "20px",
        color: "#000000"
    },
    myAccountIconDark: {
        paddingRight: "15px",
        fontSize: "35px",
        height: "20px",
        color: "#ffffff"
    },
    portIconLight: {
        paddingRight: "15px",
        fontSize: "30px",
        height: "20px",
        color: "#000000"
    },
    portIconDark: {
        paddingRight: "15px",
        fontSize: "30px",
        height: "20px",
        color: "#ffffff"
    },
    logoutIcon: {
        paddingRight: "15px",
        fontSize: "30px",
        height: "20px"
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        height: 50,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    toolBar: {
        minHeight: 0,
        height: 50,
        padding: 0,
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 24
    },
    hide: {
        display: "none"
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap"
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
        width: theme.spacing.unit * 9,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing.unit * 9
        }
    },
    toolbar: {
        height: 49,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "8px 16px",
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        width: "100%",
        padding: theme.spacing.unit * 3
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4
    },
    nestedProfile: {
        paddingLeft: theme.spacing.unit * 2
    },
    menuList: {
        position: "relative",
        right: 80
    },
    sideNavBarImageContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        // justifyContent: "flex-start",
        // margin: "auto auto auto 10px"
    },
    sideNavBarImage: {
        height: 24,
        width: 24,
        marginRight: 30
    },
    sideNavBarText: {
        marginBottom: 0
    },
    sideNavBarIcon: {
        height: 24,
        width: 24,
    },
    itemExpandIcon: {
        margin: 8
    }
});

class SiteDesign extends React.Component {
    state = {
        open: false,
        openDashboard: false,
        openAdmin: false,
        openOptions: false,
        openWebAdmin: false,
        allCommoditiesForSiblingCommunication: [],
        // allVesselTypesForSiblingCommunication: [],
        // allVesselDataForSiblingCommunication: [],
        auth: this.props.auth !== null,
        anchorEl: null
    };

    componentWillMount() {
        setAxiosAuthheaders();
    }

    setCommodityForSiblingCommunication = commodity => {
        this.setState({
            allCommoditiesForSiblingCommunication: commodity
        });
    };

    // setVesselTypeForSiblingCommunication = vesselType => {
    //     this.setState({
    //         allVesselTypesForSiblingCommunication: vesselType
    //     });
    // };

    // setVesselDataForSiblingCommunication = vesselData => {
    //     this.setState({
    //         allVesselDataForSiblingCommunication: vesselData
    //     });
    // };

    componentDidMount() {
        // axios
        //     .all([axios.get("/api/vesseltype"), axios.get("/api/commodity"), axios.get("/api/vesselData")])
        //     .then(
        //         axios.spread((getVesselTypes, getCommodity, getVesselData) => {
        //             this.setState({
        //                 allVesselTypesForSiblingCommunication: getVesselTypes.data,
        //                 allCommoditiesForSiblingCommunication: getCommodity.data,
        //                 allVesselDataForSiblingCommunication: getVesselData.data
        //             });
        //         })
        //     )

        axios.get("/api/commodity")
            .then(getCommodity => {
                this.setState({
                    allCommoditiesForSiblingCommunication: getCommodity.data
                });
            }).catch(error => {
            if (error.response.status === 401) {
                history.push("/login");
                alert("Please Login, session expired");
            } else {
                alert("Oops something wrong happened, please try again");
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            // this.state.allVesselTypesForSiblingCommunication.length !==
            // prevState.allVesselTypesForSiblingCommunication.length ||
            this.state.allCommoditiesForSiblingCommunication.length !==
            prevState.allCommoditiesForSiblingCommunication.length
            // this.state.allVesselDataForSiblingCommunication.length !==
            // prevState.allVesselDataForSiblingCommunication.length
        ) {
            // axios
            //     .all([axios.get("/api/vesseltype"), axios.get("/api/commodity"), axios.get("/api/vesselData")])
            //     .then(
            //         axios.spread((getVesselTypes, getCommodity, getVesselData) => {
            //             this.setState({
            //                 allVesselTypesForSiblingCommunication: getVesselTypes.data,
            //                 allCommoditiesForSiblingCommunication: getCommodity.data,
            //                 allVesselDataForSiblingCommunication: getVesselData.data
            //             });
            //         })
            //     )

            axios.get("/api/commodity")
                .then(getCommodity => {
                    this.setState({
                        allCommoditiesForSiblingCommunication: getCommodity.data
                    });
                }).catch(error => {
                    if (error.response.status === 401) {
                        history.push("/login");
                        alert("Please Login, session expired");
                    } else {
                        alert("Oops something wrong happened, please try again");
                    }
                });
        }
    }

    handleDrawerOpen = () => {
        this.setState({
            open: true,
            openAdmin: true
        });
    };

    handleDrawerClose = () => {
        this.setState({
            open: false,
            openDashboard: false,
            openAdmin: false,
            openOptions: false,
            openWebAdmin: false
        });
    };

    handleClickDashboard = () => {
        this.setState(state => ({
            openDashboard: !state.openDashboard,
            openAdmin: false,
            openOptions: false,
            openWebAdmin: false
        }));
    };

    handleClickAdmin = () => {
        this.setState(state => ({
            openAdmin: !state.openAdmin,
            openDashboard: false,
            openOptions: false,
            openWebAdmin: false
        }));
    };

    handleClickOptions = () => {
        this.setState(state => ({
            openOptions: !state.openOptions,
            openAdmin: false,
            openDashboard: false,
            openWebAdmin: false
        }));
    };

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    logout = () => {
        localStorage.removeItem("user");
        this.setState({anchorEl: null});
        history.push("/login");
    };

    render() {
        const open = Boolean(this.state.anchorEl);
        const {
            classes,
            theme,
            children,
            themeType,
            handleThemeTypeChange,
            handleFontSizeSmall,
            handleFontSizeLarge
        } = this.props;
        const {
            allCommoditiesForSiblingCommunication,
            // allVesselTypesForSiblingCommunication,
            // allVesselDataForSiblingCommunication
        } = this.state;

        return (
            <div className={classes.root}>
                <CssBaseline/>
                <div className={classes.appRoot}>
                    <AppBar
                        position="fixed"
                        className={classNames(classes.appBar, {
                            [classes.appBarShift]: this.state.open
                        })}
                    >
                        <Toolbar
                            className={classes.toolBar}
                            disableGutters={!this.state.open}
                        >
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={this.handleDrawerOpen}
                                className={classNames(classes.menuButton, {
                                    [classes.hide]: this.state.open
                                })}
                            >
                                <Menu style={{
                                    width: 24,
                                    height: 24,
                                }}/>
                            </IconButton>
                            {this.state.open ? (
                                <Typography
                                    variant="h1"
                                    color="inherit"
                                    className={classes.title}
                                    noWrap
                                />
                            ) : (
                                <Typography
                                    variant="h1"
                                    color="inherit"
                                    className={classes.title}
                                    noWrap
                                >
                                    AP MARITIME BOARD
                                </Typography>
                            )}
                            <div className={classes.fontSizeStyle}>
                                <Typography
                                    style={{fontSize: 10, cursor: "pointer"}}
                                    onClick={handleFontSizeSmall}
                                >
                                    A
                                </Typography>
                                <Typography style={{padding: "0 5px"}}>|</Typography>
                                <Typography
                                    style={{fontSize: 20, cursor: "pointer"}}
                                    onClick={handleFontSizeLarge}
                                >
                                    A
                                </Typography>
                            </div>
                            <Switch
                                checked={themeType}
                                onChange={handleThemeTypeChange}
                                value={themeType}
                                color="default"
                            />

                            {JSON.parse(localStorage.getItem("user")) &&
                            JSON.parse(localStorage.getItem("user"))["token"] !== null && (
                                <div>
                                    <div className={classes.profileNameIcon}>
                                        <IconButton
                                            position="fixed"
                                            aria-owns={open ? "menu-list-grow" : undefined}
                                            aria-haspopup="true"
                                            onClick={this.handleMenu}
                                            color="inherit"
                                            className={classes.profileIconButton}
                                        >
                                            <img
                                                src={`${
                                                    JSON.parse(localStorage.getItem("user")).imageUrl
                                                }`}
                                                alt=""
                                                style={{
                                                    width: 42,
                                                    height: 42,
                                                    borderRadius: "50%"
                                                }}
                                            />

                                            <Typography
                                                variant="h3"
                                                className={classes.profileButtonIconText}
                                            >
                                                {JSON.parse(localStorage.getItem("user")).name &&
                                                JSON.parse(localStorage.getItem("user"))
                                                    .name.charAt(0)
                                                    .toUpperCase() +
                                                JSON.parse(
                                                    localStorage.getItem("user")
                                                ).name.slice(1)
                                                // + " | " +
                                                // (JSON.parse(localStorage.getItem("user")).port &&
                                                //     JSON.parse(
                                                //         localStorage.getItem("user")
                                                //     ).port.toUpperCase())
                                                }
                                            </Typography>
                                        </IconButton>
                                    </div>

                                    <Popover
                                        open={open}
                                        anchorEl={this.anchorEl}
                                        onClose={this.handleClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                <MenuList>
                                                    <ListItem
                                                        button
                                                        className={classes.nestedProfile}
                                                        component={Link}
                                                        to="/dashboard/profile"
                                                        onClick={this.handleClose}
                                                    >
                                                        <AccountCircle
                                                            className={
                                                                themeType
                                                                    ? classes.myAccountIconDark
                                                                    : classes.myAccountIconLight
                                                            }
                                                        />{" "}
                                                        <Typography variant="h3">
                                                            Profile
                                                        </Typography>
                                                    </ListItem>

                                                    {/*{JSON.parse(localStorage.getItem("user"))*/}
                                                    {/*    .userType === "port-operator" ? (*/}
                                                    {/*    <ListItem*/}
                                                    {/*        button*/}
                                                    {/*        className={classes.nestedProfile}*/}
                                                    {/*        component={Link}*/}
                                                    {/*        to="/dashboard/port"*/}
                                                    {/*        onClick={this.handleClose}*/}
                                                    {/*    >*/}
                                                    {/*        <FontAwesomeIcon*/}
                                                    {/*            icon="ship"*/}
                                                    {/*            className={*/}
                                                    {/*                themeType*/}
                                                    {/*                    ? classes.portIconDark*/}
                                                    {/*                    : classes.portIconLight*/}
                                                    {/*            }*/}
                                                    {/*        />{" "}*/}
                                                    {/*        <Typography variant="h3">*/}
                                                    {/*            Port*/}
                                                    {/*        </Typography>*/}
                                                    {/*    </ListItem>*/}
                                                    {/*) : null}*/}

                                                    <ListItem
                                                        button
                                                        className={classes.nestedProfile}
                                                        component={Link}
                                                        to="/dashboard/profile"
                                                        onClick={this.logout}
                                                    >
                                                        <Logout
                                                            className={
                                                                themeType
                                                                    ? classes.myAccountIconDark
                                                                    : classes.myAccountIconLight
                                                            }
                                                        />{" "}
                                                        <Typography variant="h3">
                                                            Log Out
                                                        </Typography>
                                                    </ListItem>

                                                    {/*<MenuItem*/}
                                                    {/*    onClose={this.handleClose}*/}
                                                    {/*    onClick={this.logout}*/}
                                                    {/*>*/}
                                                    {/*    <ExitToApp style={{paddingRight: "15px"}}/>{" "}*/}
                                                    {/*    <Typography variant="h3">Log Out</Typography>*/}
                                                    {/*</MenuItem>*/}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Popover>
                                </div>
                            )}
                        </Toolbar>
                    </AppBar>
                </div>

                <Drawer
                    variant="permanent"
                    className={classNames(classes.drawer, {
                        [classes.drawerOpen]: this.state.open,
                        [classes.drawerClose]: !this.state.open
                    })}
                    classes={{
                        paper: classNames({
                            [classes.drawerOpen]: this.state.open,
                            [classes.drawerClose]: !this.state.open
                        })
                    }}
                    open={this.state.open}
                >
                    <div className={classes.toolbar} style={{minHeight: 49}}>
                        <div className={classes.sideNavBarImageContainer}>
                            {/*<img*/}
                            {/*    src={PortLogo}*/}
                            {/*    alt="Port Logo"*/}
                            {/*    className={classes.sideNavBarImage}*/}
                            {/*/>*/}
                            <Typography variant="h1" className={classes.sideNavBarText}>
                                AP MB
                            </Typography>
                        </div>
                        <IconButton onClick={this.handleDrawerClose}>
                            {theme.direction === "rtl" ? (
                                <ChevronRightIcon/>
                            ) : (
                                <ChevronLeftIcon/>
                            )}
                        </IconButton>
                    </div>
                    <Divider/>
                    <List>
                        <ListItem button onClick={this.handleClickDashboard}>
                            <ListItemIcon>
                                <img
                                    src={DashBoardIcon}
                                    className={classes.sideNavBarIcon}
                                    alt=""
                                />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard"/>
                            {this.state.openDashboard ? <ExpandLess className={classes.itemExpandIcon}/> :
                                <ExpandMore className={classes.itemExpandIcon}/>}
                        </ListItem>
                        <Collapse
                            in={this.state.openDashboard}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List component="div" disablePadding>
                                <ListItem
                                    button
                                    className={classes.nested}
                                    component={Link}
                                    to="/dashboard/analytics"
                                >
                                    <ListItemIcon>
                                        <img
                                            src={AnalyticsIcon}
                                            className={classes.sideNavBarIcon}
                                            alt=""
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Analytics"/>
                                </ListItem>
                                {JSON.parse(localStorage.getItem("user")) &&
                                JSON.parse(localStorage.getItem("user")).userType ===
                                "port-admin" ? (
                                    <>
                                        <ListItem
                                            button
                                            className={classes.nested}
                                            component={Link}
                                            to="/dashboard/reports"
                                        >
                                            <ListItemIcon>
                                                <img
                                                    src={ReportsIcon}
                                                    className={classes.sideNavBarIcon}
                                                    alt=""
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary="Reports"/>
                                        </ListItem>
                                        <ListItem
                                            button
                                            className={classes.nested}
                                            component={Link}
                                            to="/dashboard/kpi"
                                        >
                                            <ListItemIcon>
                                                <img
                                                    src={KpiIcon}
                                                    className={classes.sideNavBarIcon}
                                                    alt=""
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary="KPIs"/>
                                        </ListItem>
                                    </>
                                ) : null}
                            </List>
                        </Collapse>

                        {JSON.parse(localStorage.getItem("user")) &&
                        JSON.parse(localStorage.getItem("user")).userType === "port-admin" ? (
                            <ListItem button onClick={this.handleClickAdmin}>
                                <ListItemIcon>
                                    <img
                                        src={AdminIcon}
                                        className={classes.sideNavBarIcon}
                                        alt=""
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Admin"/>
                                {this.state.openAdmin ? <ExpandLess className={classes.itemExpandIcon}/> :
                                    <ExpandMore className={classes.itemExpandIcon}/>}
                            </ListItem>
                        ) : null}

                        {JSON.parse(localStorage.getItem("user")) &&
                        JSON.parse(localStorage.getItem("user")).userType === "port-admin" ? (
                            <Collapse in={this.state.openAdmin} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        component={Link}
                                        to="/dashboard/onBoardPort"
                                    >
                                        <ListItemIcon>
                                            <img
                                                src={PortsIcon}
                                                className={classes.sideNavBarIcon}
                                                alt=""
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary="Ports"/>
                                    </ListItem>
                                    {/*<ListItem*/}
                                    {/*    button*/}
                                    {/*    className={classes.nested}*/}
                                    {/*    component={Link}*/}
                                    {/*    to="/dashboard/users"*/}
                                    {/*>*/}
                                    {/*    <ListItemIcon>*/}
                                    {/*        <img*/}
                                    {/*            src={UsersIcon}*/}
                                    {/*            className={classes.sideNavBarIcon}*/}
                                    {/*            alt=""*/}
                                    {/*        />*/}
                                    {/*    </ListItemIcon>*/}
                                    {/*    <ListItemText primary="Users"/>*/}
                                    {/*</ListItem>*/}
                                </List>
                            </Collapse>
                        ) : null}
                    </List>
                    <Divider/>
                    <List>

                        {/*{JSON.parse(localStorage.getItem("user")) &&*/}
                        {/*JSON.parse(localStorage.getItem("user")).userType ===*/}
                        {/*"port-operator" ? (*/}
                        {/*    <ListItem*/}
                        {/*        button*/}
                        {/*        key="Imports"*/}
                        {/*        component={Link}*/}
                        {/*        to="/dashboard/imports"*/}
                        {/*    >*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <img*/}
                        {/*                src={ImportsIcon}*/}
                        {/*                className={classes.sideNavBarIcon}*/}
                        {/*                alt=""*/}
                        {/*            />*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary="Imports"/>*/}
                        {/*    </ListItem>*/}
                        {/*) : null}*/}

                        {/*{JSON.parse(localStorage.getItem("user")) &&*/}
                        {/*JSON.parse(localStorage.getItem("user")).userType ===*/}
                        {/*"port-operator" ? (*/}
                        {/*    <ListItem*/}
                        {/*        button*/}
                        {/*        key="Exports"*/}
                        {/*        component={Link}*/}
                        {/*        to="/dashboard/exports"*/}
                        {/*    >*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <img*/}
                        {/*                src={ExportsIcon}*/}
                        {/*                className={classes.sideNavBarIcon}*/}
                        {/*                alt=""*/}
                        {/*            />*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary="Exports"/>*/}
                        {/*    </ListItem>*/}
                        {/*) : null}*/}

                        {JSON.parse(localStorage.getItem("user")) &&
                        JSON.parse(localStorage.getItem("user")).userType ===
                        "port-operator" ? (
                            <ListItem
                                button
                                key="ImportsAndExports"
                                component={Link}
                                to="/dashboard/importsExports"
                            >
                                <ListItemIcon>
                                    <img
                                        src={ImportsExportsIcon}
                                        className={classes.sideNavBarIcon}
                                        alt=""
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Imports & Exports"/>
                            </ListItem>
                        ) : null}

                        {JSON.parse(localStorage.getItem("user")) &&
                        JSON.parse(localStorage.getItem("user")).userType ===
                        "port-operator" ? (
                            <ListItem
                                button
                                key="Revenue"
                                component={Link}
                                to="/dashboard/revenue"
                            >
                                <ListItemIcon>
                                    <img
                                        src={RevenueIcon}
                                        className={classes.sideNavBarIcon}
                                        alt=""
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Revenue"/>
                            </ListItem>
                        ) : null}

                        {JSON.parse(localStorage.getItem("user")) &&
                        JSON.parse(localStorage.getItem("user")).userType ===
                        "port-operator" ? (
                            <ListItem
                                button
                                key="Others"
                                component={Link}
                                to="/dashboard/others"
                            >
                                <ListItemIcon>
                                    <img
                                        src={OthersIcon}
                                        className={classes.sideNavBarIcon}
                                        alt=""
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Others"/>
                            </ListItem>
                        ) : null}

                        {/*{JSON.parse(localStorage.getItem("user")) &&*/}
                        {/*JSON.parse(localStorage.getItem("user")).userType ===*/}
                        {/*"port-operator" ? (*/}
                        {/*    <ListItem*/}
                        {/*        button*/}
                        {/*        key="Other Vessels"*/}
                        {/*        component={Link}*/}
                        {/*        to="/dashboard/otherVessels"*/}
                        {/*    >*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <img*/}
                        {/*                src={OtherVesselsIcon}*/}
                        {/*                className={classes.sideNavBarIcon}*/}
                        {/*                alt=""*/}
                        {/*            />*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary="Other Vessels"/>*/}
                        {/*    </ListItem>*/}
                        {/*) : null}*/}

                        {/*{JSON.parse(localStorage.getItem("user")) &&*/}
                        {/*JSON.parse(localStorage.getItem("user")).userType ===*/}
                        {/*"port-operator" ? (*/}
                        {/*    <ListItem*/}
                        {/*        button*/}
                        {/*        key="Berth Throughput"*/}
                        {/*        component={Link}*/}
                        {/*        to="/dashboard/berththroughput"*/}
                        {/*    >*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <img*/}
                        {/*                src={BerthThroughPutIcon}*/}
                        {/*                className={classes.sideNavBarIcon}*/}
                        {/*                alt=""*/}
                        {/*            />*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary="Berth Throughput"/>*/}
                        {/*    </ListItem>*/}
                        {/*) : null}*/}

                        {/*{JSON.parse(localStorage.getItem("user")) &&*/}
                        {/*JSON.parse(localStorage.getItem("user")).userType ===*/}
                        {/*"port-operator" ? (*/}
                        {/*    <ListItem*/}
                        {/*        button*/}
                        {/*        key="Berth Occupancy"*/}
                        {/*        component={Link}*/}
                        {/*        to="/dashboard/berthOccupancy"*/}
                        {/*    >*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <img*/}
                        {/*                src={BerthOccupancyIcon}*/}
                        {/*                className={classes.sideNavBarIcon}*/}
                        {/*                alt=""*/}
                        {/*            />*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary="Berth Occupancy"/>*/}
                        {/*    </ListItem>*/}
                        {/*) : null}*/}

                        {/*{JSON.parse(localStorage.getItem("user")) &&*/}
                        {/*JSON.parse(localStorage.getItem("user")).userType ===*/}
                        {/*"port-operator" ? (*/}
                        {/*    <ListItem*/}
                        {/*        button*/}
                        {/*        key="Parcel Size"*/}
                        {/*        component={Link}*/}
                        {/*        to="/dashboard/parcelsize"*/}
                        {/*    >*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <img*/}
                        {/*                src={ParcelSizeIcon}*/}
                        {/*                className={classes.sideNavBarIcon}*/}
                        {/*                alt=""*/}
                        {/*            />*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary="Parcel Size"/>*/}
                        {/*    </ListItem>*/}
                        {/*) : null}*/}

                        {/*{JSON.parse(localStorage.getItem("user")) &&*/}
                        {/*JSON.parse(localStorage.getItem("user")).userType ===*/}
                        {/*"port-operator" ? (*/}
                        {/*    <ListItem*/}
                        {/*        button*/}
                        {/*        key="Average Pre Berthing Waiting"*/}
                        {/*        component={Link}*/}
                        {/*        to="/dashboard/averagePerBerthingWaiting"*/}
                        {/*    >*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <img*/}
                        {/*                src={AvgPreBerthingWaitingIcon}*/}
                        {/*                className={classes.sideNavBarIcon}*/}
                        {/*                alt=""*/}
                        {/*            />*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary="Average Pre Berthing Waiting"/>*/}
                        {/*    </ListItem>*/}
                        {/*) : null}*/}

                        {/*{JSON.parse(localStorage.getItem("user")) &&*/}
                        {/*JSON.parse(localStorage.getItem("user")).userType ===*/}
                        {/*"port-operator" ? (*/}
                        {/*    <ListItem*/}
                        {/*        button*/}
                        {/*        key="Development Works"*/}
                        {/*        component={Link}*/}
                        {/*        to="/dashboard/developmentworks"*/}
                        {/*    >*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <img*/}
                        {/*                src={DevelopmentWorksIcon}*/}
                        {/*                className={classes.sideNavBarIcon}*/}
                        {/*                alt=""*/}
                        {/*            />*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary="Development Works"/>*/}
                        {/*    </ListItem>*/}
                        {/*) : null}*/}

                        {/*{JSON.parse(localStorage.getItem("user")) &&*/}
                        {/*JSON.parse(localStorage.getItem("user")).userType ===*/}
                        {/*"port-operator" ? (*/}
                        {/*    <ListItem*/}
                        {/*        button*/}
                        {/*        key="Income & Port Dues"*/}
                        {/*        component={Link}*/}
                        {/*        to="/dashboard/incomeAndPortDues"*/}
                        {/*    >*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <img*/}
                        {/*                src={IncomeAndPortDuesIcon}*/}
                        {/*                className={classes.sideNavBarIcon}*/}
                        {/*                alt=""*/}
                        {/*            />*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary="Income & Port Dues"/>*/}
                        {/*    </ListItem>*/}
                        {/*) : null}*/}

                        {/*{JSON.parse(localStorage.getItem("user")) &&*/}
                        {/*JSON.parse(localStorage.getItem("user")).userType ===*/}
                        {/*"port-operator" ? (*/}
                        {/*    <ListItem*/}
                        {/*        button*/}
                        {/*        key="Vessel Data"*/}
                        {/*        component={Link}*/}
                        {/*        to="/dashboard/vesselData"*/}
                        {/*    >*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <img*/}
                        {/*                src={VesselDataIcon}*/}
                        {/*                className={classes.sideNavBarIcon}*/}
                        {/*                alt=""*/}
                        {/*            />*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary="Vessel Data"/>*/}
                        {/*    </ListItem>*/}
                        {/*) : null}*/}

                        {/*{JSON.parse(localStorage.getItem("user")) &&*/}
                        {/*JSON.parse(localStorage.getItem("user")).userType ===*/}
                        {/*"port-operator" ? (*/}
                        {/*    <ListItem*/}
                        {/*        button*/}
                        {/*        key="Berthing-TurnAround"*/}
                        {/*        component={Link}*/}
                        {/*        to="/dashboard/berthingTurnAroundTime"*/}
                        {/*    >*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <img*/}
                        {/*                src={BerthingTurnAroundTimeIcon}*/}
                        {/*                className={classes.sideNavBarIcon}*/}
                        {/*                alt=""*/}
                        {/*            />*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary="Berthing-TurnAround"/>*/}
                        {/*    </ListItem>*/}
                        {/*) : null}*/}

                        {/*{JSON.parse(localStorage.getItem("user")) &&*/}
                        {/*JSON.parse(localStorage.getItem("user")).userType ===*/}
                        {/*"port-operator" ? (*/}
                        {/*    <ListItem*/}
                        {/*        button*/}
                        {/*        key="Trucking Trips"*/}
                        {/*        component={Link}*/}
                        {/*        to="/dashboard/truckingTrips"*/}
                        {/*    >*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <img*/}
                        {/*                src={TruckingTripIcon}*/}
                        {/*                className={classes.sideNavBarIcon}*/}
                        {/*                alt=""*/}
                        {/*            />*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary="Trucking Trips"/>*/}
                        {/*    </ListItem>*/}
                        {/*) : null}*/}

                        {/*{JSON.parse(localStorage.getItem("user")) &&*/}
                        {/*JSON.parse(localStorage.getItem("user")).userType ===*/}
                        {/*"port-admin" ? (*/}
                        {/*    <ListItem*/}
                        {/*        button*/}
                        {/*        key="On-board Port"*/}
                        {/*        component={Link}*/}
                        {/*        to="/dashboard/onBoardPort"*/}
                        {/*    >*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <img*/}
                        {/*                src={OnBoardPortsIcon}*/}
                        {/*                className={classes.sideNavBarIcon}*/}
                        {/*                alt=""*/}
                        {/*            />*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary="On-board Port"/>*/}
                        {/*    </ListItem>*/}
                        {/*) : null}*/}

                    </List>
                    {/*<Divider/>*/}
                    <List>
                        {JSON.parse(localStorage.getItem("user")) &&
                        JSON.parse(localStorage.getItem("user")).userType ===
                        "port-operator" ? (
                            <ListItem button onClick={this.handleClickOptions}>
                                <ListItemIcon>
                                    <img
                                        src={OptionsIcon}
                                        className={classes.sideNavBarIcon}
                                        alt=""
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Options"/>
                                {this.state.openOptions ? <ExpandLess className={classes.itemExpandIcon}/> :
                                    <ExpandMore className={classes.itemExpandIcon}/>}
                            </ListItem>
                        ) : null}
                        {JSON.parse(localStorage.getItem("user")) &&
                        JSON.parse(localStorage.getItem("user")).userType ===
                        "port-operator" ? (
                            <Collapse
                                in={this.state.openOptions}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        component={Link}
                                        to="/dashboard/commodities"
                                    >
                                        <ListItemIcon>
                                            <img
                                                src={CommoditiesIcon}
                                                className={classes.sideNavBarIcon}
                                                alt=""
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary="Commodities"/>
                                    </ListItem>
                                    {/*<ListItem*/}
                                    {/*    button*/}
                                    {/*    className={classes.nested}*/}
                                    {/*    component={Link}*/}
                                    {/*    to="/dashboard/vesseltypes"*/}
                                    {/*>*/}
                                    {/*    <ListItemIcon>*/}
                                    {/*        <img*/}
                                    {/*            src={VesselTypesIcon}*/}
                                    {/*            className={classes.sideNavBarIcon}*/}
                                    {/*            alt=""*/}
                                    {/*        />*/}
                                    {/*    </ListItemIcon>*/}
                                    {/*    <ListItemText primary="Vessel Types"/>*/}
                                    {/*</ListItem>*/}
                                </List>
                            </Collapse>
                        ) : null}
                    </List>
                </Drawer>

                <main className={classes.content}>
                    <div className={classes.toolbar} style={{minHeight: 0}}/>
                    {children}
                    <AllRoutes
                        allCommoditiesForSiblingCommunication={
                            allCommoditiesForSiblingCommunication
                        }
                        setCommodityForSiblingCommunication={
                            this.setCommodityForSiblingCommunication
                        }
                        // allVesselTypesForSiblingCommunication={
                        //     allVesselTypesForSiblingCommunication
                        // }
                        // setVesselTypeForSiblingCommunication={
                        //     this.setVesselTypeForSiblingCommunication
                        // }
                        // allVesselDataForSiblingCommunication={
                        //     allVesselDataForSiblingCommunication
                        // }
                        // setVesselDataForSiblingCommunication={
                        //     this.setVesselDataForSiblingCommunication
                        // }
                        themeType={themeType}
                    />
                </main>
            </div>
        );
    }
}

SiteDesign.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default withStyles(styles, {withTheme: true})(SiteDesign);
