import React, {Component} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import {styles} from "../commonStyles/ModuleItemListStyles";
import toolbarStyles from "../commonStyles/toolbarStyles";
import combineStyles from "../commonStyles/combineStyles";
import {withStyles} from "@material-ui/core";

const rows = [
    {
        tableHeaderProp: "others_year",
        disablePadding: false,
        label: "Year"
    },
    {
        tableHeaderProp: "others_month",
        disablePadding: false,
        label: "Month"
    },
    {
        tableHeaderProp: "coastal_in",
        disablePadding: false,
        label: "Coastal In (MT)"
    },
    {
        tableHeaderProp: "coastal_out",
        disablePadding: false,
        label: "Coastal Out (MT)"
    },
    {
        tableHeaderProp: "others",
        disablePadding: false,
        label: "Others"
    },
    {
        tableHeaderProp: "container_traffic_handled",
        disablePadding: false,
        label: "Container Traffic Handled (TEUs)"
    },
    {
        tableHeaderProp: "percentage_utilization_of_traffic_handling_capacity_of_the_port",
        disablePadding: false,
        label: "Percentage utilization of Traffic handling capacity of the port (%)"
    },
    {
        tableHeaderProp: "average_vehicle_turn_around_time",
        disablePadding: false,
        label: "Average vehicle turn around time(TAT) (Hrs)"
    },
    {
        tableHeaderProp: "bulk_and_handymax",
        disablePadding: false,
        label: "Bulk & Handymax (Hrs)"
    },
    {
        tableHeaderProp: "bulk_and_panamax",
        disablePadding: false,
        label: "Bulk & Panamax (Hrs)"
    },
    {
        tableHeaderProp: "bulk_and_cape_size",
        disablePadding: false,
        label: "Bulk & cape size (Hrs)"
    },
    {
        tableHeaderProp: "containers",
        disablePadding: false,
        label: "Containers (Hrs)"
    },
    {
        tableHeaderProp: "tankers",
        disablePadding: false,
        label: "Tankers (Hrs)"
    },
    {
        tableHeaderProp: "average_pre_berth_delays",
        disablePadding: false,
        label: "Average pre berth delays (Hrs)"
    },
    {
        tableHeaderProp: "total_cargo_evacuation_from_port",
        disablePadding: false,
        label: "Total cargo evacuation from port (MT)"
    },
    {
        tableHeaderProp: "by_rail",
        disablePadding: false,
        label: "By Rail (MT)"
    },
    {
        tableHeaderProp: "by_road",
        disablePadding: false,
        label: "By Road (MT)"
    },
    {
        tableHeaderProp: "by_conveyor",
        disablePadding: false,
        label: "By Conveyor (MT)"
    },
    {
        tableHeaderProp: "by_others",
        disablePadding: false,
        label: "By Others (MT)"
    },
    {
        tableHeaderProp: "specific_information",
        disablePadding: false,
        label: "Any other specific information to be shared"
    },
];

class TableHeadOthers extends Component {

    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {
            order,
            orderBy,
        } = this.props;

        const {classes} = this.props;

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox"> </TableCell>
                    {rows.map(
                        row => (
                            <TableCell
                                className={classes.row}
                                key={row.tableHeaderProp}
                                align="center"
                                padding={row.disablePadding ? "none" : "default"}
                                sortDirection={orderBy === row.tableHeaderProp ? order : false}
                            >
                                <Tooltip title={<h6>Sort by {row.label}</h6>}>
                                    <TableSortLabel
                                        active={orderBy === row.tableHeaderProp}
                                        direction={order}
                                        onClick={this.createSortHandler(row.tableHeaderProp)}
                                    >
                                        <Typography variant="h3" noWrap>{row.label}</Typography>
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        ),
                        this
                    )}
                </TableRow>
            </TableHead>
        )
    }
}

const combinedStyles = combineStyles(styles, toolbarStyles);

export default withStyles(combinedStyles)(TableHeadOthers);
