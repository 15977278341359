import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import CheckIcon from "@material-ui/icons/Check";
import SaveIcon from "@material-ui/icons/Save";
import clsx from "clsx";
// import DateFnsUtils from "@date-io/date-fns";
// import {MuiPickersUtilsProvider, DatePicker} from "material-ui-pickers";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

const styles = theme => ({
    appBar: {
        position: "relative"
    },
    rootTab: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    },
    layout: {
        width: "auto"
        //marginLeft: theme.spacing.unit,
        //marginRight: theme.spacing.unit,
        // [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        //     width: 600,
        //     marginLeft: 'auto',
        //     marginRight: 'auto',
        // },
    },
    paper: {
        padding: theme.spacing.unit * 2
        // [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        //     marginTop: theme.spacing.unit * 6,
        //     marginBottom: theme.spacing.unit * 6,
        //     padding: theme.spacing.unit * 3,
        // },
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit
    },
    textFieldBottom: {
        marginBottom: theme.spacing.unit * 2
    }
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{padding: 8 * 3}}>
            {props.children}
        </Typography>
    );
}

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            activeStep: 0,

            _id: "",
            port_name: "",
            port_email: "",
            phone_number: "",
            no_of_berths: "",
            revenue_share: "",
            investments: "",
            user_type: "",
            fax_number: "",
            url: "",
            address: "",
            state: "",
            city: "",
            district: "",
            country: "",
            pin_code: "",

            error_phone_number: "",
            error_port_name: "",

            password: "",
            new_password: "",
            confirm_password: "",

            error_password: "",
            error_new_password: "",
            error_confirm_password: "",

            // username: "",
            // portId: "",
            // portName: "",
            // name: "",
            // firstName: "",
            // lastName: "",
            // email: "",
            // phone: "",
            // dob: new Date(),
            // password: "",
            // newPassword: "",
            // confirmPassword: "",

            // errorName: "",
            // errorFirstName: "",
            // errorLastName: "",
            // errorPhone: "",
            // errorPassword: "",
            // errorNewPassword: "",
            // errorConfirmPassword: "",

            progressActive: false,
            success: false,
            loading: false,
            textFieldDisabled: false,
            buttonDisabled: false,

            profileSuccessDialog: false,
            passwordSuccessDialog: false
        };
    }

    componentDidMount() {
        const localEmail = JSON.parse(localStorage.getItem("user"));
        if (localEmail.email) {
            axios.get("/api/profile/" + localEmail.email).then(res => {
                this.setState({
                    _id: res.data._id,
                    port_name: res.data.port_name,
                    port_email: res.data.port_email,
                    phone_number: res.data.phone_number,
                    no_of_berths: res.data.no_of_berths,
                    revenue_share: res.data.revenue_share,
                    investments: res.data.investments,
                    user_type: res.data.user_type,
                    fax_number: res.data.fax_number,
                    url: res.data.url,
                    address: res.data.address,
                    state: res.data.state,
                    city: res.data.city,
                    district: res.data.district,
                    country: res.data.country,
                    pin_code: res.data.pin_code,

                    // username: res.data.port_email,
                    // portId: res.data._id,
                    // portName: res.data.port_name,
                    // name: res.data.port_name,
                    // firstName: res.data.port_name,
                    // lastName: res.data.port_name,
                    // email: res.data.port_email,
                    // phone: res.data.phone_number ? res.data.phone_number.toString() : "",
                    // dob: res.data.dob ? res.data.dob : ""
                });
            });
        }
    }

    handleChange = (event, value) => {
        this.setState({value});
    };

    // handleDateChange = date => {
    //     this.setState({
    //         dob: date
    //     });
    // };

    handleProfileClose = () => {
        this.setState({
            profileSuccessDialog: false,
            success: false
        });
    };

    handlePasswordClose = () => {
        this.setState({
            passwordSuccessDialog: false,
            success: false
        });
    };

    handleProfileUpdate = () => {
        const {
            _id,
            port_name,
            phone_number,
            no_of_berths,
            revenue_share,
            investments,
            fax_number,
            url,
            address,
            state,
            city,
            district,
            country,
            pin_code
        } = this.state;

        // console.log(typeof phone_number);
        // console.log(phone_number !== "");
        // console.log(phone_number.length);
        // console.log(phone_number.length === 10);
        // console.log(port_name !== "");

        if ((phone_number !== "" && phone_number.length === 10) && (port_name !== "")
            // name !== "" &&
            // firstName !== "" &&
            // lastName !== "" &&
            // phone !== "" &&
            // dob !== "" &&
            // phone.length === 10
        ) {
            this.setState(
                {
                    textFieldDisabled: true,
                    buttonDisabled: true,
                    progressActive: true,
                    loading: true
                },
                () => {
                    axios
                        .put(`/api/profile/profile/${_id}`, {
                            port_name,
                            phone_number,
                            no_of_berths,
                            revenue_share,
                            investments,
                            fax_number,
                            url,
                            address,
                            state,
                            city,
                            district,
                            country,
                            pin_code
                            // name,
                            // firstName,
                            // lastName,
                            // phone,
                            // dob
                        })
                        .then(res => {
                            this.setState(
                                {
                                    textFieldDisabled: false,
                                    buttonDisabled: false,
                                    progressActive: false,
                                    loading: false,
                                    success: true,
                                    port_name: res.data.port_name,
                                    port_email: res.data.port_email,
                                    phone_number: res.data.phone_number,
                                    no_of_berths: res.data.no_of_berths,
                                    revenue_share: res.data.revenue_share,
                                    investments: res.data.investments,
                                    user_type: res.data.user_type,
                                    fax_number: res.data.fax_number,
                                    url: res.data.url,
                                    address: res.data.address,
                                    state: res.data.state,
                                    city: res.data.city,
                                    district: res.data.district,
                                    country: res.data.country,
                                    pin_code: res.data.pin_code,
                                    // name: res.data.name,
                                    // firstName: res.data.firstName,
                                    // lastName: res.data.lastName,
                                    // phone: res.data.phone.toString(),
                                    // dob: res.data.dob
                                },
                                () => {
                                    this.setState({
                                        profileSuccessDialog: true
                                    });
                                }
                            );
                        });
                }
            );
        } else {
            alert("Please enter valid details.");
        }
    };

    handlePasswordUpdate = () => {
        const {_id, password, new_password, confirm_password, port_email} = this.state;
        if (password !== "" && new_password !== "" && confirm_password !== "") {
            if (new_password === confirm_password) {
                this.setState(
                    {
                        textFieldDisabled: true,
                        buttonDisabled: true,
                        progressActive: true,
                        loading: true
                    },
                    () => {
                        axios
                            .put(`/api/profile/password/${port_email}`, {
                                _id,
                                password,
                                new_password
                            })
                            .then(res => {
                                if (!res.data.success) {
                                    this.setState({
                                        textFieldDisabled: false,
                                        buttonDisabled: false,
                                        progressActive: false,
                                        loading: false,
                                        errorPassword: "Please Enter valid current password",
                                        password: ""
                                    });
                                } else if (res.status === 200) {
                                    this.setState({
                                        textFieldDisabled: false,
                                        buttonDisabled: false,
                                        progressActive: false,
                                        loading: false,
                                        success: true,
                                        password: "",
                                        new_password: "",
                                        confirm_password: "",
                                        passwordSuccessDialog: true
                                    });
                                } else {
                                    alert("Something went wrong");
                                }
                            });
                    }
                );
            } else {
                this.setState({
                    error_confirm_password:
                        "Confirm Password should be same as new password",
                    confirm_password: ""
                });
            }
        }
    };

    render() {
        const {classes, fullScreen} = this.props;
        const {
            value,

            port_name,
            port_email,
            phone_number,
            no_of_berths,
            revenue_share,
            investments,
            user_type,
            fax_number,
            url,
            address,
            state,
            city,
            district,
            country,
            pin_code,

            error_phone_number,
            error_port_name,

            password,
            new_password,
            confirm_password,

            error_password,
            error_new_password,
            error_confirm_password,

            // username,
            // portName,
            // name,
            // firstName,
            // lastName,
            // email,
            // phone,
            // dob,
            // password,
            // newPassword,
            // confirmPassword,
            // errorName,
            // errorFirstName,
            // errorLastName,
            // errorPhone,
            // errorPassword,
            // errorNewPassword,
            // errorConfirmPassword,
            textFieldDisabled,
            buttonDisabled,
            success,
            loading,
            progressActive,
            profileSuccessDialog,
            passwordSuccessDialog
        } = this.state;
        const buttonClassname = clsx({
            [classes.buttonSuccess]: success
        });

        return (
            <React.Fragment>
                <CssBaseline/>
                <main className={classes.layout}>
                    <Paper className={classes.paper}>
                        <Typography variant="h2" align="center" gutterBottom>
                            PROFILE
                        </Typography>
                        <React.Fragment>
                            <div className={classes.rootTab}>
                                <AppBar position="static">
                                    <Tabs value={value} onChange={this.handleChange}>
                                        <Tab
                                            label={<Typography variant="h3">PROFILE</Typography>}
                                        />
                                        {
                                            JSON.parse(localStorage.getItem("user")) &&
                                            JSON.parse(localStorage.getItem("user")).userType === "port-admin" ? (
                                                <Tab
                                                    label={<Typography variant="h3">PORT ORDER</Typography>}
                                                />
                                            ) : null
                                        }
                                        <Tab
                                            label={<Typography variant="h3">PASSWORD</Typography>}
                                        />
                                    </Tabs>
                                </AppBar>
                                {value === 0 && (
                                    <TabContainer>
                                        <React.Fragment>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="port_name"
                                                    name="port_name"
                                                    label="Port Name"
                                                    className={classes.textFieldBottom}
                                                    value={port_name}
                                                    onChange={e => {
                                                        if (e.target.value.length === 0) {
                                                            this.setState({
                                                                error_port_name: "Field cannot be empty",
                                                                port_name: e.target.value
                                                            })
                                                        } else {
                                                            this.setState({
                                                                port_name: e.target.value,
                                                                error_port_name: ""
                                                            })
                                                        }
                                                    }}
                                                    error={error_port_name.length !== 0}
                                                    helperText={error_port_name}
                                                    disabled={textFieldDisabled}
                                                    type="string"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="port_email"
                                                    name="port_email"
                                                    label="Port Email / Username"
                                                    className={classes.textFieldBottom}
                                                    value={port_email}
                                                    disabled={true}
                                                    type="string"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="phone_number"
                                                    name="phone_number"
                                                    label="Phone Number"
                                                    className={classes.textFieldBottom}
                                                    value={phone_number}
                                                    onChange={e => {
                                                        if (e.target.value.length !== 10) {
                                                            this.setState({
                                                                error_phone_number: "Please Enter Valid Phone Number",
                                                                phone_number: e.target.value
                                                            });
                                                        } else {
                                                            this.setState({
                                                                phone_number: e.target.value,
                                                                error_phone_number: ""
                                                            });
                                                        }
                                                    }}
                                                    error={error_phone_number.length !== 0}
                                                    helperText={error_phone_number}
                                                    disabled={textFieldDisabled}
                                                    type="string"
                                                    fullWidth
                                                />
                                            </Grid>
                                            {JSON.parse(localStorage.getItem("user")) &&
                                            JSON.parse(localStorage.getItem("user")).userType === "port-operator" ? (
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="no_of_berths"
                                                        name="no_of_berths"
                                                        label="No of Berths"
                                                        className={classes.textFieldBottom}
                                                        value={no_of_berths}
                                                        onChange={e => {
                                                            this.setState({
                                                                no_of_berths: e.target.value
                                                            })
                                                        }}
                                                        disabled={textFieldDisabled}
                                                        type="string"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            ) : null}
                                            {JSON.parse(localStorage.getItem("user")) &&
                                            JSON.parse(localStorage.getItem("user")).userType === "port-operator" ? (
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="revenue_share"
                                                        name="revenue_share"
                                                        label="Govt Revenue Share (%)"
                                                        className={classes.textFieldBottom}
                                                        value={revenue_share}
                                                        onChange={e => {
                                                            this.setState({
                                                                revenue_share: e.target.value
                                                            })
                                                        }}
                                                        disabled={textFieldDisabled}
                                                        type="string"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            ) : null}
                                            {JSON.parse(localStorage.getItem("user")) &&
                                            JSON.parse(localStorage.getItem("user")).userType === "port-operator" ? (
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="investments"
                                                        name="investments"
                                                        label="Port Investments (Rs. crs)"
                                                        className={classes.textFieldBottom}
                                                        value={investments}
                                                        onChange={e => {
                                                            this.setState({
                                                                investments: e.target.value
                                                            })
                                                        }}
                                                        disabled={textFieldDisabled}
                                                        type="string"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            ) : null}
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="user_type"
                                                    name="user_type"
                                                    label="User Type"
                                                    className={classes.textFieldBottom}
                                                    value={user_type === "port-operator" ? "Private Port" : "Admin"}
                                                    disabled={true}
                                                    type="string"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="fax_number"
                                                    name="fax_number"
                                                    label="Fax Number"
                                                    className={classes.textFieldBottom}
                                                    value={fax_number}
                                                    onChange={e => {
                                                        this.setState({
                                                            fax_number: e.target.value
                                                        })
                                                    }}
                                                    disabled={textFieldDisabled}
                                                    type="string"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="url"
                                                    name="url"
                                                    label="Website URL Ex: https://www.google.com/"
                                                    className={classes.textFieldBottom}
                                                    value={url}
                                                    onChange={e => {
                                                        this.setState({
                                                            url: e.target.value
                                                        })
                                                    }}
                                                    disabled={textFieldDisabled}
                                                    type="string"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="address"
                                                    name="address"
                                                    label="Address"
                                                    className={classes.textFieldBottom}
                                                    value={address}
                                                    onChange={e => {
                                                        this.setState({
                                                            address: e.target.value
                                                        })
                                                    }}
                                                    disabled={textFieldDisabled}
                                                    type="string"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="state"
                                                    name="state"
                                                    label="State"
                                                    className={classes.textFieldBottom}
                                                    value={state}
                                                    onChange={e => {
                                                        this.setState({
                                                            state: e.target.value
                                                        })
                                                    }}
                                                    disabled={textFieldDisabled}
                                                    type="string"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="city"
                                                    name="city"
                                                    label="City"
                                                    className={classes.textFieldBottom}
                                                    value={city}
                                                    onChange={e => {
                                                        this.setState({
                                                            city: e.target.value
                                                        })
                                                    }}
                                                    disabled={textFieldDisabled}
                                                    type="string"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="district"
                                                    name="district"
                                                    label="District"
                                                    className={classes.textFieldBottom}
                                                    value={district}
                                                    onChange={e => {
                                                        this.setState({
                                                            district: e.target.value
                                                        })
                                                    }}
                                                    disabled={textFieldDisabled}
                                                    type="string"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="country"
                                                    name="country"
                                                    label="Country"
                                                    className={classes.textFieldBottom}
                                                    value={country}
                                                    onChange={e => {
                                                        this.setState({
                                                            country: e.target.value
                                                        })
                                                    }}
                                                    disabled={textFieldDisabled}
                                                    type="string"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="pin_code"
                                                    name="pin_code"
                                                    label="Pin Code"
                                                    className={classes.textFieldBottom}
                                                    value={pin_code}
                                                    onChange={e => {
                                                        this.setState({
                                                            pin_code: e.target.value
                                                        })
                                                    }}
                                                    disabled={textFieldDisabled}
                                                    type="string"
                                                    fullWidth
                                                />
                                            </Grid>

                                            {/*<Grid item xs={12}>*/}
                                            {/*    <TextField*/}
                                            {/*        id="userName"*/}
                                            {/*        name="userName"*/}
                                            {/*        label="Username / Port Email ID"*/}
                                            {/*        className={classes.textFieldBottom}*/}
                                            {/*        value={username}*/}
                                            {/*        disabled={true}*/}
                                            {/*        type="string"*/}
                                            {/*        fullWidth*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}
                                            {/**/}
                                            {/*<Grid item xs={12}>*/}
                                            {/*    <TextField*/}
                                            {/*        id="portName"*/}
                                            {/*        name="portName"*/}
                                            {/*        label="Port Name"*/}
                                            {/*        className={classes.textFieldBottom}*/}
                                            {/*        value={portName}*/}
                                            {/*        disabled={true}*/}
                                            {/*        type="string"*/}
                                            {/*        fullWidth*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}
                                            {/**/}
                                            {/*<Grid item xs={12}>*/}
                                            {/*    <TextField*/}
                                            {/*        id="fullName"*/}
                                            {/*        name="fullName"*/}
                                            {/*        label="Full Name"*/}
                                            {/*        className={classes.textFieldBottom}*/}
                                            {/*        value={name}*/}
                                            {/*        onChange={e => {*/}
                                            {/*            if (e.target.value === "") {*/}
                                            {/*                this.setState({*/}
                                            {/*                    errorName: "Please Enter Full Name",*/}
                                            {/*                    name: ""*/}
                                            {/*                });*/}
                                            {/*            } else {*/}
                                            {/*                this.setState({*/}
                                            {/*                    name: e.target.value,*/}
                                            {/*                    errorName: ""*/}
                                            {/*                });*/}
                                            {/*            }*/}
                                            {/*        }}*/}
                                            {/*        error={errorName.length !== 0}*/}
                                            {/*        helperText={errorName}*/}
                                            {/*        disabled={textFieldDisabled}*/}
                                            {/*        type="string"*/}
                                            {/*        fullWidth*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}
                                            {/**/}
                                            {/*<Grid item xs={12}>*/}
                                            {/*    <TextField*/}
                                            {/*        id="firstName"*/}
                                            {/*        name="firstName"*/}
                                            {/*        label="First Name"*/}
                                            {/*        className={classes.textFieldBottom}*/}
                                            {/*        value={firstName}*/}
                                            {/*        onChange={e => {*/}
                                            {/*            if (e.target.value === "") {*/}
                                            {/*                this.setState({*/}
                                            {/*                    errorFirstName: "Please Enter First Name",*/}
                                            {/*                    firstName: ""*/}
                                            {/*                });*/}
                                            {/*            } else {*/}
                                            {/*                this.setState({*/}
                                            {/*                    firstName: e.target.value,*/}
                                            {/*                    errorFirstName: ""*/}
                                            {/*                });*/}
                                            {/*            }*/}
                                            {/*        }}*/}
                                            {/*        error={errorFirstName.length !== 0}*/}
                                            {/*        helperText={errorFirstName}*/}
                                            {/*        disabled={textFieldDisabled}*/}
                                            {/*        type="string"*/}
                                            {/*        fullWidth*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}
                                            {/**/}
                                            {/*<Grid item xs={12}>*/}
                                            {/*    <TextField*/}
                                            {/*        id="lastName"*/}
                                            {/*        name="lastName"*/}
                                            {/*        label="Last Name"*/}
                                            {/*        className={classes.textFieldBottom}*/}
                                            {/*        value={lastName}*/}
                                            {/*        onChange={e => {*/}
                                            {/*            if (e.target.value === "") {*/}
                                            {/*                this.setState({*/}
                                            {/*                    errorLastName: "Please Enter Last Name",*/}
                                            {/*                    lastName: ""*/}
                                            {/*                });*/}
                                            {/*            } else {*/}
                                            {/*                this.setState({*/}
                                            {/*                    lastName: e.target.value,*/}
                                            {/*                    errorLastName: ""*/}
                                            {/*                });*/}
                                            {/*            }*/}
                                            {/*        }}*/}
                                            {/*        error={errorLastName.length !== 0}*/}
                                            {/*        helperText={errorLastName}*/}
                                            {/*        disabled={textFieldDisabled}*/}
                                            {/*        type="string"*/}
                                            {/*        fullWidth*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}
                                            {/**/}
                                            {/*<Grid item xs={12}>*/}
                                            {/*    <TextField*/}
                                            {/*        id="email"*/}
                                            {/*        name="email"*/}
                                            {/*        label="Email"*/}
                                            {/*        className={classes.textFieldBottom}*/}
                                            {/*        value={email}*/}
                                            {/*        disabled={true}*/}
                                            {/*        type="string"*/}
                                            {/*        fullWidth*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}
                                            {/**/}
                                            {/*<Grid item xs={12}>*/}
                                            {/*    <TextField*/}
                                            {/*        id="phoneNumber"*/}
                                            {/*        name="phoneNumber"*/}
                                            {/*        label="Phone Number"*/}
                                            {/*        className={classes.textFieldBottom}*/}
                                            {/*        value={phone}*/}
                                            {/*        onChange={e => {*/}
                                            {/*            if (e.target.value === "") {*/}
                                            {/*                this.setState({*/}
                                            {/*                    errorPhone: "Please Enter Phone Number",*/}
                                            {/*                    phone: ""*/}
                                            {/*                });*/}
                                            {/*            } else if (e.target.value.length !== 10) {*/}
                                            {/*                this.setState({*/}
                                            {/*                    errorPhone: "Please Enter Valid Phone Number",*/}
                                            {/*                    phone: e.target.value*/}
                                            {/*                });*/}
                                            {/*            } else {*/}
                                            {/*                this.setState({*/}
                                            {/*                    phone: e.target.value,*/}
                                            {/*                    errorPhone: ""*/}
                                            {/*                });*/}
                                            {/*            }*/}
                                            {/*        }}*/}
                                            {/*        error={errorPhone.length !== 0}*/}
                                            {/*        helperText={errorPhone}*/}
                                            {/*        disabled={textFieldDisabled}*/}
                                            {/*        type="number"*/}
                                            {/*        fullWidth*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}
                                            {/**/}
                                            {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                                            {/*    <Grid*/}
                                            {/*        container*/}
                                            {/*        className={classes.grid}*/}
                                            {/*        justify="flex-start"*/}
                                            {/*    >*/}
                                            {/*        <DatePicker*/}
                                            {/*            margin="normal"*/}
                                            {/*            label="Date of Birth"*/}
                                            {/*            value={dob}*/}
                                            {/*            onChange={this.handleDateChange}*/}
                                            {/*        />*/}
                                            {/*    </Grid>*/}
                                            {/*</MuiPickersUtilsProvider>*/}

                                            <div className={classes.buttons}>
                                                {progressActive ? (
                                                    <div className={classes.wrapper}>
                                                        <Fab color="primary" className={buttonClassname}>
                                                            {success ? <CheckIcon/> : <SaveIcon/>}
                                                        </Fab>
                                                        {loading && (
                                                            <CircularProgress
                                                                size={68}
                                                                className={classes.fabProgress}
                                                            />
                                                        )}
                                                    </div>
                                                ) : (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleProfileUpdate}
                                                        className={classes.button}
                                                    >
                                                        Update
                                                    </Button>
                                                )}
                                            </div>

                                            <div>
                                                <Dialog
                                                    fullScreen={fullScreen}
                                                    open={profileSuccessDialog}
                                                    onClose={this.handleProfileClose}
                                                    aria-labelledby="responsive-dialog-title"
                                                >
                                                    <DialogTitle id="responsive-dialog-title">
                                                        {"Success"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText>
                                                            Profile Updated Successfully.
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button
                                                            onClick={this.handleProfileClose}
                                                            color="primary"
                                                            autoFocus
                                                        >
                                                            Okay
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </div>
                                        </React.Fragment>
                                    </TabContainer>
                                )}
                                {value === 1 && (
                                    JSON.parse(localStorage.getItem("user")) &&
                                    JSON.parse(localStorage.getItem("user")).userType === "port-admin" ? (
                                        <TabContainer>
                                            <React.Fragment>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        id="currentPassword"
                                                        name="currentPassword"
                                                        label="Current Password"
                                                        className={classes.textFieldBottom}
                                                        value={password}
                                                        onChange={e => {
                                                            if (e.target.value === "") {
                                                                this.setState({
                                                                    error_password: "Please Enter Current Password",
                                                                    password: ""
                                                                });
                                                            } else {
                                                                this.setState({
                                                                    password: e.target.value,
                                                                    error_password: ""
                                                                });
                                                            }
                                                        }}
                                                        error={error_password.length !== 0}
                                                        helperText={error_password}
                                                        disabled={textFieldDisabled}
                                                        type="password"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </React.Fragment>
                                        </TabContainer>
                                    ) : null
                                )}
                                {value === 2 && (
                                    <TabContainer>
                                        <React.Fragment>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    id="currentPassword"
                                                    name="currentPassword"
                                                    label="Current Password"
                                                    className={classes.textFieldBottom}
                                                    value={password}
                                                    onChange={e => {
                                                        if (e.target.value === "") {
                                                            this.setState({
                                                                error_password: "Please Enter Current Password",
                                                                password: ""
                                                            });
                                                        } else {
                                                            this.setState({
                                                                password: e.target.value,
                                                                error_password: ""
                                                            });
                                                        }
                                                    }}
                                                    error={error_password.length !== 0}
                                                    helperText={error_password}
                                                    disabled={textFieldDisabled}
                                                    type="password"
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    id="new_password"
                                                    name="new_password"
                                                    label="New Password"
                                                    className={classes.textFieldBottom}
                                                    value={new_password}
                                                    onChange={e => {
                                                        if (e.target.value === "") {
                                                            this.setState({
                                                                error_new_password: "Please Enter New Password",
                                                                new_password: ""
                                                            });
                                                        } else {
                                                            this.setState({
                                                                new_password: e.target.value,
                                                                error_new_password: ""
                                                            });
                                                        }
                                                    }}
                                                    error={error_new_password.length !== 0}
                                                    helperText={error_new_password}
                                                    disabled={textFieldDisabled}
                                                    type="password"
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    id="confirm_password"
                                                    name="confirm_password"
                                                    label="Confirm Password"
                                                    className={classes.textFieldBottom}
                                                    value={confirm_password}
                                                    onChange={e => {
                                                        if (e.target.value === "") {
                                                            this.setState({
                                                                error_confirm_password:
                                                                    "Please Enter Confirm Password",
                                                                confirm_password: ""
                                                            });
                                                        } else {
                                                            this.setState({
                                                                confirm_password: e.target.value,
                                                                error_confirm_password: ""
                                                            });
                                                        }
                                                    }}
                                                    error={error_confirm_password.length !== 0}
                                                    helperText={error_confirm_password}
                                                    disabled={textFieldDisabled}
                                                    type="password"
                                                    fullWidth
                                                />
                                            </Grid>

                                            <div className={classes.buttons}>
                                                {progressActive ? (
                                                    <div className={classes.wrapper}>
                                                        <Fab color="primary" className={buttonClassname}>
                                                            {success ? <CheckIcon/> : <SaveIcon/>}
                                                        </Fab>
                                                        {loading && (
                                                            <CircularProgress
                                                                size={68}
                                                                className={classes.fabProgress}
                                                            />
                                                        )}
                                                    </div>
                                                ) : (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handlePasswordUpdate}
                                                        className={classes.button}
                                                        disabled={
                                                            password === "" ||
                                                            new_password === "" ||
                                                            confirm_password === "" ||
                                                            buttonDisabled === true
                                                        }
                                                    >
                                                        Update
                                                    </Button>
                                                )}
                                            </div>

                                            <div>
                                                <Dialog
                                                    fullScreen={fullScreen}
                                                    open={passwordSuccessDialog}
                                                    onClose={this.handlePasswordClose}
                                                    aria-labelledby="responsive-dialog-title"
                                                >
                                                    <DialogTitle id="responsive-dialog-title">
                                                        {"Success"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText>
                                                            Password Updated Successfully.
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button
                                                            onClick={this.handlePasswordClose}
                                                            color="primary"
                                                            autoFocus
                                                        >
                                                            Okay
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </div>
                                        </React.Fragment>
                                    </TabContainer>
                                )}
                            </div>
                        </React.Fragment>
                    </Paper>
                </main>
            </React.Fragment>
        );
    }
}

Profile.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withMobileDialog()(withStyles(styles)(Profile));
