import React, {Component} from "react";
import axios from "axios";
import {Redirect} from "react-router-dom";
import {withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import history from "../../../history";
import combineStyles from "../commonStyles/combineStyles";
import {styles} from "../commonStyles/ModuleItemListStyles";
import RegistrationStyles from "../commonStyles/RegistrationStyles";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import CheckIcon from "@material-ui/icons/Check";
import SaveIcon from "@material-ui/icons/Save";
import clsx from "clsx";

import NotFound from "../../../Components/DashBoardViewComponents/NotFound";

import random from "randomatic";
import AlreadyRegisteredSnackBar from "./Snackbars/AlreadyRegisteredSnackBar";

class PortInviteRegistration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            fullName: "",
            emailAddress: "",
            port: "",
            portEmailAddress: "",
            errorFirstName: "",
            errorLastName: "",
            errorFullName: "",
            errorEmailAddress: "",
            errorPort: "",
            errorPortEmailAddress: "",

            progressActive: false,
            success: false,
            loading: false,
            textFieldDisabled: false,
            openAlreadyRegisteredSnackbar: false,

            successDialog: false,

            validUID: false
        };
    }

    validateEmail = emailField => {
        let reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;

        if (reg.test(emailField) === false) {
            return false;
        }
        return true;
    };

    closeAlreadyRegisteredSnackbar = () => {
        this.setState({openAlreadyRegisteredSnackbar: false});
    };

    handleClose = () => {
        this.setState(
            {
                successDialog: false
            },
            () => {
                history.push("/login");
            },
            () => {
                this.setState({
                    firstName: "",
                    lastName: "",
                    fullName: "",
                    emailAddress: "",
                    port: "",
                    portEmailAddress: "",
                    errorFirstName: "",
                    errorLastName: "",
                    errorFullName: "",
                    errorEmailAddress: "",
                    progressActive: false,
                    textFieldDisabled: false,
                    loading: false,
                    success: false,
                    validUID: false
                });
            }
        );
    };

    handleRegistration = () => {
        const {
            firstName,
            lastName,
            emailAddress,
            port,
            portEmailAddress
        } = this.state;
        const defaultPassword = random("aA0", 10);
        if (
            firstName !== "" &&
            lastName !== "" &&
            emailAddress !== "" &&
            port !== "" &&
            portEmailAddress !== ""
        ) {
            if (!this.validateEmail(emailAddress)) {
                this.setState({
                    errorEmailAddress: "Please enter valid Email Id",
                    emailAddress: ""
                });
            } else {
                this.setState(
                    {
                        progressActive: true,
                        textFieldDisabled: true,
                        loading: true
                    },
                    () => {
                        axios
                            .post("/api/onBoardPort/", {
                                name: port,
                                email: portEmailAddress
                            })
                            .then(res => {
                                axios
                                    .post("/api/user/", {
                                        username: emailAddress,
                                        email: emailAddress,
                                        name: firstName.concat(" " + lastName),
                                        firstName: firstName,
                                        lastName: lastName,
                                        port: res.data._id,
                                        userType: "port-operator",
                                        password: defaultPassword,
                                        default_password: defaultPassword
                                    })
                                    .then(res => {
                                        axios
                                            .put("/api/onBoardPort/contact/" + res.data.port, {
                                                key_contact: res.data._id
                                            })
                                            .then(() => {
                                                axios
                                                    .post("/api/invite/sendDefaultPasswordToUser", {
                                                        port,
                                                        emailAddress,
                                                        portEmailAddress,
                                                        defaultPassword: defaultPassword
                                                    })
                                                    .then(() => {
                                                        this.setState(
                                                            {
                                                                loading: false,
                                                                success: true
                                                            },
                                                            () => {
                                                                this.setState({
                                                                    successDialog: true
                                                                });
                                                            }
                                                        );
                                                    });
                                            });
                                    });
                            });
                    }
                );
            }
        }
    };

    componentDidMount() {
        const {email, id} = this.props.match.params;
        this.setState(
            {
                validUID: false
            },
            () => {
                axios.get("/api/invite/" + id).then((res, err) => {
                    if (err) {
                        alert("Error");
                    } else if (res.status === 200 && res.data.portEmail === email) {
                        axios
                            .get("/api/invite/checkEmailLink/" + res.data.portEmail)
                            .then(res => {
                                if (res.data.status !== 1 && res.data.status < 1) {
                                    this.setState({
                                        validUID: true,
                                        port: res.data.portName,
                                        portEmailAddress: res.data.portEmail
                                    });
                                } else {
                                    this.setState(
                                        {
                                            openAlreadyRegisteredSnackbar: true
                                        },
                                        () => {
                                            return <Redirect to={"/login"}/>;
                                        }
                                    );
                                }
                            });
                    } else {
                        alert(
                            "Not Found. Please ask Port Admin to send the Invitation again"
                        );
                        return <Redirect to={"/login"}/>;
                    }
                });
            }
        );
    }

    render() {
        const {
            firstName,
            lastName,
            emailAddress,
            port,
            portEmailAddress,
            errorFirstName,
            errorLastName,
            errorEmailAddress,
            success,
            loading,
            progressActive,
            textFieldDisabled,
            successDialog,
            validUID,
            openAlreadyRegisteredSnackbar
        } = this.state;
        const {classes, fullScreen} = this.props;
        const buttonClassname = clsx({
            [classes.buttonSuccess]: success
        });

        return !JSON.parse(localStorage.getItem("user")) &&
        !(
            JSON.parse(localStorage.getItem("user")) &&
            JSON.parse(localStorage.getItem("user"))["token"]
        ) ? (
            validUID ? (
                <React.Fragment>
                    <CssBaseline/>
                    <AppBar
                        position="absolute"
                        color="default"
                        className={classes.appBar}
                    >
                        <Toolbar>
                            <Typography variant="h6" color="inherit" noWrap>
                                AP PORTS
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <main className={classes.layout}>
                        <Paper className={classes.paper}>
                            <Typography
                                component="h1"
                                variant="h4"
                                align="center"
                                gutterBottom
                            >
                                Port Registration
                            </Typography>
                            <React.Fragment>
                                <Grid container spacing={24}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="firstName"
                                            name="firstName"
                                            label="First name"
                                            value={firstName}
                                            onChange={e => {
                                                if (e.target.value === "") {
                                                    this.setState({
                                                        errorFirstName: "Please Enter First Name",
                                                        firstName: ""
                                                    });
                                                } else {
                                                    this.setState({
                                                        firstName: e.target.value,
                                                        errorFirstName: ""
                                                    });
                                                }
                                            }}
                                            error={errorFirstName.length !== 0}
                                            helperText={errorFirstName}
                                            disabled={textFieldDisabled}
                                            type="string"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="lastName"
                                            name="lastName"
                                            label="Last name"
                                            value={lastName}
                                            onChange={e => {
                                                if (e.target.value === "") {
                                                    this.setState({
                                                        errorLastName: "Please Enter Last Name",
                                                        lastName: ""
                                                    });
                                                } else {
                                                    this.setState({
                                                        lastName: e.target.value,
                                                        errorLastName: ""
                                                    });
                                                }
                                            }}
                                            error={errorLastName.length !== 0}
                                            helperText={errorLastName}
                                            disabled={textFieldDisabled}
                                            type="string"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            id="emailAddress"
                                            name="emailAddress"
                                            label="User Email (Default password will be sent here)"
                                            value={emailAddress}
                                            onChange={e => {
                                                if (e.target.value === "") {
                                                    this.setState({
                                                        errorEmailAddress: "Please Enter Email Address",
                                                        emailAddress: ""
                                                    });
                                                } else {
                                                    this.setState({
                                                        emailAddress: e.target.value,
                                                        errorEmailAddress: ""
                                                    });
                                                }
                                            }}
                                            error={errorEmailAddress.length !== 0}
                                            helperText={errorEmailAddress}
                                            disabled={textFieldDisabled}
                                            type="string"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            id="port"
                                            name="port"
                                            label="Port"
                                            value={port}
                                            disabled={true}
                                            type="string"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            id="PortEmailAddress"
                                            name="portEmailAddress"
                                            label="Port Email Address"
                                            value={portEmailAddress}
                                            disabled={true}
                                            type="string"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                            <React.Fragment>
                                <div className={classes.buttons}>
                                    {progressActive ? (
                                        <div className={classes.wrapper}>
                                            <Fab color="primary" className={buttonClassname}>
                                                {success ? <CheckIcon/> : <SaveIcon/>}
                                            </Fab>
                                            {loading && (
                                                <CircularProgress
                                                    size={68}
                                                    className={classes.fabProgress}
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.handleRegistration}
                                            className={classes.button}
                                            ype="submit"
                                            disabled={
                                                firstName === "" ||
                                                lastName === "" ||
                                                emailAddress === "" ||
                                                port === "" ||
                                                portEmailAddress === ""
                                            }
                                        >
                                            Register
                                        </Button>
                                    )}
                                </div>
                            </React.Fragment>
                        </Paper>

                        <div>
                            <Dialog
                                fullScreen={fullScreen}
                                open={successDialog}
                                onClose={this.handleClose}
                                aria-labelledby="responsive-dialog-title"
                            >
                                <DialogTitle id="responsive-dialog-title">
                                    {"Registered Successfully"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Please login by using{" "}
                                        <p style={{color: "blue"}}>{emailAddress}</p> as username
                                        and default password sent to the user email ID.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleClose} color="primary" autoFocus>
                                        Okay
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </main>
                </React.Fragment>
            ) : openAlreadyRegisteredSnackbar === true ? (
                <AlreadyRegisteredSnackBar
                    openAlreadyRegisteredSnackbar={openAlreadyRegisteredSnackbar}
                    closeAlreadyRegisteredSnackbar={this.closeAlreadyRegisteredSnackbar}
                />
            ) : (
                <NotFound/>
            )
        ) : (
            <NotFound/>
        );
    }
}

PortInviteRegistration.propTypes = {
    classes: PropTypes.object.isRequired
};

const combinedStyles = combineStyles(styles, RegistrationStyles);

export default withMobileDialog()(
    withStyles(combinedStyles)(PortInviteRegistration)
);
