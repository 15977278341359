import React, {Component} from "react";
import {Link} from "react-router-dom";
import "./NotFound.css";

export default class NotFound extends Component {
    constructor() {
        super();
        this.state = {
            redirectTo: ""
        };
    }

    componentDidMount() {
        JSON.parse(localStorage.getItem("user"))
            ? this.setState({
                redirectTo: JSON.parse(localStorage.getItem("user")).userType
            })
            : this.setState({
                redirectTo: ""
            });
    }

    render() {
        const {redirectTo} = this.state;

        return (
            <div className="bg-purple">
                <div className="stars">
                    <div className="custom-navbar">
                        <div className="brand-logo">
                            <img
                                src={require("../../assets/images/logo.png")}
                                style={{width: "80px"}}
                                alt=""
                            />
                        </div>
                        <div className="navbar-links">
                            <ul>
                                <li>
                                    {redirectTo === "port-operator" ? (
                                        <Link className="btn-request" to="/dashboard/analytics">
                                            Home
                                        </Link>
                                    ) : redirectTo === "port-admin" ? (
                                        <Link className="btn-request" to="/dashboard/analytics">
                                            Home
                                        </Link>
                                    ) : (
                                        <Link className="btn-request" to="/login">
                                            Home
                                        </Link>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="central-body">
                        <img
                            className="image-404"
                            src={require("../../assets/images/404.svg")}
                            width="300px"
                            alt=""
                        />
                        {/*<Link className="btn-request" to="/dashboard/imports">*/}
                        {/*    Return to Home Page*/}
                        {/*</Link>*/}
                    </div>
                    <div className="objects"/>
                    <div className="glowing_stars">
                        <div className="star"/>
                        <div className="star"/>
                        <div className="star"/>
                        <div className="star"/>
                        <div className="star"/>
                    </div>
                </div>
            </div>
        );
    }
}
