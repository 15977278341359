import React, {Component} from "react";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import SiteDesign from "../SiteDesign/SiteDesign";
import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faFilter,
    faDownload,
    faCalendarAlt,
    faShip,
    faAnchor,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import CssBaseline from "@material-ui/core/CssBaseline";
import Login from "../../Components/DashBoardViewComponents/Login/Login";

library.add(faFilter, faDownload, faCalendarAlt, faShip, faAnchor, faUser);

const theme = (themeType, sizeFont) =>
    createMuiTheme({
        palette: {
            type: themeType ? "dark" : "light",
            text: {
                main: themeType ? "#FFFFFF" : "#000000"
            },
            primary: {
                main: themeType ? "#f32c2c" : "#2196f3"
            },
            secondary: {
                main: themeType ? "#ffc20f" : "#ffc20f"
            },
            error: {
                main: themeType ? "#f45d3d" : "#69281a"
            }
        },
        typography: {
            useNextVariants: true,
            fontSize: sizeFont ? 16 : "62.5%",
            fontFamily: [
                "-apple-system",
                "BlinkMacSystemFont",
                '"Helvetica Neue"',
                '"Segoe UI"',
                "Roboto",
                "Arial",
                "sans-serif",
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"'
            ].join(","),
            //All text in header bar except profile name
            h1: {
                fontSize: sizeFont ? "1.7rem" : "1.5rem",
                fontWeight: 400,
                fontFamily: "Helvetica Neue"
            },
            //Each module toolbar title
            h2: {
                fontSize: sizeFont ? "2.2rem" : "2rem",
                fontFamily: "Helvetica Neue"
            },
            //Each column heading of the table and others
            h3: {
                fontSize: sizeFont ? "1.1rem" : "0.9rem",
                fontWeight: 500,
                fontFamily: "Helvetica Neue"
            },
            //Table body text
            h4: {
                fontSize: sizeFont ? "1.1rem" : "0.9rem",
                fontFamily: "Helvetica Neue"
            },
            //Title of every Dialog
            h5: {
                fontSize: sizeFont ? "1.5rem" : "1.2rem",
                fontFamily: "Helvetica Neue"
            },
            //Tooltip text size
            h6: {
                fontSize: sizeFont ? "1.2rem" : "0.9rem",
                fontFamily: "Helvetica Neue"
            }
        },
        overrides: {
            MuiDialogTitle: {
                root: {
                    backgroundColor: themeType ? "#f32c2c" : "#2196f3"
                }
            },
            MuiDialogContent: {
                root: {
                    backgroundColor: themeType ? "#303030" : "#fafafa"
                }
            },
            MuiDialogActions: {
                root: {
                    backgroundColor: themeType ? "#424242" : "#ffffff"
                }
            },
            MuiTablePagination: {
                caption: {
                    fontSize: sizeFont ? "1.1rem" : "0.9rem",
                    fontFamily: "Helvetica Neue"
                },
                input: {
                    fontSize: sizeFont ? "1.1rem" : "0.9rem",
                    fontWeight: 400,
                    fontFamily: "Helvetica Neue"
                },
                select: {
                    width: 20,
                    paddingLeft: 8,
                    paddingRight: 20
                }
            }
        }
    });

class App extends Component {
    state = {
        themeType: false,
        sizeFont: false
    };

    handleThemeTypeChange = () => {
        this.state.themeType
            ? this.setState({
                themeType: false
            })
            : this.setState({
                themeType: true
            });
    };

    handleFontSizeSmall = () => {
        this.setState({
            sizeFont: false
        });
    };

    handleFontSizeLarge = () => {
        this.setState({
            sizeFont: true
        });
    };

    render() {
        const {themeType, sizeFont} = this.state;

        return (
            <MuiThemeProvider theme={theme(themeType, sizeFont)}>
                <React.Fragment>
                    <CssBaseline/>

                    {JSON.parse(localStorage.getItem("user")) &&
                    JSON.parse(localStorage.getItem("user"))["token"] ? (
                        <SiteDesign
                            handleThemeTypeChange={this.handleThemeTypeChange}
                            themeType={themeType}
                            handleFontSizeSmall={this.handleFontSizeSmall}
                            handleFontSizeLarge={this.handleFontSizeLarge}
                            sizeFont={sizeFont}
                        />
                    ) : (
                        <Login/>
                    )}
                </React.Fragment>
            </MuiThemeProvider>
        );
    }
}

export default App;
