import React, {Component} from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {styles} from "../commonStyles/ModuleItemListStyles";
import toolbarStyles from "../commonStyles/toolbarStyles";
import combineStyles from "../commonStyles/combineStyles";
import {withStyles} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

let rows;

const rowsZero = [
    {
        tableHeaderProp: "s_no",
        disablePadding: false,
        label: "S.no"
    },
    {
        tableHeaderProp: "imported_commodity_name",
        disablePadding: false,
        label: "Commodity"
    },
    {
        tableHeaderProp: "qty_in_mts",
        disablePadding: false,
        label: "Quantity (M.Tons)"
    },
    {
        tableHeaderProp: "imported_year",
        disablePadding: false,
        label: "Imported Year"
    },
    {
        tableHeaderProp: "imported_month",
        disablePadding: false,
        label: "Imported Month"
    },
    {
        tableHeaderProp: "no_of_vessels_per_day",
        disablePadding: false,
        label: "Vessel Count"
    },
    {
        tableHeaderProp: "voyage_type",
        disablePadding: false,
        label: "Voyage Type"
    },
    {
        tableHeaderProp: "flag_type",
        disablePadding: false,
        label: "Flag Type"
    },
];

const rowsOne = [
    {
        tableHeaderProp: "s_no",
        disablePadding: false,
        label: "S.no"
    },
    {
        tableHeaderProp: "exported_commodity_name",
        disablePadding: false,
        label: "Commodity"
    },
    {
        tableHeaderProp: "qty_in_mts",
        disablePadding: false,
        label: "Quantity (M.Tons)"
    },
    {
        tableHeaderProp: "exported_year",
        disablePadding: false,
        label: "Exported Year"
    },
    {
        tableHeaderProp: "exported_month",
        disablePadding: false,
        label: "Exported Month"
    },
    {
        tableHeaderProp: "no_of_vessels_per_day",
        disablePadding: false,
        label: "Vessel Count"
    },
    {
        tableHeaderProp: "voyage_type",
        disablePadding: false,
        label: "Voyage Type"
    },
    {
        tableHeaderProp: "flag_type",
        disablePadding: false,
        label: "Flag Type"
    },
];

const rowsTwo = [
    {
        tableHeaderProp: "s_no",
        disablePadding: false,
        label: "S.no"
    },
    {
        tableHeaderProp: "revenue_year",
        disablePadding: false,
        label: "Revenue Year"
    },
    {
        tableHeaderProp: "revenue_month",
        disablePadding: false,
        label: "Revenue Month"
    },
    {
        tableHeaderProp: "revenue_amount",
        disablePadding: false,
        label: "Revenue Amount"
    },
];

// const rowsTwo = [
//     {
//         tableHeaderProp: "s_no",
//         disablePadding: false,
//         label: "S.no"
//     },
//     {
//         tableHeaderProp: "vessel_type",
//         disablePadding: false,
//         label: "Vessel Type"
//     },
//     {
//         tableHeaderProp: "no_of_vessels",
//         disablePadding: false,
//         label: "No of Vessels"
//     },
// ];
//
// const rowsThree = [
//     {
//         tableHeaderProp: "s_no",
//         disablePadding: false,
//         label: "S.no"
//     },
//     {
//         tableHeaderProp: "imported_commodity_name",
//         disablePadding: false,
//         label: "Commodity"
//     },
//     {
//         tableHeaderProp: "no_of_vessels",
//         disablePadding: false,
//         label: "No of Vessels"
//     },
//     {
//         tableHeaderProp: "total_cargo",
//         disablePadding: false,
//         label: "Total Cargo(in Tonnes)"
//     },
//     {
//         tableHeaderProp: "avg_parcel_size",
//         disablePadding: false,
//         label: "Avg. Parcel Size (in Tonnes)"
//     },
//     {
//         tableHeaderProp: "total_ship_size",
//         disablePadding: false,
//         label: "Total Ship Size(DWT) in Tonnes"
//     },
//     {
//         tableHeaderProp: "avg_ship_size",
//         disablePadding: false,
//         label: "Avg. Ship Size (DWT) in Tonnes"
//     },
// ];
//
// const rowsFour = [
//     {
//         tableHeaderProp: "s_no",
//         disablePadding: false,
//         label: "S.no"
//     },
//     {
//         tableHeaderProp: "commodity",
//         disablePadding: false,
//         label: "Commodity"
//     },
//     {
//         tableHeaderProp: "avg_preberthing_waitingtime_port",
//         disablePadding: false,
//         label: "Avg. Pre Berthing Waiting Time of Ships (On Port Account/ HH:MM:SS)"
//     },
//     {
//         tableHeaderProp: "avg_preberthing_waitingtime_nonport",
//         disablePadding: false,
//         label: "Avg. Pre Berthing Waiting Time of Ships (On Non-Port Account/ HH:MM:SS)"
//     },
// ];
//
// const rowsFive = [
//     {
//         tableHeaderProp: "s_no",
//         disablePadding: false,
//         label: "S.no"
//     },
//     {
//         tableHeaderProp: "imported_commodity_name",
//         disablePadding: false,
//         label: "Commodity"
//     },
//     {
//         tableHeaderProp: "avg_ship_berth_throughput",
//         disablePadding: false,
//         label: "Avg. Ship Berth Throughput (In Tonnes)"
//     },
//     {
//         tableHeaderProp: "standard_ship_berth_throughput",
//         disablePadding: false,
//         label: "Standard Ship Berth Throughput (In Tonnes)"
//     },
// ];
//
// const rowsSix = [
//     {
//         tableHeaderProp: "s_no",
//         disablePadding: false,
//         label: "S.no"
//     },
//     {
//         tableHeaderProp: "berth_hire",
//         disablePadding: false,
//         label: "Berth Hire"
//     },
//     {
//         tableHeaderProp: "wharfage",
//         disablePadding: false,
//         label: "Wharfage"
//     },
//     {
//         tableHeaderProp: "other_services",
//         disablePadding: false,
//         label: "Other Services"
//     },
//     {
//         tableHeaderProp: "port_dues",
//         disablePadding: false,
//         label: "Port Dues"
//     },
//     {
//         tableHeaderProp: "total",
//         disablePadding: false,
//         label: "Total"
//     },
// ];
//
// const rowsSeven = [
//     {
//         tableHeaderProp: "s_no",
//         disablePadding: false,
//         label: "S.no"
//     },
//     {
//         tableHeaderProp: "location",
//         disablePadding: false,
//         label: "Location (City)"
//     },
//     {
//         tableHeaderProp: "work_description",
//         disablePadding: false,
//         label: "Description of Work"
//     },
//     {
//         tableHeaderProp: "status_of_work",
//         disablePadding: false,
//         label: "Status of Work"
//     },
//     {
//         tableHeaderProp: "date_of_commencement",
//         disablePadding: false,
//         label: "Date of Commencement"
//     },
//     {
//         tableHeaderProp: "date_of_completion",
//         disablePadding: false,
//         label: "Date of Completion"
//     },
// ];
//
// const rowsEight = [
//     {
//         tableHeaderProp: "s_no",
//         disablePadding: false,
//         label: "S.no"
//     },
//     {
//         tableHeaderProp: "commodity_name",
//         disablePadding: false,
//         label: "Commodity Name"
//     },
//     {
//         tableHeaderProp: "import_income_amount",
//         disablePadding: false,
//         label: "Imports Amount (Rs)"
//     },
//     {
//         tableHeaderProp: "export_income_amount",
//         disablePadding: false,
//         label: "Exports Amount (Rs)"
//     },
// ];
//
// const rowsNine = [
//     {
//         tableHeaderProp: "s_no",
//         disablePadding: false,
//         label: "S.no"
//     },
//     {
//         tableHeaderProp: "vessel_data_name",
//         disablePadding: false,
//         label: "Vessel Name"
//     },
//     {
//         tableHeaderProp: "date_of_arrival",
//         disablePadding: false,
//         label: "Date of Arrival"
//     },
//     {
//         tableHeaderProp: "berthing_time",
//         disablePadding: false,
//         label: "Berthing Time"
//     },
//     {
//         tableHeaderProp: "unberthing_time",
//         disablePadding: false,
//         label: "Un Berthing Time"
//     },
//     {
//         tableHeaderProp: "turn_around_time",
//         disablePadding: false,
//         label: "Turn Around Time"
//     },
//     {
//         tableHeaderProp: "date_of_departure",
//         disablePadding: false,
//         label: "Date of Departure"
//     },
// ];
//
// const rowsTen = [
//     {
//         tableHeaderProp: "s_no",
//         disablePadding: false,
//         label: "S.no"
//     },
//     {
//         tableHeaderProp: "no_of_trucks",
//         disablePadding: false,
//         label: "No of Trucks in a Month"
//     },
//     {
//         tableHeaderProp: "no_of_trips",
//         disablePadding: false,
//         label: "Total No of Trips in a Month"
//     },
//     {
//         tableHeaderProp: "trips_month",
//         disablePadding: false,
//         label: "Trips Month"
//     },
// ];

class EnhancedTableHead extends Component {
    state = {
        arrowRef: null
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node
        });
    };

    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {

        const {classes, tabValue, order, orderBy} = this.props;

        switch (tabValue) {
            case 0:
                rows = rowsZero;
                break;
            case 1:
                rows = rowsOne;
                break;
            case 2:
                rows = rowsTwo;
                break;
            // case 2:
            //     rows = rowsTwo;
            //     break;
            // case 3:
            //     rows = rowsThree;
            //     break;
            // case 4:
            //     rows = rowsFour;
            //     break;
            // case 5:
            //     rows = rowsFive;
            //     break;
            // case 6:
            //     rows = rowsSix;
            //     break;
            // case 7:
            //     rows = rowsSeven;
            //     break;
            // case 8:
            //     rows = rowsEight;
            //     break;
            // case 9:
            //     rows = rowsNine;
            //     break;
            // case 10:
            //     rows = rowsTen;
            //     break;
            default:
                return null;
        }

        return (
            <TableHead>
                <TableRow style={{height: 45}}>
                    {rows.map(
                        row => (
                            <TableCell
                                className={classes.row}
                                key={row.tableHeaderProp}
                                align="center"
                                padding={row.disablePadding ? "none" : "default"}
                                sortDirection={orderBy === row.tableHeaderProp ? order : false}
                                onClick={this.createSortHandler(row.tableHeaderProp)}
                            >
                                <Tooltip title={<h6>Sort by {row.label}</h6>}>
                                    <TableSortLabel
                                        active={orderBy === row.tableHeaderProp}
                                        direction={order}
                                        onClick={this.createSortHandler(row.tableHeaderProp)}
                                    >
                                        <Typography variant="h3">{row.label}</Typography>
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        ),
                        this
                    )}
                </TableRow>
            </TableHead>
        )
    }
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired
};

const combinedStyles = combineStyles(styles, toolbarStyles);

export default withStyles(combinedStyles)(EnhancedTableHead);
