import React, {Component} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import {styles} from "../commonStyles/ModuleItemListStyles";
import toolbarStyles from "../commonStyles/toolbarStyles";
import combineStyles from "../commonStyles/combineStyles";
import {withStyles} from "@material-ui/core";

const rows = [
    {
        tableHeaderProp: "revenue_year",
        disablePadding: false,
        label: "Revenue Year"
    },
    {
        tableHeaderProp: "revenue_month",
        disablePadding: false,
        label: "Revenue Month"
    },
    {
        tableHeaderProp: "revenue_amount",
        disablePadding: false,
        label: "Revenue Amount (Rs. in crs)"
    },
];

class TableHeadRevenue extends Component {

    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {
            order,
            orderBy,
        } = this.props;

        const {classes} = this.props;

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox"> </TableCell>
                    {rows.map(
                        row => (
                            <TableCell
                                className={classes.row}
                                key={row.tableHeaderProp}
                                align="center"
                                padding={row.disablePadding ? "none" : "default"}
                                sortDirection={orderBy === row.tableHeaderProp ? order : false}
                            >
                                <Tooltip title={<h6>Sort by {row.label}</h6>}>
                                    <TableSortLabel
                                        active={orderBy === row.tableHeaderProp}
                                        direction={order}
                                        onClick={this.createSortHandler(row.tableHeaderProp)}
                                    >
                                        <Typography variant="h3">{row.label}</Typography>
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        ),
                        this
                    )}
                </TableRow>
            </TableHead>
        )
    }
}

const combinedStyles = combineStyles(styles, toolbarStyles);

export default withStyles(combinedStyles)(TableHeadRevenue);
