import axios from "axios";

export default function() {
  const token =
    JSON.parse(localStorage.getItem("user")) &&
    JSON.parse(localStorage.getItem("user"))["token"];
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
  }
}

/* Set default header which will be sent with every request I make with axios
https://github.com/axios/axios#global-axios-defaults

No longer need to attach token manually to every request. I can place the above function in the file which is guaranteed to be executed every time (e.g: File which contains the routes).

Without it, I had to add the following as the last argument to each and every axios call.

{
    headers: {
        "Content-Type": "application/json",
        Authorization:
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user"))["token"]
    }
}

*/
