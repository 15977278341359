import React, {Component} from "react";
import Chart from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.plugins.unregister(ChartDataLabels);

class BarChart extends Component {

    ref = React.createRef();

    componentDidMount() {
        const {current} = this.ref;
        this.myBarChart = new Chart(current, {
            type: 'bar',
            data: {
                labels: [
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                    'January',
                    'February',
                    'March',
                ],
                datasets: this.props.data,
            },
            plugins: [ChartDataLabels],
            options: {
                responsive: true,
                maintainAspectRatio: false,
                title: {
                    display: true,
                    position: "top",
                    text: this.props.title,
                    fontSize: 16,
                    fontColor: "#111"
                },
                legend: {
                    display: false,
                    position: "bottom",
                    labels: {
                        fontColor: "#333",
                        fontSize: 16
                    }
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                        }
                    }],
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                        }
                    }]
                },
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {current} = this.ref;
        this.myBarChart.destroy();
        this.myBarChart = new Chart(current, {
            type: 'bar',
            data: {
                labels: [
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                    'January',
                    'February',
                    'March',
                ],
                datasets: this.props.data,
            },
            plugins: [ChartDataLabels],
            options: {
                responsive: true,
                maintainAspectRatio: false,
                title: {
                    display: true,
                    position: "top",
                    text: this.props.title,
                    fontSize: 16,
                    fontColor: "#111"
                },
                legend: {
                    display: false,
                    position: "bottom",
                    labels: {
                        fontColor: "#333",
                        fontSize: 16
                    }
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                        }
                    }],
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                        }
                    }]
                },
            }
        });
    }

    render() {
        return (
            <canvas ref={this.ref}> </canvas>
        )
    }
}

export default BarChart;
