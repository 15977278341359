import React, {Component} from "react";
import {Col, Row} from "reactstrap";
import PropTypes from "prop-types";
// import {confirmAlert} from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../UtilFunctions/confirmDelete.css";
import "../UtilFunctions/snackbar.css";
// import {showDeleteSnackbar} from "../UtilFunctions/showEmptyFieldAndDeleteSnackbar";
import {withStyles} from "@material-ui/core";
import axios from "axios";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
import {styles} from "../commonStyles/ModuleItemListStyles";
import toolbarStyles from "../commonStyles/toolbarStyles";
import combineStyles from "../commonStyles/combineStyles";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import TableToolbarOnBoardPort from "./TableToolbarOnBoardPort";
import EnhancedTableHead from "./EnhancedTableHead";
import orderByLodash from "lodash/orderBy";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import {Helmet} from "react-helmet";
import setAxiosAuthheaders from "../UtilFunctions/setAxiosAuthheaders";

const _ = require("lodash");
const tableSortByHeadingUtilFunc = require("../UtilFunctions/tableSortByHeading");

const CustomTableCell = withStyles(theme => ({
    head: {
        background: "#66CCFF",
        color: theme.palette.common.white,
        align: "left"
    },
    body: {
        fontSize: 14,
        align: "left"
    }
}))(TableCell);

class OnBoardPortList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allOnBoardPortFormatted: [],
            queryStringFromChild: "",
            columnToQuery: "name",
            order: "desc",
            orderBy: "name",
            selected: []
        };
    }

    componentWillMount() {
        setAxiosAuthheaders();
    }

    // function to handle the placement of the arrow on top of the Tooltip
    handleArrowRef = node => {
        this.setState({
            arrowRef: node
        });
    };

    componentDidMount() {
        axios.get("/api/portInvite/").then(res => {
            this.setState({
                allOnBoardPortFormatted: res.data
            });
        });
    }

    // Function to handle the the request from user to sort by a particular heading.
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }
        // In below I am setting the state with destructuring, given both key-value is the same word. So in setState, I just mention the key from the state variable.
        this.setState({order, orderBy});
    };

    // Function to return true if an item is selected or false if NOT
    isSelected = id => this.state.selected.indexOf(id) !== -1;

    handleSelectAllClick = event => {
        if (event.target.checked) {
            if (this.state.queryStringFromChild === "") {
                this.setState(state => ({
                    selected: state.allOnBoardPortFormatted.map(n => n._id)
                }));
                return;
            } else if (this.state.queryStringFromChild !== "") {
                const lowerCaseQuery = this.state.queryStringFromChild.toLowerCase();

                const totalTextQueryResult = this.state.allOnBoardPortFormatted.filter(
                    item => {
                        return (
                            item[this.state.columnToQuery] &&
                            item[this.state.columnToQuery]
                                .toLowerCase()
                                .includes(lowerCaseQuery)
                        );
                    }
                );
                this.setState(() => ({
                    selected: totalTextQueryResult.map(n => n._id)
                }));
                return;
            }
        }
        this.setState({selected: []});
    };

    unSelectItems = () => {
        this.setState({
            selected: []
        });
    };

    // Function to add item's _id to the 'selected' array when I click on them and make them checked
    handleClick = (event, id) => {
        // event.stopPropagation();
        const {selected} = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            // If its a new item that has been selected, then concat it to the old array of selections
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        this.setState({selected: newSelected});
    };

    returnDocumentToEdit = id => {
        if (this.state.selected.length !== 0) {
            return this.state.allOnBoardPortFormatted.filter(item => item._id === id);
        }
    };

    handleQueryString = queryTypedInChild => {
        this.setState({
            queryStringFromChild: queryTypedInChild
        });
    };

    handleColumnToQuery = columnToQueryInChild => {
        this.setState({
            columnToQuery: columnToQueryInChild
        });
    };

    clearAllQueryString = () => {
        this.setState({
            queryStringFromChild: "",
            columnToQuery: "name"
        });
    };

    // editItem = () => {
    //     axios.get("/api/onBoardPort/").then(res => {
    //         this.setState({
    //             allOnBoardPortFormatted: res.data
    //         });
    //     });
    // };

    // confirmDeleteCustom = idArr => {
    //     let payload = {
    //         onBoardPort_id: idArr
    //     };
    //     confirmAlert({
    //         customUI: ({onClose}) => {
    //             return (
    //                 <div className="custom-ui">
    //                     <h1>Are you sure?</h1>
    //                     <p>You want to delete this Port</p>
    //                     <button onClick={onClose}>No</button>
    //                     <button
    //                         onClick={() => {
    //                             axios
    //                                 .delete("/api/onBoardPort/delete", {
    //                                     data: payload
    //                                 })
    //                                 .then(() => {
    //                                     let updatedData = this.state.allOnBoardPortFormatted;
    //                                     _.forEach(payload.onBoardPort_id, id => {
    //                                         updatedData = updatedData.filter(item => item._id !== id);
    //                                     });
    //                                     this.setState({
    //                                         allOnBoardPortFormatted: updatedData,
    //                                         selected: []
    //                                     });
    //                                 })
    //                                 .then(() => {
    //                                     showDeleteSnackbar();
    //                                     onClose();
    //                                 })
    //                                 .catch(error => {
    //                                     if (error.response.status === 401) {
    //                                         this.props.history.push("/login");
    //                                         alert("Please Login, session expired");
    //                                         onClose();
    //                                     } else {
    //                                         alert(
    //                                             "Oops something wrong happened while deleting, please try again"
    //                                         );
    //                                     }
    //                                 });
    //                         }}
    //                     >
    //                         Yes, Delete it!
    //                     </button>
    //                 </div>
    //             );
    //         }
    //     });
    // };

    render() {
        const {classes, themeType} = this.props;
        const {
            allOnBoardPortFormatted,
            selected,
            order,
            orderBy,
            queryStringFromChild
        } = this.state;

        const onBoardPortToEdit = this.returnDocumentToEdit(this.state.selected[0]);

        // lowercase the queryStringFromChild string that the user types
        const lowerCaseQuery =
            queryStringFromChild && queryStringFromChild.toLowerCase();

        // filter the whole database returning only the selected items
        const downloadSelectedItems = allOnBoardPortFormatted.filter(item => {
            return selected.indexOf(item._id) !== -1;
        });

        const totalTextQueryResult = allOnBoardPortFormatted.filter(item =>
            this.state.columnToQuery === "name"
                ? item[this.state.columnToQuery]
                : item[this.state.columnToQuery] &&
                item[this.state.columnToQuery].toLowerCase().includes(lowerCaseQuery)
        );

        const onBoardPortToRender = orderByLodash(
            queryStringFromChild ? totalTextQueryResult : allOnBoardPortFormatted
        );

        return (
            <MuiThemeProvider>
                <div>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>AP MB | On Board Ports</title>
                        <meta name="description" content="AP Maritime Board Ports!"/>
                        <meta name="theme-color" content="#008f68"/>
                        <body/>
                    </Helmet>
                    <Row>
                        <Col xs="12">
                            <Paper className={classes.root}>
                                <TableToolbarOnBoardPort
                                    allOnBoardPortFormatted={allOnBoardPortFormatted}
                                    numSelected={selected.length}
                                    // confirmDeleteCustom={this.confirmDeleteCustom}
                                    checkedItems={selected}
                                    onBoardPortToEdit={onBoardPortToEdit}
                                    // editItem={this.editItem}
                                    unSelectItems={this.unSelectItems}
                                    downloadSelectedItems={downloadSelectedItems}
                                    handleColumnToQuery={this.handleColumnToQuery}
                                    handleQueryString={this.handleQueryString}
                                    clearAllQueryString={this.clearAllQueryString}
                                    themeType={themeType}
                                />
                                <div className={classes.tableWrapper}>
                                    <Table className={classes.table}>
                                        <EnhancedTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={this.handleSelectAllClick}
                                            onRequestSort={this.handleRequestSort}
                                            listCount={onBoardPortToRender.length}
                                        />
                                        <TableBody>
                                            {tableSortByHeadingUtilFunc
                                                .stableSort(
                                                    onBoardPortToRender,
                                                    tableSortByHeadingUtilFunc.getSorting(order, orderBy)
                                                )
                                                .map((n, index) => {
                                                    const isSelected = this.isSelected(n._id);
                                                    return (
                                                        <TableRow
                                                            hover
                                                            // onClick={event => this.handleClick(event, n._id)}
                                                            role="checkbox"
                                                            aria-checked={isSelected}
                                                            tabIndex={-1}
                                                            key={n._id}
                                                            selected={isSelected}
                                                            style={{
                                                                height: "35px"
                                                            }}
                                                        >
                                                            <CustomTableCell
                                                                padding="checkbox"
                                                                style={{
                                                                    width: "10px"
                                                                }}
                                                            >
                                                                <Checkbox
                                                                    onClick={event => this.handleClick(event, n._id)}
                                                                    checked={isSelected}
                                                                />
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {<Typography variant="h4">{index + 1}</Typography>}
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {<Typography variant="h4">{n.port_name}</Typography>}
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {<Typography variant="h4">{n.port_email}</Typography>}
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {<Typography variant="h4">{n.phone_number}</Typography>}
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {<Typography variant="h4">{n.no_of_berths}</Typography>}
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {<Typography variant="h4">{n.revenue_share}</Typography>}
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {<Typography variant="h4">{n.user_type === "port-admin" ? "ADMIN" : "PP"}</Typography>}
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Button variant="contained" size="small" color="primary" href={n.url}>
                                                                        Link
                                                                    </Button>
                                                                }
                                                                {/*{<Typography variant="h4">{n.url}</Typography>}*/}
                                                            </CustomTableCell>
                                                            {/*<CustomTableCell*/}
                                                            {/*    className={classes.customTableCell}*/}
                                                            {/*    align="center"*/}
                                                            {/*    style={{*/}
                                                            {/*        width: "40%",*/}
                                                            {/*        overflow: "auto"*/}
                                                            {/*    }}*/}
                                                            {/*>*/}
                                                            {/*    {<Typography variant="h4">*/}
                                                            {/*        {n.address ? n.address + ", " : ""}*/}
                                                            {/*        {n.city ? n.city + ", " : ""}*/}
                                                            {/*        {n.state ? n.state + ", " : ""}*/}
                                                            {/*        {n.country ? n.country + ", " : ""}*/}
                                                            {/*        {n.pincode ? n.pincode + "." : ""}*/}
                                                            {/*    </Typography>}*/}
                                                            {/*</CustomTableCell>*/}
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </div>
                                <div id="snackbar">
                                    The Port you selected has been successfully deleted
                                </div>
                            </Paper>
                        </Col>
                    </Row>
                </div>
            </MuiThemeProvider>
        );
    }
}

OnBoardPortList.propTypes = {
    classes: PropTypes.object.isRequired
};

const combinedStyles = combineStyles(styles, toolbarStyles);

export default withStyles(combinedStyles)(OnBoardPortList);
