import React, {Component} from "react";
import classNames from "classnames";
import {withStyles} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from "@material-ui/icons/GetApp";
import toolbarStyles from "../commonStyles/toolbarStyles";
import {CSVLink} from "react-csv";
// import ShowImport from "./ShowImport";
import EditImports from "./EditImportExport";
import Paper from "@material-ui/core/Paper";
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const moment = require("moment");

class TableToolbarImportExport extends Component {

    state = {
        selectedYear: new Date(),
    };

    handleSelectedYearChange = date => {
        this.setState({
            selectedYear: date
        }, () => {
            this.props.getDataForYear(
                moment(this.state.selectedYear).startOf("year").toISOString(),
                moment(this.state.selectedYear).endOf("year").toISOString()
            );
        });
    };

    handleAllYears = () => {
        this.props.getDataForYearsOnly();
    };

    render() {
        const {
            classes,
            selected,
            itemToEdit,
            editItem,
            deleteItem,
            themeType,
            importExportList,
            downloadSelectedItems,
            allCommoditiesForSiblingCommunication
        } = this.props;

        const {
            selectedYear,
        } = this.state;

        return (
            <Toolbar className={classNames(classes.root, {[classes.highlight]: selected.length > 0})}>
                <div className={classes.title}>
                    {selected.length === 1 ? (
                        <Typography color="inherit" variant="h3">
                            {selected.length} item selected
                        </Typography>
                    ) : selected.length > 1 ? (
                        <Typography color="inherit" variant="h3">
                            {selected.length} items selected
                        </Typography>
                    ) : null}
                </div>

                <div className={classes.spacer}/>

                {selected.length < 1 ? (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Paper className={classes.monthSelectorPaper}>
                            <Typography variant="h5">
                                Imports & Exports for year
                            </Typography>
                            <DatePicker
                                autoOk
                                views={["year"]}
                                value={selectedYear}
                                onChange={date => this.handleSelectedYearChange(date)}
                                classes={{
                                    root: classes.selectedMonthDatePicker
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    classes: {
                                        input: classes.textFieldSelectedMonth,
                                    },
                                }}
                            />
                        </Paper>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <Button
                            onClick={this.handleAllYears}
                            style={{width: 200, height: 50}}
                            color="primary"
                            variant="contained"
                            size="large"
                            type="submit"
                        >
                            Only Years
                        </Button>
                    </MuiPickersUtilsProvider>
                ) : null}

                <div className={classes.spacer}/>

                <div className={classes.actions}>
                    {selected.length > 0 ? (
                        <div style={{display: "flex", flexDirection: "row"}}>

                            <Tooltip title="Download data for the selected item(s)">
                                <CSVLink
                                    data={downloadSelectedItems}
                                    className={classes.csvDownloadSingle}
                                >
                                    <IconButton>
                                        <DownloadIcon
                                            className={classes.tableToolbarIcon}
                                        />
                                    </IconButton>
                                </CSVLink>
                            </Tooltip>

                            {/*{numSelected === 1 ? (*/}
                            {/*    <Tooltip title="View Item Details">*/}
                            {/*        <IconButton>*/}
                            {/*            <ShowImport*/}
                            {/*                itemToEdit={itemToEdit}*/}
                            {/*                checkedItems={this.props.checkedItems}*/}
                            {/*                themeType={themeType}*/}
                            {/*            />*/}
                            {/*        </IconButton>*/}
                            {/*    </Tooltip>*/}
                            {/*) : null}*/}

                            {selected.length === 1 ? (
                                <Tooltip title="Edit">
                                    <IconButton>
                                        <EditImports
                                            editItemToParentState={editItem}
                                            itemToEdit={itemToEdit}
                                            allCommoditiesForSiblingCommunication={allCommoditiesForSiblingCommunication}
                                            themeType={themeType}
                                        />
                                    </IconButton>
                                </Tooltip>
                            ) : null}

                            <Tooltip title="Delete">
                                <IconButton onClick={deleteItem.bind()}>
                                    <DeleteIcon
                                        className={classes.tableToolbarIcon}
                                    />
                                </IconButton>
                            </Tooltip>

                        </div>
                    ) : (
                        <div style={{display: "flex", flexDirection: "row"}}>

                            <div className={classes.spacerCurrentMonth}/>

                            <Tooltip title="Download entire data">
                                <CSVLink
                                    data={importExportList}
                                    className={classes.csvDownloadSingle}
                                >
                                    <IconButton>
                                        <DownloadIcon
                                            className={classes.tableToolbarIcon}
                                        />
                                    </IconButton>
                                </CSVLink>
                            </Tooltip>

                        </div>
                    )}
                </div>
            </Toolbar>
        );
    }
}

export default withStyles(toolbarStyles)(TableToolbarImportExport);
