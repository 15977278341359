import React, {Component} from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {withStyles} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {styles} from "../commonStyles/AddNewItemStyles";
import toolbarStyles from "../commonStyles/toolbarStyles";
import combineStyles from "../commonStyles/combineStyles";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import Typography from "@material-ui/core/Typography";

class ShowOnBoardPort extends Component {
    state = {
        open: false,
        port_name: this.props.onBoardPortToEdit[0].port_name,
        // key_contact: this.props.onBoardPortToEdit[0].key_contact,
        port_email: this.props.onBoardPortToEdit[0].port_email,
        phone_number: this.props.onBoardPortToEdit[0].phone_number,
        no_of_berths: this.props.onBoardPortToEdit[0].no_of_berths,
        revenue_share: this.props.onBoardPortToEdit[0].revenue_share,
        user_type: this.props.onBoardPortToEdit[0].user_type,
        address: this.props.onBoardPortToEdit[0].address,
        state: this.props.onBoardPortToEdit[0].state,
        city: this.props.onBoardPortToEdit[0].city,
        country: this.props.onBoardPortToEdit[0].country,
        pin_code: this.props.onBoardPortToEdit[0].pin_code
    };

    handleToggle = () => {
        this.setState({
            open: !this.state.open
        });
    };

    handleFabOpen = () => {
        this.setState({open: true});
    };

    handleCancel = () => {
        this.setState({open: false});
    };

    render() {
        const {classes, themeType} = this.props;
        const {
            port_name,
            // key_contact,
            port_email,
            phone_number,
            no_of_berths,
            revenue_share,
            user_type,
            address,
            state,
            city,
            country,
            pin_code
        } = this.state;

        return (
            <div>
                <VisibilityIcon
                    onClick={this.handleFabOpen}
                    className={classes.tableToolbarIcon}
                />
                <Dialog
                    open={this.state.open}
                    onClose={this.handleToggle}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth={"sm"}
                    variant="contained"
                >
                    <DialogTitle
                        id="form-dialog-title"
                        className={themeType ? classes.dialogTitleEditDark : classes.dialogTitleEditLight}
                    >
                        <div className={classes.displayFlexDialogTitle}>
                            <Typography variant="h5" className={classes.dialogTitleHeading}>Details of selected on Board
                                Port</Typography>
                            <IconButton
                                onClick={this.handleToggle}
                                style={{float: "right"}}
                                className={classes.button}
                            >
                                <CancelIcon/>
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent required>
                        <TextField
                            classes={{
                                root: classes.space
                            }}
                            value={port_name}
                            disabled={true}
                            className={classes.textFieldVPR}
                            label="Port Name"
                            type="string"
                        />

                        {/*<TextField*/}
                        {/*    required*/}
                        {/*    autoFocus*/}
                        {/*    classes={{*/}
                        {/*        root: classes.space*/}
                        {/*    }}*/}
                        {/*    value={key_contact}*/}
                        {/*    disabled={true}*/}
                        {/*    className={classes.textFieldVPR}*/}
                        {/*    label="Port Operator"*/}
                        {/*    type="string"*/}
                        {/*/>*/}

                        <TextField
                            classes={{
                                root: classes.space
                            }}
                            value={port_email}
                            disabled={true}
                            className={classes.textFieldVPR}
                            label="Port Email"
                            type="string"
                            fullWidth
                        />

                        <TextField
                            classes={{
                                root: classes.space
                            }}
                            value={phone_number}
                            disabled={true}
                            className={classes.textFieldVPR}
                            label="Phone Number"
                            type="string"
                            fullWidth
                        />

                        <TextField
                            classes={{
                                root: classes.space
                            }}
                            value={no_of_berths}
                            disabled={true}
                            className={classes.textFieldVPR}
                            label="Berths"
                            type="string"
                        />

                        <TextField
                            classes={{
                                root: classes.space
                            }}
                            value={revenue_share}
                            disabled={true}
                            className={classes.textFieldVPR}
                            label="Govt. Share"
                            type="string"
                        />

                        <TextField
                            classes={{
                                root: classes.space
                            }}
                            value={user_type === "port-admin" ? "ADMIN" : "PP"}
                            disabled={true}
                            className={classes.textFieldVPR}
                            label="User Type"
                            type="string"
                        />

                        <TextField
                            classes={{
                                root: classes.space
                            }}
                            value={address}
                            disabled={true}
                            className={classes.textFieldVPR}
                            label="Address"
                            type="string"
                            fullWidth
                        />

                        <TextField
                            classes={{
                                root: classes.space
                            }}
                            value={state}
                            disabled={true}
                            className={classes.textFieldVPR}
                            label="State"
                            type="string"
                            fullWidth
                        />

                        <TextField
                            classes={{
                                root: classes.space
                            }}
                            value={city}
                            disabled={true}
                            className={classes.textFieldVPR}
                            label="City"
                            type="string"
                            fullWidth
                        />

                        <TextField
                            classes={{
                                root: classes.space
                            }}
                            value={country}
                            disabled={true}
                            className={classes.textFieldVPR}
                            label="Country"
                            type="string"
                            fullWidth
                        />

                        <TextField
                            classes={{
                                root: classes.space
                            }}
                            value={pin_code}
                            disabled={true}
                            className={classes.textFieldVPR}
                            label="Pin Code"
                            type="number"
                            fullWidth
                        />
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

ShowOnBoardPort.propTypes = {
    classes: PropTypes.object.isRequired
};

const combinedStyles = combineStyles(styles, toolbarStyles);

export default withStyles(combinedStyles)(ShowOnBoardPort);
