import React, {Component} from "react";
//Material UI
import {withStyles} from "@material-ui/core";
import {styles} from "../commonStyles/ModuleItemListStyles";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import TableFooter from "@material-ui/core/TableFooter";
import Typography from "@material-ui/core/Typography";
//Snack Bars
import "../UtilFunctions/snackbar.css";
import {showDeleteSnackbar} from "../UtilFunctions/showEmptyFieldAndDeleteSnackbar";
//Imported Styles
import toolbarStyles from "../commonStyles/toolbarStyles";
import combineStyles from "../commonStyles/combineStyles";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../UtilFunctions/confirmDelete.css";
//Components
import TableToolbarImportExport from "./TableToolbarImportExport";
import TableHeadImportExport from "./TableHeadImportExport";
import AddNewImportExport from "./AddNewImportExport";
import TablePaginationActionsWrapped from "../UtilFunctions/TablePaginationActionsWrapped";
//Others
import history from "../../../history";
import {Col, Row} from "reactstrap";
import PropTypes from "prop-types";
import {confirmAlert} from "react-confirm-alert";
import axios from "axios";
import orderByLodash from "lodash/orderBy";
import {Helmet} from "react-helmet";
import setAxiosAuthheaders from "../UtilFunctions/setAxiosAuthheaders";

const tableSortByHeadingUtilFunc = require("../UtilFunctions/tableSortByHeading");
const moment = require("moment");

const CustomTableCell = withStyles(theme => ({
    head: {
        background: "#66CCFF",
        color: theme.palette.common.white,
        align: "left"
    },
    body: {
        fontSize: 14,
        align: "left"
    }
}))(TableCell);

class ImportExportList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: "desc",
            orderBy: "year",

            totalCount: 0,
            page: 0,
            rowsPerPage: 10,

            startYear: new Date(),
            endYear: new Date(),
            paginatedImportExportList: [],
            selected: [],

            itemAddedToTheList: true,
            itemDeletedFromTheList: true,

            yearsOnly: false,

            arrowRef: null,

            // totalItemsUnformatted: [],
            // totalItemsFormatted: [],
            // editingOnItemDone: false,
            // queryStringFromChild: "",
            // columnToQuery: "commodity_name",
            // itemsDateRangePaginated: [],
            // totalDateRangeSearchResultParent: [],
            // start_imported_date: new Date(),
            // end_imported_date: new Date(),
            // ifUserSearchedDateRange: false,
            // ifUserClickedForCurrentMonth: false,
            // currentMonthImportsPaginated: [],
            // currentMonthImportsTotal: [],
            // currentDateImportsPaginated: [],
            // currentDateImportsTotal: [],

            // Start Harsha
            // selectedMonth: new Date(),
            // startOfSelectedMonth: moment(new Date()).startOf("month").toISOString(),
            // endOfSelectedMonth: moment(new Date()).endOf("month").toISOString()
            // End Harsha
        };
    }

    componentWillMount() {
        setAxiosAuthheaders();
    }

    componentDidMount() {
        let start = moment(this.state.startYear).startOf("year").toISOString();
        let end = moment(this.state.endYear).endOf("year").toISOString();

        this.getDataForYear(start, end);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let start = this.state.startYear;
        let end = this.state.endYear;

        if (
            this.state.page !== prevState.page ||
            this.state.rowsPerPage !== prevState.rowsPerPage ||
            this.state.itemAddedToTheList !== prevState.itemAddedToTheList ||
            this.state.itemDeletedFromTheList !== prevState.itemDeletedFromTheList
        ) {
            if (this.state.yearsOnly === true) {
                this.getDataForYearsOnly();
            } else {
                this.getDataForYear(start, end);
            }
        }
    }

    getDataForYear = (start, end) => {
        this.setState(
            {
                startYear: start,
                endYear: end,
                yearsOnly: false
            }, () => {
                axios.get("/api/importsExports/count",
                    {
                        params: {
                            start,
                            end
                        }
                    }
                ).then(res => {
                    this.setState({totalCount: res.data.count}, () => {
                        axios.get(`/api/importsExports/getYearData`,
                            {
                                params: {
                                    page: this.state.page,
                                    rowsPerPage: this.state.rowsPerPage,
                                    start,
                                    end
                                }
                            }
                        ).then(res => {
                            this.setState({
                                paginatedImportExportList: res.data.records
                            })
                        }).catch(error => {
                            if (error.response !== undefined) {
                                if (error.response.status === 401) {
                                    history.push("/login");
                                    alert("Please Login, session expired");
                                } else {
                                    alert("Yearly Import & Exports list could not be fetched, please try again");
                                }
                            }
                        });
                    });
                }).catch(error => {
                    if (error.response !== undefined) {
                        if (error.response.status === 401) {
                            history.push("/login");
                            alert("Please Login, session expired");
                        } else {
                            alert("Import & Exports count count not be fetched, please try again");
                        }
                    }
                })
            }
        );
    };

    getDataForYearsOnly = () => {
        this.setState({
            yearsOnly: true
        });

        axios.get("/api/importsExports/countYears")
            .then(res => {
                this.setState({totalCount: res.data.count}, () => {
                    axios.get(`/api/importsExports/getOnlyYearData`,
                        {
                            params: {
                                page: this.state.page,
                                rowsPerPage: this.state.rowsPerPage,
                            }
                        }
                    ).then(res => {
                        this.setState({
                            paginatedImportExportList: res.data.records
                        })
                    }).catch(error => {
                        if (error.response !== undefined) {
                            if (error.response.status === 401) {
                                history.push("/login");
                                alert("Please Login, session expired");
                            } else {
                                alert("Import & Export Years could not be fetched, please try again");
                            }
                        }
                    });
                });
            }).catch(error => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    history.push("/login");
                    alert("Please Login, session expired");
                } else {
                    alert("Import & Export Years count could not be fetched, please try again");
                }
            }
        })
    };

    addItem = () => {
        this.setState({
            itemAddedToTheList: !this.state.itemAddedToTheList
        });
    };

    editItem = (editedData) => {
        const newList = this.state.paginatedImportExportList.map(item => {
            if (item._id === editedData._id) {
                return {
                    ...editedData
                };
            }
            return item;
        });

        this.setState({
            selected: [],
            paginatedImportExportList: newList
        });
    };

    deleteItem = () => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className="custom-ui">
                        <h1>Are you sure?</h1>
                        <p>You want to delete selected Record</p>
                        <button onClick={onClose}>No</button>
                        <button
                            onClick={() => {
                                axios.delete("/api/importsExports/delete", {
                                    data: this.state.selected
                                }).then(() => {
                                    this.setState({
                                        selected: [],
                                        itemDeletedFromTheList: !this.state.itemDeletedFromTheList
                                    });
                                }).then(() => {
                                    showDeleteSnackbar();
                                    onClose();
                                }).catch(error => {
                                    if (error.response.status === 401) {
                                        history.push("/login");
                                        alert("Please Login, session expired");
                                        onClose();
                                    } else {
                                        alert(
                                            "Import Exports file deletion failed, please try again"
                                        );
                                    }
                                });
                            }}
                        >
                            Yes, Delete it!
                        </button>
                    </div>
                );
            }
        });
    };

    handleChangePage = (event, page) => {
        this.setState({
            page: page
        })
    };

    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
    };

    handleClick = (event, id) => {
        const {selected} = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            // If its a new item that has been selected, then concat it to the old array of selections
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        this.setState({selected: newSelected});
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    // Currently not in use
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }
        // In below I am setting the state with destructuring, given both key-value is the same word. So in setState, I just mention the key from the state variable.
        this.setState({order, orderBy});
    };

    render() {
        const {classes, themeType, allCommoditiesForSiblingCommunication} = this.props;
        const {
            order,
            orderBy,
            selected,
            rowsPerPage,
            page,
            totalCount,
            paginatedImportExportList
        } = this.state;

        const itemToEdit = selected.length !== 0 ?
            paginatedImportExportList.filter(item => item._id === selected[0])
            :
            null;

        const downloadSelectedItems = paginatedImportExportList.filter(item => {
            return selected.indexOf(item._id) !== -1;
        });

        return (
            <MuiThemeProvider>
                <div>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>AP MB | Imports & Exports</title>
                        <meta name="description" content="AP Maritime Board Imports & Exports"/>
                        <meta name="theme-color" content="#008f68"/>
                    </Helmet>
                    <Row>
                        <Col xs="12">
                            <Paper className={classes.root}>
                                <TableToolbarImportExport
                                    selected={selected}
                                    getDataForYear={this.getDataForYear}
                                    getDataForYearsOnly={this.getDataForYearsOnly}
                                    itemToEdit={itemToEdit}
                                    editItem={this.editItem}
                                    deleteItem={this.deleteItem}
                                    themeType={themeType}
                                    importExportList={paginatedImportExportList}
                                    downloadSelectedItems={downloadSelectedItems}
                                    allCommoditiesForSiblingCommunication={allCommoditiesForSiblingCommunication}
                                />
                                <div className={classes.tableWrapper}>
                                    <Table className={classes.table}>
                                        <TableHeadImportExport
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={this.handleRequestSort}
                                        />
                                        <TableBody>
                                            {tableSortByHeadingUtilFunc
                                                .stableSort(
                                                    paginatedImportExportList,
                                                    tableSortByHeadingUtilFunc.getSorting(order, orderBy)
                                                )
                                                .map(n => {
                                                    const isSelected = this.isSelected(n._id);
                                                    return (
                                                        <TableRow
                                                            hover
                                                            onClick={event => this.handleClick(event, n._id)}
                                                            role="checkbox"
                                                            aria-checked={isSelected}
                                                            tabIndex={-1}
                                                            key={n._id || n.id}
                                                            selected={isSelected}
                                                            style={{
                                                                height: "35px"
                                                            }}
                                                        >
                                                            <CustomTableCell
                                                                padding="checkbox"
                                                                style={{
                                                                    width: "10px"
                                                                }}
                                                            >
                                                                <Checkbox checked={isSelected}/>
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.year !== null ? moment(n.year).format("YYYY") : moment(n.month).format("YYYY")}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.month !== null ? moment(n.month).format("MMM") : "-"}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.cargo_type !== null ? n.cargo_type : "-"}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.commodity_name !== null ? n.commodity_name : "-"}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.qty_in_mts}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.no_of_vessels_per_month !== null ? n.no_of_vessels_per_month : "-"}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.voyage_type.length !== 0 ? n.voyage_type : "-"}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.flag_type.length !== 0 ? n.flag_type : "-"}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                    count={totalCount}
                                                    rowsPerPage={parseInt(rowsPerPage)}
                                                    page={page}
                                                    SelectProps={{
                                                        native: true
                                                    }}
                                                    onChangePage={this.handleChangePage}
                                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActionsWrapped}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </div>
                                <div id="snackbar">
                                    The Import & Export Record you selected has been successfully deleted
                                </div>
                            </Paper>
                        </Col>
                    </Row>

                    <div className="float-sm-right m-b-sm">
                        <AddNewImportExport
                            addNewItemToParentState={this.addItem}
                            allCommoditiesForSiblingCommunication={
                                this.props.allCommoditiesForSiblingCommunication
                            }
                        />
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

ImportExportList.propTypes = {
    classes: PropTypes.object.isRequired
};

const combinedStyles = combineStyles(styles, toolbarStyles);

export default withStyles(combinedStyles)(ImportExportList);
