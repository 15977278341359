import React, {Component} from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import {styles} from "../commonStyles/ModuleItemListStyles";
import toolbarStyles from "../commonStyles/toolbarStyles";
import combineStyles from "../commonStyles/combineStyles";
import {withStyles} from "@material-ui/core";

// const rows = [
//     {
//         tableHeaderProp: "s_no",
//         disablePadding: true,
//         label: "S.no"
//     },
//     {
//         tableHeaderProp: "name",
//         disablePadding: false,
//         label: "Port Name"
//     },
//     {
//         tableHeaderProp: "key_contact",
//         disablePadding: false,
//         label: "Port Operator"
//     },
//     {
//         tableHeaderProp: "email",
//         disablePadding: false,
//         label: "Port Email"
//     },
//     {
//         tableHeaderProp: "phone_number",
//         disablePadding: false,
//         label: "Contact Number"
//     },
//     {
//         tableHeaderProp: "address",
//         disablePadding: false,
//         label: "Address"
//     }
// ];

const rows = [
    {
        tableHeaderProp: "s_no",
        disablePadding: true,
        label: "S.no"
    },
    {
        tableHeaderProp: "port_name",
        disablePadding: false,
        label: "Port Name"
    },
    {
        tableHeaderProp: "port_email",
        disablePadding: false,
        label: "Port Email"
    },
    {
        tableHeaderProp: "phone_number",
        disablePadding: false,
        label: "Phone Number"
    },
    {
        tableHeaderProp: "no_of_berths",
        disablePadding: false,
        label: "Berths"
    },
    {
        tableHeaderProp: "revenue_share",
        disablePadding: false,
        label: "Govt. Share"
    },
    {
        tableHeaderProp: "user_type",
        disablePadding: false,
        label: "User Type"
    },
    {
        tableHeaderProp: "url",
        disablePadding: false,
        label: "URL"
    },
    // {
    //     tableHeaderProp: "address",
    //     disablePadding: false,
    //     label: "Address"
    // }
];

class EnhancedTableHead extends Component {
    state = {
        arrowRef: null
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node
        });
    };

    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {

        const {onSelectAllClick, order, orderBy, numSelected, listCount} = this.props;

        const {classes} = this.props;

        return (
            <TableHead>
                <TableRow>
                    <Tooltip title={numSelected > 1 ? `Select all ${numSelected}` : `Select the item`}>
                        <TableCell padding="checkbox">
                            <Checkbox
                                indeterminate={numSelected !== listCount && numSelected > 0}
                                checked={numSelected === listCount}
                                onChange={onSelectAllClick}
                            />
                        </TableCell>
                    </Tooltip>
                    {rows.map(
                        row => (
                            <TableCell
                                className={classes.row}
                                key={row.tableHeaderProp}
                                align="center"
                                padding={row.disablePadding ? "none" : "default"}
                                sortDirection={orderBy === row.tableHeaderProp ? order : false}
                            >
                                <Tooltip title={<h6>Sort by {row.label}</h6>}>
                                    {/*<TableSortLabel*/}
                                    {/*    active={orderBy === row.tableHeaderProp}*/}
                                    {/*    direction={order}*/}
                                    {/*    onClick={this.createSortHandler(row.tableHeaderProp)}*/}
                                    {/*>*/}
                                        <Typography variant="h3">{row.label}</Typography>
                                    {/*</TableSortLabel>*/}
                                </Tooltip>
                            </TableCell>
                        ),
                        this
                    )}
                </TableRow>
            </TableHead>
        )
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired
};

const combinedStyles = combineStyles(styles, toolbarStyles);

export default withStyles(combinedStyles)(EnhancedTableHead);
