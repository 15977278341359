import React from "react";
import {Route, Redirect} from "react-router-dom";

const ProtectedRoutes = ({component: Component, ...rest}) => {
    return (
        <Route
            {...rest}
            render={props =>
                JSON.parse(localStorage.getItem("user")) &&
                JSON.parse(localStorage.getItem("user"))["token"] ? (
                    <Component {...props} {...rest} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: props.location}
                        }}
                    />
                )
            }
        />
    );
};

export default ProtectedRoutes;

/*
Some basics on react-router-dom

react-router's component has components attribute.

The main job of a <Router> component is to create a history object to keep track of the location (URL). When the location changes because of a navigation action, the child component (in this case <App>) is re-rendered.

What does the <Route> render?

Routes have three props that can be used to define what should be rendered when the route’s path matches. Only one should be provided to a <Route> element.

component — A React component. When a route with a component prop matches, the route will return a new element whose type is the provided React component (created using React.createElement).

render — A function that returns a React element [5]. It will be called when the path matches. This is similar to component, but is useful for inline rendering and passing extra props to the element.

children — A function that returns a React element. Unlike the prior two props, this will always be rendered, regardless of whether the route’s path matches the current location.

2> Explanation of state: { from : props.location }

This is to implement that after a user tries to move into a protected route and then instead of being taken to an unrelated page, he should have been taken back to the initial page he was trying to access before he was redirected.


So to do that, First, inside of our PrivateRoute component when we redirect the user for not being authenticated, we’ll need to pass along the current route they’re trying to visit so we can come back to it after they authenticate.
We can do this by changing the Redirect’s to prop from a string (like '/') to an object and pass along a state key whose value is the current location of the route the user is trying to access.

Then I need to modify the Login component so that if the user was redirected there from a previous route, once they authenticate, they’re taken back to that original route.

let { from } = this.props.location.state || { from: { pathname: "/" } };

Now when the user authenticates, they’ll be taken back to the original route they were trying to access.

3> { from : props.location } - What exactly is this 'from' key

https://reacttraining.com/react-router/web/api/Redirect  -  The state object can be accessed via this.props.location.state in the redirected-to component. This new 'from' key (which is not a special name, as the above official dox has chose 'referrer' key instead of 'from') would then be accessed via this.props.location.state.referrer in the Login component pointed to by the pathname '/login'

So, here in Login.js I am destructuring it.

4> Overall with the setup of CheckAuth.js, ProtectedRoutes.js and Login.js, now in the app, any routes, that I want only Loggedin users to access, just put its routes wrapped in ProtectedRoutes component in Routes.js

 */
