import React, {Component} from "react";
//Material UI
import {withStyles} from "@material-ui/core";
import {styles} from "../commonStyles/AddNewItemStyles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
//Icons
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
//Snack Bars
import EmptyFieldSnackBar from "./Snackbars/EmptyFieldSnackBar";
import NewItemAddedConfirmSnackbar from "./Snackbars/NewItemAddedConfirmSnackbar";
//Others
import axios from "axios";
import PropTypes from "prop-types";
import history from "../../../history";
import {MenuItem} from "material-ui/Menu";
import setAxiosAuthheaders from "../UtilFunctions/setAxiosAuthheaders";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 550
        }
    }
};

class AddNewCommodity extends Component {
    state = {
        open: false,
        openNewItemAddedConfirmSnackbar: false,
        openEmptyTextFieldSnackbar: false,
        vertical: "top",
        horizontal: "center",
        categoryList: [],
        typeList: [],
        name: "",
        type: "",
        category: null,
        //Error Message handler
        errorName: "",
        errorType: ""
    };

    componentWillMount() {
        setAxiosAuthheaders();
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).userType === "port-operator") {
            axios
                .get("/api/commodity/categories")
                .then(res => {
                    this.setState({
                        categoryList: res.data
                    });
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        history.push("/login");
                        alert("Please Login, session expired");
                    } else {
                        alert("Oops loading commodity categories failed, please try again");
                    }
                });

            axios
                .get("/api/commodity/types")
                .then(res => {
                    this.setState({
                        typeList: res.data
                    });
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        history.push("/login");
                        alert("Please Login, session expired");
                    } else {
                        alert("Oops loading commodity types failed, please try again");
                    }
                });
        }
    }

    handleToggle = () => {
        this.setState({
            open: !this.state.open
        });
    };

    handleFabOpen = () => {
        this.setState({open: true});
    };

    closeNewItemConfirmSnackbar = () => {
        this.setState({openNewItemAddedConfirmSnackbar: false});
    };

    closeEmptyFieldSnackbar = () => {
        this.setState({openEmptyTextFieldSnackbar: false});
    };

    onChange = e => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState(state);
    };

    handleFormSubmit = () => {
        const {addNewItemToParentState} = this.props;
        const {name, type, category} = this.state;

        name !== "" ? this.setState({errorName: ""}) : this.setState({errorName: "Enter Commodity Name"});
        type !== "" ? this.setState({errorType: ""}) : this.setState({errorType: "Select Commodity Type"});

        if (name !== "" && type !== "") {
            axios
                .post("/api/commodity/", {
                    name,
                    type,
                    category
                })
                .then(() => {
                    addNewItemToParentState({
                        name,
                        type,
                        category
                    });
                    this.setState(
                        {
                            open: false,
                            openNewItemAddedConfirmSnackbar: true,
                            vertical: "top",
                            horizontal: "center",
                            name: "",
                            type: "",
                            category: "",
                            //Error Message handler
                            errorName: "",
                            errorType: ""
                        },
                        () => {
                            history.push("/dashboard/commodities");
                        }
                    );
                })
                .catch(error => {
                    if (
                        error.response.data.name === "MongoError" &&
                        error.response.data.code === 11000
                    ) {
                        alert(
                            "Duplicate Commodity Name! please select another name for the Commodity"
                        );
                    } else if (error.response.status === 401) {
                        history.push("/login");
                        alert("Please Login, session expired");
                    } else {
                        alert("Oops something wrong happened, please try again");
                    }
                });
        } else {
            this.setState({openEmptyTextFieldSnackbar: true});
        }
    };

    handleCancel = () => {
        this.setState({
            open: !this.state.open,
            name: "",
            type: "",
            category: "",
            //Error Message handler
            errorName: "",
            errorType: ""
        });
    };

    handleEnterEscapeKeyPress = e => {
        if (e.key === "Enter") {
            this.handleFormSubmit();
        } else if (e.key === "Escape") {
            this.handleCancel();
        }
    };

    render() {
        const {classes} = this.props;
        const {
            categoryList,
            typeList,
            name,
            type,
            category,
            //Error Message handler
            errorName,
            errorType
        } = this.state;

        return (
            <div>
                <Tooltip title="Add Commodity">
                    <Fab
                        onClick={this.handleFabOpen}
                        aria-pressed="true"
                        color="secondary"
                        size="large"
                        aria-label="Add"
                        fontSize="large"
                        className={classes.fabButton}
                    >
                        <AddIcon/>
                    </Fab>
                </Tooltip>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleToggle}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth={"sm"}
                    variant="contained"
                    PaperProps={{
                        classes: {
                            root: classes.paper
                        }
                    }}
                    onKeyDown={this.handleEnterEscapeKeyPress}
                    disableBackdropClick
                >
                    <DialogTitle
                        id="form-dialog-title"
                        disableTypography
                        className={classes.dialogTitleAdd}
                    >
                        <div className={classes.displayFlexDialogTitle}>
                            <Typography variant="h5" className={classes.dialogTitleHeading}>New Commodity</Typography>
                            <IconButton
                                onClick={this.handleToggle}
                                className={classes.button}
                                style={{float: "right"}}
                            >
                                <CancelIcon/>
                            </IconButton>
                        </div>
                    </DialogTitle>

                    <DialogContent required>

                        <TextField
                            required
                            classes={{
                                root: classes.space
                            }}
                            value={name}
                            onChange={e => {
                                this.setState({
                                    name: e.target.value
                                })
                            }}
                            label="Name"
                            type="string"
                            error={errorName.length !== 0}
                            helperText={errorName}
                            fullWidth
                        />

                        <TextField
                            select
                            required
                            label="Select Commodity Type"
                            className={classes.textFieldDropDown}
                            value={type}
                            onChange={e => {
                                this.setState({
                                    type: e.target.value
                                })
                            }}
                            menuprops={MenuProps}
                            error={errorType.length !== 0}
                            helperText={errorType}
                            margin="normal"
                            fullWidth
                        >
                            {typeList.map(item => (
                                <MenuItem key={item._id} value={item._id}>
                                    {item.typeName}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            select
                            label="Select Commodity Category"
                            className={classes.textFieldDropDown}
                            value={category}
                            onChange={e => {
                                this.setState({
                                    category: e.target.value
                                })
                            }}
                            menuprops={MenuProps}
                            error={errorType.length !== 0}
                            helperText={errorType}
                            margin="normal"
                            fullWidth
                        >
                            {categoryList.map(item => (
                                <MenuItem key={item._id} value={item._id}>
                                    {item.categoryName}
                                </MenuItem>
                            ))}
                        </TextField>

                    </DialogContent>

                    <DialogActions
                        className={classes.dialogActions}
                    >
                        <div className={classes.displayFlexDialogActions}>
                            <Button
                                onClick={this.handleCancel}
                                classes={{
                                    root: classes.spaceDialogAction
                                }}
                                variant="contained"
                                size="large"
                                style={{
                                    backgroundColor: "#ee0053"
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={this.handleFormSubmit}
                                classes={{
                                    root: classes.spaceDialogAction
                                }}
                                color="primary"
                                variant="contained"
                                size="large"
                                type="submit"
                            >
                                Save
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
                <NewItemAddedConfirmSnackbar
                    openNewItemAddedConfirmSnackbar={
                        this.state.openNewItemAddedConfirmSnackbar
                    }
                    closeNewItemConfirmSnackbar={this.closeNewItemConfirmSnackbar}
                />

                <EmptyFieldSnackBar
                    openEmptyTextFieldSnackbar={this.state.openEmptyTextFieldSnackbar}
                    closeEmptyFieldSnackbar={this.closeEmptyFieldSnackbar}
                />
            </div>
        );
    }
}

AddNewCommodity.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AddNewCommodity);
