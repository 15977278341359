import React, {Component} from "react";
import {Switch, Route} from "react-router-dom";
import DashBoard from "../Components/DashBoardViewComponents/Dashboard";
import AnalyticsPort from "../Components/DashBoardViewComponents/Analytics/AnalyticsPort";
import ReportsList from "../Components/DashBoardViewComponents/Reports/ReportsList";
import KpiView from "../Components/DashBoardViewComponents/Kpi/KpiViews";
import ImportExportList from "../Components/DashBoardViewComponents/ImportsAndExports/ImportExportList";
import RevenueList from "../Components/DashBoardViewComponents/Revenue/RevenueList";
import OthersList from "../Components/DashBoardViewComponents/Others/OthersList";
import OnBoardPort from "../Components/DashBoardViewComponents/OnBoardPort/OnBoardPortList";
import CommodityList from "../Components/DashBoardViewComponents/Commodity/CommodityList";
import Login from "../Components/DashBoardViewComponents/Login/Login";
import PasswordReset from "../Components/DashBoardViewComponents/Login/PasswordReset";
import Profile from "../Components/DashBoardViewComponents/Profile/Profile";
import NotFound from "../Components/DashBoardViewComponents/NotFound";
import ProtectedRoutes from "../Components/DashBoardViewComponents/Login/ProtectedRoutes";

class DashboardRoutes extends Component {
    render() {
        const {
            allCommoditiesForSiblingCommunication,
            setCommodityForSiblingCommunication,
            themeType
        } = this.props;

        return (
            <div>
                <Switch>
                    <Route exact path="/" component={DashBoard}/>

                    <ProtectedRoutes
                        exact
                        path="/dashboard/analytics"
                        component={AnalyticsPort}
                        allCommoditiesForSiblingCommunication={
                            allCommoditiesForSiblingCommunication
                        }
                    />

                    {JSON.parse(localStorage.getItem("user")) &&
                    JSON.parse(localStorage.getItem("user")).userType === "port-admin" ? (
                        <ProtectedRoutes
                            exact
                            path="/dashboard/reports"
                            component={ReportsList}
                        />
                    ) : null}

                    {JSON.parse(localStorage.getItem("user")) &&
                    JSON.parse(localStorage.getItem("user")).userType === "port-admin" ? (
                        <ProtectedRoutes
                            exact
                            path="/dashboard/kpi"
                            component={KpiView}
                        />
                    ) : null}

                    {JSON.parse(localStorage.getItem("user")) &&
                    JSON.parse(localStorage.getItem("user")).userType ===
                    "port-operator" ? (
                        <ProtectedRoutes
                            exact
                            path="/dashboard/importsExports"
                            component={ImportExportList}
                            allCommoditiesForSiblingCommunication={
                                allCommoditiesForSiblingCommunication
                            }
                            themeType={themeType}
                        />
                    ) : null}

                    {JSON.parse(localStorage.getItem("user")) &&
                    JSON.parse(localStorage.getItem("user")).userType ===
                    "port-operator" ? (
                        <ProtectedRoutes
                            exact
                            path="/dashboard/revenue"
                            component={RevenueList}
                            // allCommoditiesForSiblingCommunication={
                            //     allCommoditiesForSiblingCommunication
                            // }
                            themeType={themeType}
                        />
                    ) : null}

                    {JSON.parse(localStorage.getItem("user")) &&
                    JSON.parse(localStorage.getItem("user")).userType ===
                    "port-operator" ? (
                        <ProtectedRoutes
                            exact
                            path="/dashboard/others"
                            component={OthersList}
                            themeType={themeType}
                        />
                    ) : null}

                    {JSON.parse(localStorage.getItem("user")) &&
                    JSON.parse(localStorage.getItem("user")).userType ===
                    "port-operator" ? (
                        <ProtectedRoutes
                            exact
                            path="/dashboard/commodities"
                            component={CommodityList}
                            setCommodityForSiblingCommunication={
                                setCommodityForSiblingCommunication
                            }
                            themeType={themeType}
                        />
                    ) : null}

                    {JSON.parse(localStorage.getItem("user")) &&
                    JSON.parse(localStorage.getItem("user")).userType === "port-admin" ? (
                        <ProtectedRoutes
                            exact
                            path="/dashboard/onBoardPort"
                            component={OnBoardPort}
                            themeType={themeType}
                        />
                    ) : null}

                    <ProtectedRoutes
                        exact
                        path="/dashboard/profile"
                        component={Profile}
                    />

                    <ProtectedRoutes exact path="/dashboard/admin"/>

                    <Route exact path={"/login"} component={Login}/>

                    <Route path={"/password-reset"} component={PasswordReset}/>

                    <Route component={NotFound}/>
                </Switch>
            </div>
        );
    }
}

export default DashboardRoutes;
