import React, {Component} from "react";
//Material UI
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import TableFooter from "@material-ui/core/TableFooter";
import Typography from "@material-ui/core/Typography";
//Snack Bars
import "../UtilFunctions/snackbar.css";
import {showDeleteSnackbar} from "../UtilFunctions/showEmptyFieldAndDeleteSnackbar";
//Imported Styles
import {withStyles} from "@material-ui/core";
import {styles} from "../commonStyles/ModuleItemListStyles";
import toolbarStyles from "../commonStyles/toolbarStyles";
import combineStyles from "../commonStyles/combineStyles";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../UtilFunctions/confirmDelete.css";
//Components
import TableToolbarOthers from "./TableToolbarOthers";
import TableHeadOthers from "./TableHeadOthers";
import AddNewOthers from "./AddNewOthers";
import TablePaginationActionsWrapped from "../UtilFunctions/TablePaginationActionsWrapped";
//Others
import history from "../../../history";
import {Col, Row} from "reactstrap";
import {confirmAlert} from "react-confirm-alert";
import axios from "axios";
import {Helmet} from "react-helmet";
import setAxiosAuthheaders from "../UtilFunctions/setAxiosAuthheaders";

const tableSortByHeadingUtilFunc = require("../UtilFunctions/tableSortByHeading");
const moment = require("moment");

const CustomTableCell = withStyles(theme => ({
    head: {
        background: "#66CCFF",
        color: theme.palette.common.white,
        align: "left"
    },
    body: {
        fontSize: 14,
        align: "left"
    }
}))(TableCell);

class OthersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: "desc",
            orderBy: "others_year",

            totalCount: 0,
            page: 0,
            rowsPerPage: 10,

            startYear: new Date(),
            endYear: new Date(),
            paginatedOthersList: [],
            selected: [],

            itemAddedToTheList: true,
            itemDeletedFromTheList: true,

            arrowRef: null,
        };
    }

    componentWillMount() {
        setAxiosAuthheaders();
    }

    componentDidMount() {
        let start = moment(this.state.startYear).startOf("year").toISOString();
        let end = moment(this.state.endYear).endOf("year").toISOString();

        this.getDataForYear(start, end);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let start = this.state.startYear;
        let end = this.state.endYear;

        if (
            this.state.page !== prevState.page ||
            this.state.rowsPerPage !== prevState.rowsPerPage ||
            this.state.itemAddedToTheList !== prevState.itemAddedToTheList ||
            this.state.itemDeletedFromTheList !== prevState.itemDeletedFromTheList
        ) {
            this.getDataForYear(start, end);
        }
    }

    getDataForYear = (start, end) => {
        this.setState(
            {
                startYear: start,
                endYear: end,
            }, () => {
                axios.get("/api/others/count",
                    {
                        params: {
                            start,
                            end
                        }
                    }
                ).then(res => {
                    this.setState({totalCount: res.data.count}, () => {
                        axios.get(`/api/others/getYearData`,
                            {
                                params: {
                                    page: this.state.page,
                                    rowsPerPage: this.state.rowsPerPage,
                                    start,
                                    end
                                }
                            }
                        ).then(res => {
                            this.setState({
                                paginatedOthersList: res.data.records
                            })
                        }).catch(error => {
                            if (error.response !== undefined) {
                                if (error.response.status === 401) {
                                    history.push("/login");
                                    alert("Please Login, session expired");
                                } else {
                                    alert("Yearly others list could not be fetched, please try again");
                                }
                            }
                        });
                    });
                }).catch(error => {
                    if (error.response !== undefined) {
                        if (error.response.status === 401) {
                            history.push("/login");
                            alert("Please Login, session expired");
                        } else {
                            alert("Others count count not be fetched, please try again");
                        }
                    }
                })
            }
        );
    };

    addItem = () => {
        this.setState({
            itemAddedToTheList: !this.state.itemAddedToTheList
        });
    };

    editItem = (editedData) => {
        const newList = this.state.paginatedOthersList.map(item => {
            if (item._id === editedData._id) {
                return {
                    ...editedData
                };
            }
            return item;
        });

        this.setState({
            selected: [],
            paginatedOthersList: newList
        });
    };

    deleteItem = () => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className="custom-ui">
                        <h1>Are you sure?</h1>
                        <p>You want to delete this Others Record</p>
                        <button onClick={onClose}>No</button>
                        <button
                            onClick={() => {
                                axios.delete("/api/others/delete", {
                                    data: this.state.selected
                                }).then(() => {
                                    this.setState({
                                        selected: [],
                                        itemDeletedFromTheList: !this.state.itemDeletedFromTheList
                                    });
                                }).then(() => {
                                    showDeleteSnackbar();
                                    onClose();
                                }).catch(error => {
                                    if (error.response !== undefined) {
                                        if (error.response.status === 401) {
                                            history.push("/login");
                                            alert("Please Login, session expired");
                                            onClose();
                                        } else {
                                            alert("Others file deletion failed, please try again");
                                        }
                                    }
                                });
                            }}
                        >
                            Yes, Delete it!
                        </button>
                    </div>
                );
            }
        });
    };

    handleChangePage = (event, page) => {
        this.setState({
            page: page
        })
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: 0,
            rowsPerPage: event.target.value
        });
    };

    handleClick = (event, id) => {
        const {selected} = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            // If its a new item that has been selected, then concat it to the old array of selections
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        this.setState({selected: newSelected});
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    // Currently not in use
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }
        // In below I am setting the state with destructuring, given both key-value is the same word. So in setState, I just mention the key from the state variable.
        this.setState({order, orderBy});
    };

    render() {
        const {classes, themeType} = this.props;

        const {
            order,
            orderBy,
            selected,
            rowsPerPage,
            page,
            totalCount,
            paginatedOthersList
        } = this.state;

        const itemToEdit = selected.length !== 0 ?
            paginatedOthersList.filter(item => item._id === selected[0])
            :
            null;

        const downloadSelectedItems = paginatedOthersList.filter(item => {
            return selected.indexOf(item._id) !== -1;
        });

        return (
            <MuiThemeProvider>
                <div>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>Ap Ports | Others </title>
                        <meta name="description" content="Ap Maritime Board Others"/>
                        <meta name="theme-color" content="#008f68"/>
                    </Helmet>
                    <Row>
                        <Col xs="12">
                            <Paper className={classes.othersTablePaper}>
                                <TableToolbarOthers
                                    selected={selected}
                                    getDataForYear={this.getDataForYear}
                                    itemToEdit={itemToEdit}
                                    editItem={this.editItem}
                                    deleteItem={this.deleteItem}
                                    themeType={themeType}
                                    othersList={paginatedOthersList}
                                    downloadSelectedItems={downloadSelectedItems}
                                />
                                <div className={classes.tableWrapper}>
                                    <Table className={classes.table}>
                                        <TableHeadOthers
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={this.handleRequestSort}
                                        />
                                        <TableBody>
                                            {tableSortByHeadingUtilFunc
                                                .stableSort(
                                                    paginatedOthersList,
                                                    tableSortByHeadingUtilFunc.getSorting(order, orderBy)
                                                )
                                                .map(n => {
                                                    const isSelected = this.isSelected(n._id);
                                                    return (
                                                        <TableRow
                                                            hover
                                                            onClick={event => this.handleClick(event, n._id)}
                                                            role="checkbox"
                                                            aria-checked={isSelected}
                                                            tabIndex={-1}
                                                            key={n._id || n.id}
                                                            selected={isSelected}
                                                            style={{
                                                                height: "35px"
                                                            }}
                                                        >
                                                            <CustomTableCell
                                                                padding="checkbox"
                                                                style={{
                                                                    width: "10px"
                                                                }}
                                                            >
                                                                <Checkbox checked={isSelected}/>
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.others_year !== null ? moment(n.others_year).format("YYYY") : moment(n.others_month).format("YYYY")}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.others_month !== null ? moment(n.others_month).format("MMM") : "-"}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.coastal_in}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.coastal_out}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.others}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.container_traffic_handled}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.percentage_utilization_of_traffic_handling_capacity_of_the_port}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.average_vehicle_turn_around_time}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.bulk_and_handymax}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.bulk_and_panamax}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.bulk_and_cape_size}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.containers}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.tankers}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.average_pre_berth_delays}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.total_cargo_evacuation_from_port}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.by_rail}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.by_road}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.by_conveyor}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.by_others}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                className={classes.customTableCell}
                                                                align="center"
                                                                style={{
                                                                    width: "auto"
                                                                }}
                                                            >
                                                                {
                                                                    <Typography variant="h4">
                                                                        {n.specific_information}
                                                                    </Typography>
                                                                }
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                    colSpan={8}
                                                    count={totalCount}
                                                    rowsPerPage={parseInt(rowsPerPage)}
                                                    page={page}
                                                    SelectProps={{
                                                        native: true
                                                    }}
                                                    onChangePage={this.handleChangePage}
                                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActionsWrapped}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </div>
                                <div id="snackbar">
                                    The Others Record you selected has been successfully deleted
                                </div>
                            </Paper>
                        </Col>
                    </Row>
                    <Row>
                        <br/>
                    </Row>

                    <div className="float-sm-right m-b-sm">
                        <AddNewOthers
                            addNewItemToParentState={this.addItem}
                        />
                    </div>
                </div>
            </MuiThemeProvider>
        )
    }
}

const combinedStyles = combineStyles(styles, toolbarStyles);

export default withStyles(combinedStyles)(OthersList);
