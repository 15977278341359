import React, {Component} from "react";
import history from "../../../history";
import {Row, Col} from "reactstrap";
import PropTypes from "prop-types";
import {confirmAlert} from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../UtilFunctions/confirmDelete.css";
import "../UtilFunctions/snackbar.css";
import {showDeleteSnackbar} from "../UtilFunctions/showEmptyFieldAndDeleteSnackbar";
import {withStyles} from "@material-ui/core";
import AddNewCommodity from "./AddNewCommodity";
import axios from "axios";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {styles} from "../commonStyles/ModuleItemListStyles";
import toolbarStyles from "../commonStyles/toolbarStyles";
import combineStyles from "../commonStyles/combineStyles";
import Typography from "@material-ui/core/Typography";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import TableToolbarCommodity from "./TableToolbarCommodity";
import TableHeadCommodity from "./TableHeadCommodity";
import orderByLodash from "lodash/orderBy";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import TableFooter from "@material-ui/core/TableFooter";
import TablePaginationActionsWrapped from "../UtilFunctions/TablePaginationActionsWrapped";
import {Helmet} from "react-helmet";
import setAxiosAuthheaders from "../UtilFunctions/setAxiosAuthheaders";

const CustomTableCell = withStyles(theme => ({
    head: {
        background: "#66CCFF",
        color: theme.palette.common.white,
        align: "left"
    },
    body: {
        fontSize: 14,
        align: "left"
    }
}))(TableCell);

const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

/* stableSort() higher order function to sort by a table heading
1> the argument 'comparisonCB' is the getSorting() defined below.
2> The sort() method takes a single compareFunction as a callback and compares each pair of successive elements.
 */
function stableSort(array, comparisonCB) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparisonCB(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

/* getSorting() to be passed as a callback to the above sableSort(). The argument 'orderBy' will initially start (as a state) its value with 'location' (which is the first table header label) and then will take on whichever table header label the user clicks on.
A> First the 'orderBy' value will be set by handleRequestSort() with its argument 'property'
B> Then this function will be passed down as a prop 'onRequestSort' to EnhancedTableHead child component.
C> In EnhancedTableHead, it will be called within createSortHandler() function and will be invoked on onClick and passed row.tableHeaderProp (which is the Table-header field value)
 */
function getSorting(order, orderBy) {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

class CommodityList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allCommodities: [],
            openEditModal: false,
            editingOnItemDone: false,
            order: "asc",
            orderBy: "name",
            selected: [],
            page: 0,
            rowsPerPage: 10,
            queryStringFromChild: "",
            columnToQuery: "name",
            currentPageRenderedList: []
        };
    }

    componentWillMount() {
        setAxiosAuthheaders();
    }

    componentDidMount() {
        if (
            JSON.parse(localStorage.getItem("user")) &&
            JSON.parse(localStorage.getItem("user"))["token"] !== null
        ) {
            axios
                .get("/api/commodity")
                .then(res => {
                    this.setState({
                        allCommodities: res.data
                    });
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            history.push("/login");
                            alert("Please Login, session expired");
                        } else {
                            alert("Oops fetching commodities failed, please try again");
                        }
                    }
                });
        } else {
            history.push("/login");
        }
    }

    addItem = item => {
        this.setState(
            {
                allCommodities: [item, ...this.state.allCommodities]
            },
            () => {
                this.props.setCommodityForSiblingCommunication(
                    this.state.allCommodities
                );
            }
        );
    };

    editItem = () => {
        // * having to put this extra if condition and the database call here to resolve an issue that for the last item left in the table, after edit submission the location and work-description was not getting rendered immediately. Edited data will get rendered only after page refresh
        if (this.state.allCommodities.length === 1) {
            axios
                .get("/api/commodity")
                .then(res => {
                    this.setState({
                        allCommodities: res.data
                    });
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        history.push("/login");
                        alert("Please Login, session expired");
                    } else {
                        alert("Oops something wrong happened, please try again");
                    }
                });
        } else {
            this.setState(
                {
                    allCommodities: [this.state.allCommodities]
                },
                () => {
                    this.props.setCommodityForSiblingCommunication(
                        this.state.allCommodities
                    );
                }
            );
        }
    };

    returnDocumentToEdit = id => {
        if (this.state.selected.length !== 0) {
            return this.state.allCommodities.filter(item => item._id === id);
        }
    };

    confirmDeleteCustom = idArr => {
        let payload = {
            commodities_id_list_arr: idArr
        };
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className="custom-ui">
                        <h1>Are you sure?</h1>
                        <p>You want to delete this Commodity Item</p>
                        <button onClick={onClose}>No</button>
                        <button
                            onClick={() => {
                                axios
                                    .delete("/api/commodity/delete", {
                                        data: payload
                                    })
                                    .then(() => {
                                        this.setState(
                                            {
                                                allCommodities: [this.state.allCommodities],
                                                selected: []
                                            },
                                            () => {
                                                this.props.setCommodityForSiblingCommunication(
                                                    this.state.allCommodities
                                                );
                                            }
                                        );
                                    })
                                    .then(() => {
                                        showDeleteSnackbar();
                                        onClose();
                                    })
                                    .catch(error => {
                                        if (error.response.status === 401) {
                                            history.push("/login");
                                            alert("Please Login, session expired");
                                            onClose();
                                        } else {
                                            alert("Oops something wrong happened, please try again");
                                        }
                                    });
                            }}
                        >
                            Yes, Delete it!
                        </button>
                    </div>
                );
            }
        });
    };

    // Function to handle the the request from user to sort by a particular heading.
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }
        // In below I am setting the state with destructuring, given both key-value is the same word. So in setState, I just mention the key from the state variable.
        this.setState({order, orderBy});
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    // Function to handle when the checkbox for the select all is clicked to select all the items in that page
    handleSelectAllClick = event => {
        const {
            page,
            rowsPerPage,
            queryStringFromChild,
            allCommodities,
            order,
            orderBy
        } = this.state;

        if (event.target.checked) {
            if (queryStringFromChild === "") {
                const listOfItemsInCurrentPage = stableSort(
                    allCommodities,
                    getSorting(order, orderBy)
                ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
                this.setState(() => ({
                    selected: listOfItemsInCurrentPage.map(n => n._id)
                }));
                return;
            } else if (this.state.queryStringFromChild !== "") {
                const lowerCaseQuery = this.state.queryStringFromChild.toLowerCase();

                const totalTextQueryResult = this.state.allCommodities.filter(item => {
                    return (
                        item[this.state.columnToQuery] &&
                        item[this.state.columnToQuery]
                            .toLowerCase()
                            .includes(lowerCaseQuery)
                    );
                });
                const commodityListToRender = totalTextQueryResult.slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
                );
                this.setState(() => ({
                    selected: commodityListToRender.map(n => n._id)
                }));
                return;
            }
        }
        this.setState({selected: []});
    };

    unSelectItems = () => {
        this.setState({
            selected: []
        });
    };

    handleClick = (event, id) => {
        const {selected} = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        this.setState({selected: newSelected});
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.state.rowsPerPage !== prevState.rowsPerPage ||
            this.state.page !== prevState.page ||
            this.state.allCommodities.length !== prevState.allCommodities.length ||
            this.state.selected !== prevState.selected
        ) {
            return axios
                .get("/api/commodity")
                .then(res => {
                    this.setState({
                        allCommodities: res.data
                    });
                })
                .catch(error => {
                    if (error.response !== undefined) {
                        if (error.response.status === 401) {
                            history.push("/login");
                            alert("Please Login, session expired");
                        } else {
                            alert("Oops something wrong happened, please try again");
                        }
                    }
                });
        }
    }

    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
    };

    handleQueryString = queryTypedInChild => {
        this.setState({
            queryStringFromChild: queryTypedInChild
        });
    };

    handleColumnToQuery = columnToQueryInChild => {
        this.setState({
            columnToQuery: columnToQueryInChild
        });
    };

    clearAllQueryString = () => {
        this.setState({
            queryStringFromChild: "",
            columnToQuery: "name"
        });
    };

    handleChangePage = (event, page) => {
        this.setState({page});
    };

    render() {
        const {classes, themeType} = this.props;
        const {
            order,
            orderBy,
            selected,
            rowsPerPage,
            page,
            allCommodities,
            queryStringFromChild
        } = this.state;

        // lowercase the queryStringFromChild string that the user types
        const lowerCaseQuery =
            queryStringFromChild && queryStringFromChild.toLowerCase();

        const totalTextQueryResult = allCommodities.filter(
            item =>
                item[this.state.columnToQuery] &&
                item[this.state.columnToQuery].toLowerCase().includes(lowerCaseQuery)
        );

        const commodityListToRender = orderByLodash(
            queryStringFromChild
                ? totalTextQueryResult
                : stableSort(allCommodities, getSorting(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
                )
        );

        const commodityToEdit = this.returnDocumentToEdit(this.state.selected[0]);

        // filter the whole database returning only the selected items
        const downloadSelectedItems = allCommodities.filter(item => {
            return selected.indexOf(item._id) !== -1;
        });

        const emptyRows =
            rowsPerPage -
            Math.min(rowsPerPage, commodityListToRender.length - page * rowsPerPage);

        // in below the whole table header is a different component 'EnhancedTableHead'
        return (
            <MuiThemeProvider>
                <div>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>Ap Ports Commodity List</title>
                        <meta name="description" content="Ap Ports List of Commodities!"/>
                    </Helmet>
                    <Row>
                        <Col xs="12">
                            <Paper className={classes.root}>
                                <TableToolbarCommodity
                                    allCommodities={this.state.allCommodities}
                                    numSelected={selected.length}
                                    confirmDeleteCustom={this.confirmDeleteCustom}
                                    checkedItems={selected}
                                    commodityToEdit={commodityToEdit}
                                    editItem={this.editItem}
                                    handleQueryString={this.handleQueryString}
                                    handleColumnToQuery={this.handleColumnToQuery}
                                    clearAllQueryString={this.clearAllQueryString}
                                    unSelectItems={this.unSelectItems}
                                    downloadSelectedItems={downloadSelectedItems}
                                    page={this.state.page}
                                    rowsPerPage={this.state.rowsPerPage}
                                    themeType={themeType}
                                />
                                <div className={classes.tableWrapper}>
                                    <Table className={classes.table}>
                                        <TableHeadCommodity
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={this.handleSelectAllClick}
                                            onRequestSort={this.handleRequestSort}
                                            page={page}
                                            count={
                                                queryStringFromChild
                                                    ? totalTextQueryResult.length
                                                    : allCommodities.length
                                            }
                                            rowsPerPage={parseInt(rowsPerPage)}
                                            noOfItemsInCurrentPage={commodityListToRender.length}
                                        />
                                        <TableBody>
                                            {commodityListToRender.map((n, i) => {
                                                const isSelected = this.isSelected(n._id);
                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={event => this.handleClick(event, n._id)}
                                                        role="checkbox"
                                                        aria-checked={isSelected}
                                                        tabIndex={-1}
                                                        key={n._id || n.id || i}
                                                        selected={isSelected}
                                                        style={{
                                                            height: "35px"
                                                        }}
                                                    >
                                                        <CustomTableCell
                                                            padding="checkbox"
                                                            style={{
                                                                width: "10px"
                                                            }}
                                                        >
                                                            <Checkbox checked={isSelected}/>
                                                        </CustomTableCell>
                                                        <CustomTableCell
                                                            className={classes.customTableCell}
                                                            align="center"
                                                            style={{
                                                                width: "auto"
                                                            }}
                                                        >
                                                            {<Typography variant="h4">{n.name}</Typography>}
                                                        </CustomTableCell>
                                                        <CustomTableCell
                                                            className={classes.customTableCell}
                                                            align="center"
                                                            style={{
                                                                widthRight: "auto"
                                                            }}
                                                        >
                                                            {<Typography variant="h4">{n.type !== undefined ? n.type !== null ? n.type.typeName : "" : ""}</Typography>}
                                                        </CustomTableCell>
                                                        <CustomTableCell
                                                            className={classes.customTableCell}
                                                            align="center"
                                                            style={{
                                                                widthRight: "auto"
                                                            }}
                                                        >
                                                            {<Typography variant="h4">{n.category !== undefined ? n.category !== null ? n.category.categoryName : "" : ""}</Typography>}
                                                        </CustomTableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{height: 0}}>
                                                    <CustomTableCell colSpan={6}/>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                    colSpan={6}
                                                    count={
                                                        queryStringFromChild
                                                            ? totalTextQueryResult.length
                                                            : allCommodities.length
                                                    }
                                                    rowsPerPage={parseInt(rowsPerPage)}
                                                    page={page}
                                                    SelectProps={{
                                                        native: true
                                                    }}
                                                    onChangePage={this.handleChangePage}
                                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActionsWrapped}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </div>
                                <div id="snackbar">
                                    The Commodity you selected has been successfully deleted
                                </div>
                            </Paper>
                        </Col>
                    </Row>
                    <Row>
                        <br/>
                    </Row>

                    <div className="float-sm-right m-b-sm">
                        <AddNewCommodity addNewItemToParentState={this.addItem}/>
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

CommodityList.propTypes = {
    classes: PropTypes.object.isRequired
};

const combinedStyles = combineStyles(styles, toolbarStyles);

export default withStyles(combinedStyles)(CommodityList);
