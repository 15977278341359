import React, { Component } from "react";
import history from "../../../history";
import PropTypes from "prop-types";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { CardTitle } from "react-toolbox/lib/card";
import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import { ValidatorForm } from "react-material-ui-form-validator";
import PasswordResetConfirmSnackbar from "./Snackbars/PasswordResetConfirmSnackbar";
import WrongDateRangeSnackBar from "./Snackbars/WrongDateRangeSnackBar";
import Image from "../../../Images/DashBoard/Login/Login_ship.jpg";
import NotFound from "../NotFound";
import PasswordField from "material-ui-password-field";
const queryString = require("query-string");

const styles = {
  root: {
    color: green[600],
    "&$checked": {
      color: green[500]
    }
  },
  checked: {},
  card: {
    maxWidth: "500px",
    maxHeight: "1200px",
    height: "600px"
  },

  media: {
    height: 0,
    justify: "center",
    backgroundPosition: "center",
    alignItems: "center",
    textAlign: "center",
    marginLeft: "113px",
    marginTop: "10px",
    paddingLeft: "56.25%",
    paddingTop: "56.25%", // 16:9
    width: "30px"
  }
};

class PasswordReset extends Component {
  constructor() {
    super();
    this.state = {
      newResetPassword: "",
      confirmNewPassword: "",
      message: "",
      shouldForgotPasswordComponentOpen: false,
      redirectToReferrer: false,
      emailFromURLParams: "",
      uidFromURLParams: "",
      openNewItemAddedConfirmSnackbar: false,
      resetPasswordConfirmation:
        "Password has been changed successfully, you will be redirected to the Login page"
    };
  }

  closeWrongDateRangeSnackBar = () => {
    this.setState({ openWrongDateRangeSnackBar: false });
  };

  closeNewItemConfirmSnackbar = () => {
    this.setState({ openNewItemAddedConfirmSnackbar: false });
  };

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    this.setState({
      emailFromURLParams: parsed.email,
      uidFromURLParams: parsed.uid
    });
  }

  // Function to reset the password - It will fire two sequential axios request and based on first request's successful response value, will do the next axios request.
  passwordResetOnSubmit = async e => {
    e.preventDefault();

    const {
      emailFromURLParams,
      uidFromURLParams,
      newResetPassword,
      confirmNewPassword
    } = this.state;

    const firstRequest = await axios
      .post("/api/forgot-password/verify-uid-before-password-reset", {
        emailFromURLParams,
        uidFromURLParams
      })
      .catch(error => {
        if (error.response.status === 400) {
          this.setState({ openWrongDateRangeSnackBar: true });
        }
      });
    const userId = await (firstRequest && firstRequest.data);

    if (userId && newResetPassword === confirmNewPassword) {
      axios
        .put("/api/user/password-reset", {
          userId,
          newResetPassword
        })
        .then(() => {
          this.setState(
            {
              openNewItemAddedConfirmSnackbar: true,
              newResetPassword: "",
              confirmNewPassword: ""
            },

            () => {
              this.intervalId = setTimeout(() => {
                history.push("/login");
              }, 3000);
            }
          );
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.setState({
              message: "Authentication failed. User not found."
            });
          } else if (error.response.status === 401) {
            this.setState({ message: "Authentication failed. Wrong Password" });
          } else if (
            error.response.status === 500 ||
            error.response.status === 422
          ) {
            this.setState({
              message:
                "Authentication failed. Only for Port authorized personnel"
            });
          }
        });
    }
  };

  componentWillUnmount() {
    clearTimeout(this.intervalId);
  }

  render() {
    const { from } = this.props.location.state || {
      from: { pathname: "/dashboard/imports" }
    };
    const {
      newResetPassword,
      confirmNewPassword,
      redirectToReferrer
    } = this.state;

    const { classes } = this.props;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    /* If the user is already logged-in, then he should not be able to nevigate to the password-reset route  */

    return !JSON.parse(localStorage.getItem("user")) &&
      !(
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user"))["token"]
      ) ? (
      <Grid
        container
        spacing={36}
        direction="column"
        alignItems="center"
        justify="center"
        style={{
          minHeight: "100vh",
          backgroundImage: `url(${Image})`,
          height: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "norepeat",
          backgroundSize: "cover"
        }}
      >
        <Card className={classes.card}>
          <CardActionArea
            style={{
              alignItems: "center",
              justify: "center",
              backgroundPosition: "center",
              textAlign: "center",
              display: "block"
            }}
          >
            <CardMedia
              className={classes.media}
              image={require("../../../Images/ApLogo/portlogo.png")}
              title="Reset Password"
              style={styles.media}
            />
            <CardTitle
              title="Reset Password"
              style={{
                marginTop: "10px",
                fontWeight: "bold"
              }}
            />
            <CardContent>
              <ValidatorForm ref="form" onError={errors => console.log(errors)}>
                <PasswordField
                  label="New Password"
                  placeholder="Please enter your new Password"
                  margin="normal"
                  value={newResetPassword}
                  onChange={e =>
                    this.setState({
                      newResetPassword: e.target.value
                    })
                  }
                  helperText={
                    newResetPassword === ""
                      ? "Please enter your new Password"
                      : " "
                  }
                  style={{
                    width: "100%",
                    paddingTop: "35px"
                  }}
                />
                <PasswordField
                  label="Confirm New Password"
                  placeholder="Please re-enter the new Password"
                  margin="normal"
                  value={confirmNewPassword}
                  onChange={e =>
                    this.setState({
                      confirmNewPassword: e.target.value
                    })
                  }
                  helperText={
                    confirmNewPassword === ""
                      ? "Please re-enter the new Password"
                      : " "
                  }
                  style={{
                    width: "100%",
                    paddingTop: "45px"
                  }}
                />
              </ValidatorForm>

              <div>
                <Button
                  onClick={this.passwordResetOnSubmit}
                  variant="outlined"
                  size="large"
                  color="primary"
                  style={{
                    height: "49px",
                    marginTop: "50px",
                    width: "100%"
                  }}
                  type="submit"
                  disabled={
                    newResetPassword === "" || confirmNewPassword === ""
                  }
                >
                  Click here to change your Password
                </Button>
                <WrongDateRangeSnackBar
                  openWrongDateRangeSnackBar={
                    this.state.openWrongDateRangeSnackBar
                  }
                  closeWrongDateRangeSnackBar={this.closeWrongDateRangeSnackBar}
                />
                <PasswordResetConfirmSnackbar
                  openNewItemAddedConfirmSnackbar={
                    this.state.openNewItemAddedConfirmSnackbar
                  }
                  resetPasswordConfirmation={
                    this.state.resetPasswordConfirmation
                  }
                  closeNewItemConfirmSnackbar={this.closeNewItemConfirmSnackbar}
                />
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    ) : (
      <div>
        <div className="alert alert-warning alert-dismissible" role="alert">
          <h5> `You are already logged-in`</h5>
        </div>
        <NotFound />
      </div>
    );
  }
}

PasswordReset.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PasswordReset);

/*
1> To test loggin in with Postman fire a POST request in http://localhost:3000/api/auth/login

{
"email": "abc@live.in",
"newResetPassword":"abc"
}

2> When the PasswordReset request is successful, my then() callback (inside PasswordReset.js function ) will receive a response object with the following properties:

data: the payload returned from the server. By default, Axios expects JSON and will parse this back into a JavaScript object for me.

status: the HTTP code returned from the server.

statusText: the HTTP status message returned by the server.

headers: all the headers sent back by the server. In this app, I will later use getToken(req.headers) to get the actual token saved in localStorage of the browser.

config: the original request configuration.

request: the actual XMLHttpRequest object (when running in a browser).

*/
