import React, {Component} from "react";
import PropTypes from "prop-types";
import {styles} from "../commonStyles/ModuleItemListStyles";
import toolbarStyles from "../commonStyles/toolbarStyles";
import combineStyles from "../commonStyles/combineStyles";
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const tableSortByHeadingUtilFunc = require("../UtilFunctions/tableSortByHeading");
const moment = require("moment");

const CustomTableCell = withStyles(theme => ({
    head: {
        background: "#66CCFF",
        color: theme.palette.common.white,
        align: "left"
    },
    body: {
        fontSize: 14,
        align: "left"
    }
}))(TableCell);

class CommonTableBody extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {classes, dataToRender, tabValue, order, orderBy} = this.props;

        return (
            <TableBody>
                {tableSortByHeadingUtilFunc
                    .stableSort(
                        dataToRender,
                        tableSortByHeadingUtilFunc.getSorting(order, orderBy)
                    ).map((n, index) => {
                        if (tabValue === 0) {//imports
                            return (
                                <TableRow hover style={{height: "35px"}} key={n._id}>
                                    <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                                        {<Typography variant="h4">{index + 1}</Typography>}
                                    </CustomTableCell>
                                    <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                                        {<Typography variant="h4">{n.imported_commodity_name !== null ? n.imported_commodity_name : "-"}</Typography>}
                                    </CustomTableCell>
                                    <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                                        {<Typography variant="h4">{n.qty_in_mts}</Typography>}
                                    </CustomTableCell>
                                    <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                                        {<Typography variant="h4">{n.imported_year !== null ? moment(n.imported_year).format("YYYY") : "-"}</Typography>}
                                    </CustomTableCell>
                                    <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                                        {<Typography variant="h4">{n.imported_month !== null ? moment(n.imported_month).format("MMMM YYYY") : "-"}</Typography>}
                                    </CustomTableCell>
                                    <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                                        {<Typography variant="h4">{n.no_of_vessels_per_month !== null ? n.no_of_vessels_per_month : "-"}</Typography>}
                                    </CustomTableCell>
                                    <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                                        {<Typography variant="h4">{n.voyage_type.length !== 0 ? n.voyage_type : "-"}</Typography>}
                                    </CustomTableCell>
                                    <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                                        {<Typography variant="h4">{n.flag_type.length !== 0 ? n.flag_type : "-"}</Typography>}
                                    </CustomTableCell>
                                </TableRow>
                            )
                        } else if (tabValue === 1) {//exports
                            return (
                                <TableRow hover style={{height: "35px"}} key={n._id}>
                                    <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                                        {<Typography variant="h4">{index + 1}</Typography>}
                                    </CustomTableCell>
                                    <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                                        {<Typography variant="h4">{n.exported_commodity_name !== null ? n.exported_commodity_name : "-"}</Typography>}
                                    </CustomTableCell>
                                    <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                                        {<Typography variant="h4">{n.qty_in_mts}</Typography>}
                                    </CustomTableCell>
                                    <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                                        {<Typography variant="h4">{n.exported_year !== null ? moment(n.exported_year).format("YYYY") : "-"}</Typography>}
                                    </CustomTableCell>
                                    <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                                        {<Typography variant="h4">{n.exported_month !== null ? moment(n.exported_month).format("MMMM YYYY") : "-"}</Typography>}
                                    </CustomTableCell>
                                    <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                                        {<Typography variant="h4">{n.no_of_vessels_per_month !== null ? n.no_of_vessels_per_month : "-"}</Typography>}
                                    </CustomTableCell>
                                    <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                                        {<Typography variant="h4">{n.voyage_type.length !== 0 ? n.voyage_type : "-"}</Typography>}
                                    </CustomTableCell>
                                    <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                                        {<Typography variant="h4">{n.flag_type.length !== 0 ? n.flag_type : "-"}</Typography>}
                                    </CustomTableCell>
                                </TableRow>
                            )
                        } else if (tabValue === 2) {//revenue
                            return (
                                <TableRow hover style={{height: "35px"}} key={n._id}>
                                    <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                                        {<Typography variant="h4">{index + 1}</Typography>}
                                    </CustomTableCell>
                                    <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                                        {<Typography variant="h4">{n.revenue_year !== null ? moment(n.revenue_year).format("YYYY") : "-"}</Typography>}
                                    </CustomTableCell>
                                    <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                                        {<Typography variant="h4">{n.revenue_month !== null ? moment(n.revenue_month).format("MMMM YYYY") : "-"}</Typography>}
                                    </CustomTableCell>
                                    <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                                        {<Typography variant="h4">{n.revenue_amount}</Typography>}
                                    </CustomTableCell>
                                </TableRow>
                            )
                        }
                        // } else if (tabValue === 2) {//other vessels
                        //     return (
                        //         <TableRow hover style={{height: "35px"}} key={n._id}>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{index + 1}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.vesselType}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.noOfVessels}</Typography>}
                        //             </CustomTableCell>
                        //         </TableRow>
                        //     )
                        // } else if (tabValue === 3) {//parcel size
                        //     return (
                        //         <TableRow hover style={{height: "35px"}} key={n._id}>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{index + 1}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.commodity}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.noOfVessels}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.totalCargo}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.avgParcelSize}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.totalShipSize}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.avgShipSize}</Typography>}
                        //             </CustomTableCell>
                        //         </TableRow>
                        //     )
                        // } else if (tabValue === 4) {//avg pre berthing waiting time
                        //     return (
                        //         <TableRow hover style={{height: "35px"}} key={n._id}>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{index + 1}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.commodity}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{~~(n.avgPreBerthingWaitingTimePort / 3600) + " Hour, "
                        //                 + ~~((n.avgPreBerthingWaitingTimePort % 3600) / 60) + " Minutes, "
                        //                 + ~~(n.avgPreBerthingWaitingTimePort % 60) + " Seconds"}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{~~(n.avgPreBerthingWaitingTimeNonPort / 3600) + " Hour, "
                        //                 + ~~((n.avgPreBerthingWaitingTimeNonPort % 3600) / 60) + " Minutes, "
                        //                 + ~~(n.avgPreBerthingWaitingTimeNonPort % 60) + " Seconds"}</Typography>}
                        //             </CustomTableCell>
                        //         </TableRow>
                        //     )
                        // } else if (tabValue === 5) {//avg ship berth through put
                        //     return (
                        //         <TableRow hover style={{height: "35px"}} key={n._id}>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{index + 1}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.commodity}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.avgShipBerthThroughPut}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.shipBerthThroughPut}</Typography>}
                        //             </CustomTableCell>
                        //         </TableRow>
                        //     )
                        // } else if (tabValue === 6) {//income and port due
                        //     return (
                        //         <TableRow hover style={{height: "35px"}} key={n._id}>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{index + 1}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.berthHire}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.wharfage}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.otherServices}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.portDues}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.total}</Typography>}
                        //             </CustomTableCell>
                        //         </TableRow>
                        //     )
                        // } else if (tabValue === 7) {//development works
                        //     return (
                        //         <TableRow hover style={{height: "35px"}} key={n._id}>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{index + 1}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.location}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.workDescription}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.statusOfWork}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{new Date(n.dateOfCommencement).getFullYear() + "-"
                        //                 + (new Date(n.dateOfCommencement).getMonth() + 1) + "-"
                        //                 + new Date(n.dateOfCommencement).getDate()}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{new Date(n.dateOfCompletion).getFullYear() + "-"
                        //                 + (new Date(n.dateOfCompletion).getMonth() + 1) + "-"
                        //                 + new Date(n.dateOfCompletion).getDate()}</Typography>}
                        //             </CustomTableCell>
                        //         </TableRow>
                        //     )
                        // } else if(tabValue === 8) {//Revenue
                        //     return (
                        //         <TableRow hover style={{height: "35px"}} key={n._id}>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{index + 1}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.commodityName}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.importsAmount}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.exportsAmount}</Typography>}
                        //             </CustomTableCell>
                        //         </TableRow>
                        //     )
                        // } else if(tabValue === 9) {//Berthing Turn Around Time
                        //     return (
                        //         <TableRow hover style={{height: "35px"}} key={n._id}>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{index + 1}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.vesselName}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{new Date(n.dateOfArrival).getFullYear() + "-"
                        //                 + (new Date(n.dateOfArrival).getMonth() + 1) + "-"
                        //                 + new Date(n.dateOfArrival).getDate()}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{new Date(n.berthingTime).getFullYear() + "-"
                        //                 + (new Date(n.berthingTime).getMonth() + 1) + "-"
                        //                 + new Date(n.berthingTime).getDate()}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{new Date(n.unBerthingTme).getFullYear() + "-"
                        //                 + (new Date(n.unBerthingTme).getMonth() + 1) + "-"
                        //                 + new Date(n.unBerthingTme).getDate()}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.turnAroundTime}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{new Date(n.dateOfDeparture).getFullYear() + "-"
                        //                 + (new Date(n.dateOfDeparture).getMonth() + 1) + "-"
                        //                 + new Date(n.dateOfDeparture).getDate()}</Typography>}
                        //             </CustomTableCell>
                        //         </TableRow>
                        //     )
                        // } else if(tabValue === 10) {//Trucking Trips
                        //     return (
                        //         <TableRow hover style={{height: "35px"}} key={n._id}>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{index + 1}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.noOfTrucksInMonth}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.totalNoOfTripsInMonth}</Typography>}
                        //             </CustomTableCell>
                        //             <CustomTableCell align="center" className={classes.reportsCustomTableCellStyles}>
                        //                 {<Typography variant="h4">{n.tripsMonth !== null ? moment(n.tripsMonth).format(
                        //                     "MMM-YYYY"
                        //                 ) : "-"}</Typography>}
                        //             </CustomTableCell>
                        //         </TableRow>
                        //     )
                        // }
                    })}
            </TableBody>
        );
    }
}

CommonTableBody.propTypes = {
    classes: PropTypes.object.isRequired
};

const combinedStyles = combineStyles(styles, toolbarStyles);

export default withStyles(combinedStyles)(CommonTableBody);
