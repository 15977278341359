import React, {Component} from "react";
//Material UI
import {withStyles} from "@material-ui/core";
import styles from "./kpiViewStyles.js";
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
//Components
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
//Others
import axios from "axios";
import $ from "jquery";
import JsPdf from "jspdf";
import PropTypes from "prop-types";
import html2canvas from "html2canvas";
import setAxiosAuthheaders from "../UtilFunctions/setAxiosAuthheaders";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

const moment = require("moment");

const indicatorsList = [
    {indicator: "Total Volume of Cargo handled", unit: "MT", ref: "total"},
    {indicator: "Imports", unit: "MT", ref: "import"},
    {indicator: "Exports", unit: "MT", ref: "export"},
    {indicator: "Coastal In", unit: "MT", ref: "coastal_in"},
    {indicator: "Coastal Out", unit: "MT", ref: "coastal_out"},
    {indicator: "Others", unit: "", ref: "others"},
    {indicator: "Container Traffic Handled", unit: "TEUs", ref: "container_traffic_handled"},
    {
        indicator: "Percentage utilization of Traffic Handling Capacity of the Port",
        unit: "%",
        ref: "percentage_utilization_of_traffic_handling_capacity_of_the_port"
    },
    {indicator: "Total IncomeAndPortDues Generated by the Port", unit: "Rs cr", ref: "revenue_amount"},
    {
        indicator: "IncomeAndPortDues Accrued to GOAP as share as per Concession Agreement",
        unit: "Rs cr",
        ref: "govt_share"
    },
    {indicator: "Average Vehicle Turn Around Time (TAT)", unit: "Hrs", ref: "average_vehicle_turn_around_time"},
    {indicator: "Bulk & Handymax", unit: "Hrs", ref: "bulk_and_handymax"},
    {indicator: "Bulk & Panamax", unit: "Hrs", ref: "bulk_and_panamax"},
    {indicator: "Bulk & Cape Size", unit: "Hrs", ref: "bulk_and_cape_size"},
    {indicator: "Containers", unit: "Hrs", ref: "containers"},
    {indicator: "Tankers", unit: "Hrs", ref: "containers"},
    {indicator: "Average Pre Berth Delays", unit: "Hrs", ref: "average_pre_berth_delays"},
    {indicator: "Total Cargo Evacuation from Port", unit: "MT", ref: "total_cargo_evacuation_from_port"},
    {indicator: "By Rail", unit: "MT", ref: "by_rail"},
    {indicator: "By Road", unit: "MT", ref: "by_road"},
    {indicator: "By Conveyor", unit: "MT", ref: "by_conveyor"},
    {indicator: "By Others", unit: "MT", ref: "by_others"},
    {indicator: "Any Other Specific Info to be Shared", unit: "", ref: "specific_information"},
];

class KpiView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            portsName: [],

            cargoData: [],
            revenueData: [],
            shareData: [],

            portsStatusData: [],

            yearAndMonth: new Date(),
            monthlyData: [],
            monthlySum: {},

            year: null,
            portSelectId: "",
            portSelectName: "",
            monthWiseCargo: {},

            fromToYears: [],
        };
    }

    componentWillMount() {
        setAxiosAuthheaders();
    }

    componentDidMount() {

        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).userType === "port-admin"
            ?
            axios.get(`/api/kpi/getPortsName`).then(res => {
                this.setState({
                    portsName: res.data,
                });
            })
            :
            console.log("Invalid");

        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).userType === "port-admin"
            ?
            axios.get(`/api/kpi/getAnnualData`).then(res => {
                this.setState({
                    cargoData: res.data[0].cargoData,
                    revenueData: res.data[0].revenueData,
                    shareData: res.data[0].shareData,
                });
            })
            :
            console.log("Invalid");

        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).userType === "port-admin"
            ?
            axios.get(`/api/kpi/getPortsStatus`).then(res => {
                this.updatePortsStatusData(res.data);
            })
            :
            console.log("Invalid");

        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).userType === "port-admin"
            ?
            this.getMonthlyData()
            :
            console.log("Invalid");

        this.yearlyCargoGrossRevenueData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.yearAndMonth !== prevState.yearAndMonth) {
            this.getMonthlyData();
        }

        if (this.state.year !== prevState.year || this.state.portSelectId !== prevState.portSelectId) {
            this.getMonthWiseCargo(this.state.year, this.state.portSelectId);
        }
    }

    updatePortsStatusData = (data) => {
        data.map(each => {
            if (each.data.length !== this.state.portsName.length) {
                for (let i = 0; i < this.state.portsName.length; i++) {
                    if (each.data[i] !== undefined) {
                        if (each.data[i].port_name !== this.state.portsName[i].port_name) {
                            each.data.push(each.data[i]);
                            each.data[i] = {port_name: this.state.portsName[i].port_name};
                        }
                    } else {
                        each.data.push({port_name: this.state.portsName[i].port_name})
                    }
                }
            }
        });

        this.setState({
            portsStatusData: data,
        });
    };

    getMonthlyData = () => {
        let start = moment(this.state.yearAndMonth).startOf("month").toISOString();
        let end = moment(this.state.yearAndMonth).endOf("month").toISOString();

        axios.get("/api/kpi/getMonthlyData", {
            params: {
                start,
                end
            }
        }).then(res => {

            let finalList = [];
            let cargoSum = 0;
            let revenueSum = 0;
            let shareSum = 0;

            indicatorsList.map(item => {
                let list = {
                    indicator: item.indicator,
                    unit: item.unit,
                    data: []
                };

                this.state.portsName.map(name => {
                    list.data.push({port_name: name.port_name, value: ""})
                });

                res.data.map(port => {
                    if (item.ref === "total") {
                        port.cargo.map(cargo => {
                            if (cargo.cargo_type === null) {
                                list.data.map(each => {
                                    if (each.port_name === port.port_name) {
                                        each["value"] = cargo.qty_in_mts;
                                        cargoSum += cargo.qty_in_mts;
                                    }
                                });
                            }
                        })
                    } else if (item.ref === "import") {
                        port.cargo.map(cargo => {
                            if (cargo.cargo_type === "Import") {
                                list.data.map(each => {
                                    if (each.port_name === port.port_name) {
                                        each["value"] = cargo.qty_in_mts
                                    }
                                });
                            }
                        })
                    } else if (item.ref === "export") {
                        port.cargo.map(cargo => {
                            if (cargo.cargo_type === "Export") {
                                list.data.map(each => {
                                    if (each.port_name === port.port_name) {
                                        each["value"] = cargo.qty_in_mts
                                    }
                                });
                            }
                        })
                    } else {
                        list.data.map(each => {
                            if (each.port_name === port.port_name) {
                                each["value"] = port[item.ref];
                                if (item.ref === "revenue_amount") {
                                    revenueSum += port[item.ref]
                                }
                                if (item.ref === "govt_share") {
                                    shareSum += port[item.ref]
                                }
                            }
                        });
                    }
                });

                finalList.push(list);
            });

            this.setState({
                monthlyData: finalList,
                monthlySum: {
                    cargoSum,
                    revenueSum,
                    shareSum
                }
            });
        })
    };

    handleYearAndMonthChange = date => {
        this.setState({
            yearAndMonth: date
        });
    };

    handleYearChange = date => {
        this.setState({
            year: date
        });
    };

    handleSelectPort = e => {
        this.setState({
            portSelectId: e.target.value,
        });
        this.state.portsName.forEach(each => {
            if (each._id === e.target.value) {
                this.setState({
                    portSelectName: each.port_name
                });
            }
        })
    };

    getMonthWiseCargo = (year, portId) => {
        let temp = new Date(year).getFullYear();
        let start = moment(new Date(temp, 3, 1)).toISOString();
        let end = moment(new Date(temp + 1, 2, 30)).toISOString();
        let months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        if (year !== null && portId !== "") {
            axios.get("/api/kpi/getMonthWiseCargo", {
                params: {
                    start,
                    end,
                    portId
                }
            }).then(res => {
                let monthNames = [];
                let exports = [];
                let imports = [];
                let rawExports = [];
                let rawImports = [];
                let exportNames = [];
                let importNames = [];
                let exportsTotal = [];
                let importsTotal = [];
                let containerMts = [];
                let total = [];

                res.data[0].ie.map(each => {
                    monthNames.push(months[each._id.month] + "-" + (each._id.year).toString())
                });

                monthNames.sort(function sort(a, b) {
                    let aa = a.split('-'),
                        bb = b.split('-');
                    return aa[1] - bb[1] || aa[0] - bb[0];
                });

                let sortMonthNames = [...new Set(monthNames)];

                sortMonthNames.map(month => {
                    res.data[0].ie.map(each => {
                        if (months[each._id.month] + "-" + (each._id.year).toString() === month) {
                            if (each._id.cargo_type === "Export") {
                                rawExports.push(each.data)
                            }
                        }
                    });
                    res.data[0].ie.map(each => {
                        if (months[each._id.month] + "-" + (each._id.year).toString() === month) {
                            if (each._id.cargo_type === "Import") {
                                rawImports.push(each.data)
                            }
                        }
                    });
                    res.data[0].ie.map(each => {
                        if (months[each._id.month] + "-" + (each._id.year).toString() === month) {
                            if (each._id.cargo_type === "Export") {
                                each.data.map(cargo => {
                                    exportNames.push(cargo.commodity)
                                })
                            }
                        }
                    });
                    res.data[0].ie.map(each => {
                        if (months[each._id.month] + "-" + (each._id.year).toString() === month) {
                            if (each._id.cargo_type === "Import") {
                                each.data.map(cargo => {
                                    importNames.push(cargo.commodity)
                                })
                            }
                        }
                    });
                    res.data[0].ie.map(each => {
                        if (months[each._id.month] + "-" + (each._id.year).toString() === month) {
                            if (each._id.cargo_type === "Export") {
                                exportsTotal.push(each.total)
                            }
                        }
                    });
                    res.data[0].ie.map(each => {
                        if (months[each._id.month] + "-" + (each._id.year).toString() === month) {
                            if (each._id.cargo_type === "Import") {
                                importsTotal.push(each.total)
                            }
                        }
                    });
                    res.data[0].container.map(each => {
                        if (months[each.month] + "-" + (each.year).toString() === month) {
                            containerMts.push(each.qty_in_mts)
                        }
                    })
                });

                sortMonthNames.map((month, index) => {
                    let et = exportsTotal[index] !== undefined ? exportsTotal[index] : 0;
                    let it = importsTotal[index] !== undefined ? importsTotal[index] : 0;
                    let ct = containerMts[index] !== undefined ? containerMts[index] : 0;
                    total[index] = et + it + ct;
                });

                let sortExportNames = [...new Set(exportNames)];
                sortExportNames.sort();

                let sortImportNames = [...new Set(importNames)];
                sortImportNames.sort();

                sortExportNames.map(name => {
                    let value = [];
                    rawExports.map((raw, index) => {
                        raw.map(each => {
                            if (each.commodity === name) {
                                value[index] = each.qty_in_mts
                            }
                        });
                        if (value[index] === undefined) {
                            value[index] = null;
                        }
                    });
                    exports.push({material: name, value: value});
                });

                sortImportNames.map(name => {
                    let value = [];
                    rawImports.map((raw, index) => {
                        raw.map(each => {
                            if (each.commodity === name) {
                                value[index] = each.qty_in_mts
                            }
                        });
                        if (value[index] === undefined) {
                            value[index] = null;
                        }
                    });
                    imports.push({material: name, value: value});
                });

                this.setState({
                    monthWiseCargo: {
                        month: sortMonthNames,
                        exports: exports,
                        imports: imports,
                        exportsTotal: exportsTotal,
                        importsTotal: importsTotal,
                        containerMts: containerMts,
                        total: total
                    }
                });
            })
        }
    };

    yearlyCargoGrossRevenueData = () => {
        let d;
        let tempStore = [];
        for (d = new Date("January 01, 1999").getFullYear(); d <= new Date().getFullYear() - 1; d++) {
            tempStore.push({fromTo: d + "-" + (d + 1), year: d.toString()});
        }
        this.setState({
            fromToYears: tempStore
        });
    };

    render() {
        const {classes} = this.props;
        const {portsName, cargoData, revenueData, shareData, fromToYears, portsStatusData, yearAndMonth, monthlyData, monthlySum, year, portSelectId, portSelectName, monthWiseCargo} = this.state;

        console.log(monthWiseCargo);

        let printTableOne = () => {

            let top_left_X_Y_Coordinates = 0;
            let element = document.getElementById("print-page-one");
            let height = element.offsetHeight;
            let width = element.offsetWidth;
            let pdf = "";

            html2canvas($("#print-page-one")[0], {allowTaint: true}).then(function (canvas) {
                canvas.getContext('2d');

                let imgData = canvas.toDataURL("image/jpeg", 1.0);
                pdf = new JsPdf('l', 'pt', [width, height]);
                pdf.addImage(imgData, 'JPG', top_left_X_Y_Coordinates, top_left_X_Y_Coordinates, width, height);
                pdf.output('dataurlnewwindow');
            });
        };

        let printTableTwo = () => {

            let top_left_X_Y_Coordinates = 0;
            let element = document.getElementById("print-page-two");
            let height = element.offsetHeight;
            let width = element.offsetWidth;
            let pdf = "";

            html2canvas($("#print-page-two")[0], {allowTaint: true}).then(function (canvas) {
                canvas.getContext('2d');

                let imgData = canvas.toDataURL("image/jpeg", 1.0);
                pdf = new JsPdf('l', 'pt', [width, height]);
                pdf.addImage(imgData, 'JPG', top_left_X_Y_Coordinates, top_left_X_Y_Coordinates, width, height);
                pdf.output('dataurlnewwindow');
            });
        };

        let printTableThree = () => {

            let top_left_X_Y_Coordinates = 0;
            let element = document.getElementById("print-page-three");
            let height = element.offsetHeight;
            let width = element.offsetWidth;
            let pdf = "";

            html2canvas($("#print-page-three")[0], {allowTaint: true}).then(function (canvas) {
                canvas.getContext('2d');

                let imgData = canvas.toDataURL("image/jpeg", 1.0);
                pdf = new JsPdf('l', 'pt', [width, height]);
                pdf.addImage(imgData, 'JPG', top_left_X_Y_Coordinates, top_left_X_Y_Coordinates, width, height);
                pdf.output('dataurlnewwindow');
            });
        };

        let printTableFour = () => {

            let top_left_X_Y_Coordinates = 0;
            let element = document.getElementById("print-page-four");
            let height = element.offsetHeight;
            let width = element.offsetWidth;
            let pdf = "";

            html2canvas($("#print-page-four")[0], {allowTaint: true}).then(function (canvas) {
                canvas.getContext('2d');

                let imgData = canvas.toDataURL("image/jpeg", 1.0);
                pdf = new JsPdf('l', 'pt', [width, height]);
                pdf.addImage(imgData, 'JPG', top_left_X_Y_Coordinates, top_left_X_Y_Coordinates, width, height);
                pdf.output('dataurlnewwindow');
            });
        };

        return (
            <MuiThemeProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => printTableOne()}
                        style={{marginRight: 20}}
                    >
                        Print Table 1
                    </Button>

                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => printTableTwo()}
                        style={{marginRight: 20}}
                    >
                        Print Table 2
                    </Button>

                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => printTableThree()}
                        style={{marginRight: 20}}
                    >
                        Print Table 3
                    </Button>

                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => printTableFour()}
                        style={{marginRight: 20}}
                    >
                        Print Table 4
                    </Button>

                    <div id="print-page-one" style={{display: "flex", flexDirection: "column", margin: "20px 0"}}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={cargoData.length * 3 + 1} align="center"
                                                   className={classes.tableCellColorBlackAndBorder}>
                                            Cargo handled, Gross Income to the Developer and Revenue Share to Government
                                            in respect of the below PPP Ports
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell rowSpan={2} align="center"
                                                   className={classes.tableCellColorGreyAndBorderAndPadding}>Period</TableCell>
                                        <TableCell colSpan={cargoData.length} align="center"
                                                   className={classes.tableCellColorGreyAndBorder}>Cargo Handled (Mn.
                                            Tons)</TableCell>
                                        <TableCell colSpan={revenueData.length} align="center"
                                                   className={classes.tableCellColorGreyAndBorder}>Gross Revenue to
                                            Developer (Rs. in crs)</TableCell>
                                        <TableCell colSpan={shareData.length} align="center"
                                                   className={classes.tableCellColorGreyAndBorder}>Revenue share to the
                                            Government (Rs. in crs)</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {
                                            cargoData.map(item => (
                                                <TableCell align="center" key={item._id.id}
                                                           className={classes.tableCellColorWhiteAndBorder}>
                                                    {item._id.port_name}
                                                </TableCell>
                                            ))
                                        }
                                        {
                                            revenueData.map(item => (
                                                <TableCell align="center" key={item._id.id}
                                                           className={classes.tableCellColorWhiteAndBorder}>
                                                    {item._id.port_name}
                                                </TableCell>
                                            ))
                                        }
                                        {
                                            shareData.map(item => (
                                                <TableCell align="center" key={item._id.id}
                                                           className={classes.tableCellColorWhiteAndBorder}>
                                                    {item._id.port_name + " (" + item._id.revenue_share + "%)"}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        fromToYears.map((year, index) => (
                                            <TableRow align="center" key={index}
                                                      className={classes.tableCellColorGreyAndBorderAndPadding}>
                                                {year.fromTo}
                                                {cargoData.map((item, index) => (
                                                    <TableCell align="center" key={index}
                                                               className={classes.tableCellColorWhiteAndBorder}>
                                                        {
                                                            item.cargo.map(cargo => (
                                                                cargo.cargo_year === year.year ?
                                                                    cargo.cargo_qty
                                                                    :
                                                                    ""
                                                            ))
                                                        }
                                                    </TableCell>
                                                ))}
                                                {revenueData.map((item, index) => (
                                                    <TableCell align="center" key={index}
                                                               className={classes.tableCellColorWhiteAndBorder}>
                                                        {
                                                            item.revenue.map(revenue => (
                                                                revenue.revenue_year === year.year ?
                                                                    revenue.revenue_amount
                                                                    :
                                                                    ""
                                                            ))
                                                        }
                                                    </TableCell>
                                                ))}
                                                {shareData.map((item, index) => (
                                                    <TableCell align="center" key={index}
                                                               className={classes.tableCellColorWhiteAndBorder}>
                                                        {
                                                            item.share.map(share => (
                                                                share.share_year === year.year ?
                                                                    share.share_amount
                                                                    :
                                                                    ""
                                                            ))
                                                        }
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    }

                                    <TableRow>
                                        <TableCell align="center"
                                                   className={classes.tableCellColorGreyAndBorderAndPadding}>
                                        </TableCell>
                                        {
                                            cargoData.map(item => (
                                                <TableCell align="center" key={item._id.id}
                                                           className={classes.tableCellColorGreyAndBorderAndPadding}>
                                                    {item.total}
                                                </TableCell>
                                            ))
                                        }
                                        {
                                            revenueData.map(item => (
                                                <TableCell align="center" key={item._id.id}
                                                           className={classes.tableCellColorGreyAndBorderAndPadding}>
                                                    {item.total}
                                                </TableCell>
                                            ))
                                        }
                                        {
                                            shareData.map(item => (
                                                <TableCell align="center" key={item._id.id}
                                                           className={classes.tableCellColorGreyAndBorderAndPadding}>
                                                    {item.total}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center"
                                                   className={classes.tableCellColorGreyAndBorderAndPadding}>
                                            Investments
                                        </TableCell>
                                        {
                                            shareData.map(item => (
                                                <TableCell align="center" key={item._id.id}
                                                           className={classes.tableCellColorWhiteAndBorder}>
                                                    {
                                                        item._id.investments.length !== 0 ?
                                                            "Rs." + item._id.investments + " crs"
                                                            :
                                                            "--"
                                                    }
                                                </TableCell>
                                            ))
                                        }
                                        <TableCell colSpan={shareData.length * 2} align="center"
                                                   className={classes.tableCellColorWhiteAndBorder}>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center"
                                                   className={classes.tableCellColorGreyAndBorderAndPadding}>
                                            No. of Berths
                                        </TableCell>
                                        {
                                            shareData.map(item => (
                                                <TableCell align="center" key={item._id.id}
                                                           className={classes.tableCellColorWhiteAndBorder}>
                                                    {
                                                        item._id.no_of_berths.length !== 0 ?
                                                            item._id.no_of_berths
                                                            :
                                                            "--"
                                                    }
                                                </TableCell>
                                            ))
                                        }
                                        <TableCell colSpan={shareData.length * 2} align="center"
                                                   className={classes.tableCellColorWhiteAndBorder}>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <div id="print-page-two" style={{display: "flex", flexDirection: "column", margin: "20px 0"}}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={portsName.length * 3 + 4} align="center"
                                                   className={classes.tableCellColorBlackAndBorder}>
                                            Status of AP Ports Operations
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell rowSpan={2} align="center" style={{width: 90}}
                                                   className={classes.tableCellColorGreyAndBorderAndPadding}>Period</TableCell>
                                        {
                                            portsName.map((item) => (
                                                <TableCell colSpan={3} align="center"
                                                           className={classes.tableCellColorGreyAndBorder}>{item.port_name + "(" + item.revenue_share + "%)"}
                                                </TableCell>
                                            ))
                                        }
                                        <TableCell colSpan={3} align="center"
                                                   className={classes.tableCellColorGreyAndBorder}>APMB
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {
                                            portsName.map(() => (
                                                <>
                                                    <TableCell align="center"
                                                               className={classes.tableCellColorWhiteAndBorder}>
                                                        Cargo
                                                    </TableCell>
                                                    <TableCell align="center"
                                                               className={classes.tableCellColorWhiteAndBorder}>
                                                        Revenue
                                                    </TableCell>
                                                    <TableCell align="center"
                                                               className={classes.tableCellColorWhiteAndBorder}>
                                                        Govt. Share
                                                    </TableCell>
                                                </>
                                            ))
                                        }
                                        <TableCell align="center"
                                                   className={classes.tableCellColorWhiteAndBorder}>
                                            Cargo
                                        </TableCell>
                                        <TableCell align="center"
                                                   className={classes.tableCellColorWhiteAndBorder}>
                                            Revenue
                                        </TableCell>
                                        <TableCell align="center"
                                                   className={classes.tableCellColorWhiteAndBorder}>
                                            Govt. Share
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        portsStatusData.map((each, index) => (
                                            <TableRow align="center" key={index}
                                                      className={classes.tableCellColorGreyAndBorderAndPadding}>
                                                <TableCell align="center"
                                                           className={classes.tableCellColorGreyAndBorderAndPadding}>
                                                    {each.period + "-" + (parseInt(each.period) + 1)}
                                                </TableCell>
                                                {
                                                    each.data.map(data => (
                                                        <>
                                                            {
                                                                data.hasOwnProperty('cargo') ?
                                                                    <TableCell align="center"
                                                                               className={classes.tableCellColorWhiteAndBorder}>
                                                                        {data.cargo !== undefined ? data.cargo.toFixed(2) : "--"}
                                                                    </TableCell>
                                                                    :
                                                                    <TableCell align="center"
                                                                               className={classes.tableCellColorWhiteAndBorder}>
                                                                        {"--"}
                                                                    </TableCell>
                                                            }
                                                            {
                                                                data.hasOwnProperty('revenue') ?
                                                                    <TableCell align="center"
                                                                               className={classes.tableCellColorWhiteAndBorder}>
                                                                        {data.revenue !== undefined ? data.revenue.toFixed(2) : "--"}
                                                                    </TableCell>
                                                                    :
                                                                    <TableCell align="center"
                                                                               className={classes.tableCellColorWhiteAndBorder}>
                                                                        {"--"}
                                                                    </TableCell>
                                                            }
                                                            {
                                                                data.hasOwnProperty('share') ?
                                                                    <TableCell align="center"
                                                                               className={classes.tableCellColorWhiteAndBorder}>
                                                                        {data.share !== undefined ? data.share.toFixed(2) : "--"}
                                                                    </TableCell>
                                                                    :
                                                                    <TableCell align="center"
                                                                               className={classes.tableCellColorWhiteAndBorder}>
                                                                        {"--"}
                                                                    </TableCell>
                                                            }
                                                        </>
                                                    ))
                                                }
                                                <TableCell align="center"
                                                           className={classes.tableCellColorWhiteAndBorder}>
                                                    {each.sum.cargo !== undefined ? each.sum.cargo.toFixed(2) : "--"}
                                                </TableCell>
                                                <TableCell align="center"
                                                           className={classes.tableCellColorWhiteAndBorder}>
                                                    {each.sum.revenue !== undefined ? each.sum.revenue.toFixed(2) : "--"}
                                                </TableCell>
                                                <TableCell align="center"
                                                           className={classes.tableCellColorWhiteAndBorder}>
                                                    {each.sum.share !== undefined ? each.sum.share.toFixed(2) : "--"}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", alignItems: "flex-end", marginTop: 20}}>
                        <Paper>
                            <div style={{display: "flex", justifyContent: "center", width: "100%", padding: "0 20px"}}>
                                <DatePicker
                                    autoOk
                                    views={["year", "month"]}
                                    label="Select Year and Month"
                                    value={yearAndMonth}
                                    onChange={this.handleYearAndMonthChange}
                                    style={{width: 200, margin: "5px 0"}}
                                />
                            </div>
                        </Paper>
                    </div>

                    <div id="print-page-three" style={{display: "flex", flexDirection: "column", marginTop: 20}}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={portsName.length + 3} align="center"
                                                   className={classes.tableCellBottomRightBorder}>
                                            A.P.NON - MAJOR PORTS: KEY PERFORMANCE INDICATORS FOR THE MONTH
                                            OF {moment(yearAndMonth).format("MMMM").toUpperCase()}&nbsp;
                                            {moment(yearAndMonth).format("YYYY")}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" className={classes.tableCellBottomRightBorder}>
                                            SI.No</TableCell>
                                        <TableCell align="center" className={classes.tableCellBottomRightBorder}>
                                            Key Performance Indicators</TableCell>
                                        <TableCell align="center" className={classes.tableCellBottomRightBorder}>
                                            Unit of Measurement</TableCell>

                                        {portsName.map(item => (
                                            <TableCell align="center"
                                                       className={classes.tableCellBottomRightBorder}>{item.port_name}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {monthlyData.map((item, index) => (
                                        <TableRow>
                                            <TableCell align="center"
                                                       className={classes.tableCellBottomRightBorder}>{index + 1}</TableCell>
                                            <TableCell align="left" style={{"width": 400, "paddingLeft": 5}}
                                                       className={classes.tableCellBottomRightBorder}>{item.indicator}</TableCell>
                                            <TableCell align="center" style={{"width": 35}}
                                                       className={classes.tableCellBottomRightBorder}>{item.unit}</TableCell>

                                            {item.data.map(each => (
                                                <TableCell align="center"
                                                           className={classes.tableCellBottomRightBorder}>
                                                    {each.value !== "" ? each.value : "---"}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TableContainer component={Paper}>
                            <h6>Total volume of cargo handled
                                - {monthlySum.cargoSum !== undefined ? monthlySum.cargoSum.toFixed(2) : 0}</h6>
                            <h6>Total revenue earned (in crores)
                                - {monthlySum.revenueSum !== undefined ? monthlySum.revenueSum.toFixed(2) : 0}</h6>
                            <h6>Revenue - Govt share (in crores)
                                - {monthlySum.shareSum !== undefined ? monthlySum.shareSum.toFixed(2) : 0}</h6>
                        </TableContainer>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", alignItems: "flex-end", marginTop: 20}}>
                        <Paper>
                            <div style={{display: "flex", justifyContent: "center", width: "100%", padding: "0 20px"}}>
                                <DatePicker
                                    autoOk
                                    clearable
                                    views={["year"]}
                                    label="Select Financial Year"
                                    value={year}
                                    onChange={this.handleYearChange}
                                    style={{width: 200, margin: "5px 0"}}
                                />
                            </div>
                        </Paper>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <Paper>
                            <div style={{display: "flex", justifyContent: "center", width: "100%", padding: "0 20px"}}>
                                <TextField
                                    select
                                    label="Select Port"
                                    style={{width: 200, margin: "5px 0"}}
                                    value={portSelectId}
                                    onChange={this.handleSelectPort}
                                >
                                    {portsName.map(item => (
                                        <MenuItem key={item._id} value={item._id}>
                                            {item.port_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </Paper>
                    </div>

                    <div id="print-page-four" style={{display: "flex", flexDirection: "column", marginTop: 20}}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            colSpan={monthWiseCargo.month !== undefined ? monthWiseCargo.month.length + 2 : 14}
                                            align="center"
                                            className={classes.tableCellBottomRightBorder}>
                                            {portSelectName} - Month wise Cargo Handled
                                            (FY {moment(year).year()}-{moment(year).year() + 1})
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" className={classes.tableCellBottomRightBorder}>
                                            SI.No</TableCell>
                                        <TableCell align="center" className={classes.tableCellBottomRightBorder}>
                                            Particulars</TableCell>

                                        {
                                            monthWiseCargo.month !== undefined ?
                                                monthWiseCargo.month.map(each => (
                                                    <TableCell align="center"
                                                               className={classes.tableCellBottomRightBorder}>{each}</TableCell>
                                                ))
                                                : undefined
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center"
                                                   className={classes.tableCellBottomRightBorder}> </TableCell>
                                        <TableCell align="center" style={{fontWeight: "bold"}}
                                                   className={classes.tableCellBottomRightBorder}>Export</TableCell>
                                        {
                                            monthWiseCargo.month !== undefined ?
                                                monthWiseCargo.month.map(() => (
                                                    <TableCell align="center" style={{fontWeight: "bold"}}
                                                               className={classes.tableCellBottomRightBorder}> </TableCell>
                                                ))
                                                : undefined
                                        }
                                    </TableRow>

                                    {
                                        monthWiseCargo.exports !== undefined ?
                                            monthWiseCargo.exports.map((each, index) => (
                                                <>
                                                    <TableRow>
                                                        <TableCell align="center"
                                                                   className={classes.tableCellBottomRightBorder}>{index + 1}</TableCell>
                                                        <TableCell align="center"
                                                                   className={classes.tableCellBottomRightBorder}>{each.material}</TableCell>
                                                        {
                                                            each.value.map(item => (
                                                                <TableCell align="center"
                                                                           className={classes.tableCellBottomRightBorder}>{item}</TableCell>
                                                            ))
                                                        }
                                                    </TableRow>
                                                </>
                                            ))
                                            : undefined
                                    }

                                    <TableRow>
                                        <TableCell align="center"
                                                   className={classes.tableCellBottomRightBorder}> </TableCell>
                                        <TableCell align="center" style={{fontWeight: "bold"}}
                                                   className={classes.tableCellBottomRightBorder}>Exports Total</TableCell>
                                        {
                                            monthWiseCargo.exportsTotal !== undefined ?
                                                monthWiseCargo.exportsTotal.map(total => (
                                                    <TableCell align="center" style={{fontWeight: "bold"}}
                                                               className={classes.tableCellBottomRightBorder}>{total}</TableCell>
                                                ))
                                                : undefined
                                        }
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center"
                                                   className={classes.tableCellBottomRightBorder}> </TableCell>
                                        <TableCell align="center" style={{fontWeight: "bold"}}
                                                   className={classes.tableCellBottomRightBorder}>Import</TableCell>
                                        {
                                            monthWiseCargo.month !== undefined ?
                                                monthWiseCargo.month.map(() => (
                                                    <TableCell align="center" style={{fontWeight: "bold"}}
                                                               className={classes.tableCellBottomRightBorder}> </TableCell>
                                                ))
                                                : undefined
                                        }
                                    </TableRow>

                                    {
                                        monthWiseCargo.imports !== undefined ?
                                            monthWiseCargo.imports.map((each, index) => (
                                                <>
                                                    <TableRow>
                                                        <TableCell align="center"
                                                                   className={classes.tableCellBottomRightBorder}>{index + 1}</TableCell>
                                                        <TableCell align="center"
                                                                   className={classes.tableCellBottomRightBorder}>{each.material}</TableCell>
                                                        {
                                                            each.value.map(item => (
                                                                <TableCell align="center"
                                                                           className={classes.tableCellBottomRightBorder}>{item}</TableCell>
                                                            ))
                                                        }
                                                    </TableRow>
                                                </>
                                            ))
                                            : undefined
                                    }

                                    <TableRow>
                                        <TableCell align="center"
                                                   className={classes.tableCellBottomRightBorder}> </TableCell>
                                        <TableCell align="center" style={{fontWeight: "bold"}}
                                                   className={classes.tableCellBottomRightBorder}>Imports Total</TableCell>
                                        {
                                            monthWiseCargo.importsTotal !== undefined ?
                                                monthWiseCargo.importsTotal.map(total => (
                                                    <TableCell align="center" style={{fontWeight: "bold"}}
                                                               className={classes.tableCellBottomRightBorder}>{total}</TableCell>
                                                ))
                                                : undefined
                                        }
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center"
                                                   className={classes.tableCellBottomRightBorder}> </TableCell>
                                        <TableCell align="center" style={{fontWeight: "bold"}}
                                                   className={classes.tableCellBottomRightBorder}>Containers
                                            (MTs)</TableCell>
                                        {
                                            monthWiseCargo.containerMts !== undefined ?
                                                monthWiseCargo.containerMts.map(mt => (
                                                    <TableCell align="center" style={{fontWeight: "bold"}}
                                                               className={classes.tableCellBottomRightBorder}>{mt}</TableCell>
                                                ))
                                                : undefined
                                        }
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center"
                                                   className={classes.tableCellBottomRightBorder}> </TableCell>
                                        <TableCell align="center" style={{fontWeight: "bold"}}
                                                   className={classes.tableCellBottomRightBorder}>Total</TableCell>
                                        {
                                            monthWiseCargo.total !== undefined ?
                                                monthWiseCargo.total.map(value => (
                                                    <TableCell align="center" style={{fontWeight: "bold"}}
                                                               className={classes.tableCellBottomRightBorder}>{value}</TableCell>
                                                ))
                                                : undefined
                                        }
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        );
    }
}

export default withStyles(styles)(KpiView);
