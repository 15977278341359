import React from 'react';
import {useDropzone} from 'react-dropzone';

export default function DropZone(props) {
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({noDrag: true, accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'});

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    return (
        <section className="container">
            <div {...getRootProps({className: 'dropzone'})} style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '20px',
                borderWidth: 2,
                borderRadius: 2,
                borderStyle: 'dashed',
                backgroundColor: '#fafafa',
                outline: 'none',
                transition: 'border .24s ease-in-out'
            }}>
                <input {...getInputProps()} />
                <p>click to select a file</p>
            </div>
            <aside>
                <h4>File Name</h4>
                <ul>{files}</ul>
            </aside>
        </section>
    );
}
