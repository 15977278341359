import React, {Component} from "react";
//Material UI
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {withStyles} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {styles} from "../commonStyles/AddNewItemStyles";
import toolbarStyles from "../commonStyles/toolbarStyles";
import combineStyles from "../commonStyles/combineStyles";
// import theme from "../commonStyles/AddNewItemThemes";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
//Date Pickers
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
//Icons
import CancelIcon from "@material-ui/icons/Cancel";
//Snack Bars
import EditItemConfirmSnackbar from "./Snackbars/EditItemConfirmSnackbar";
//Others
import axios from "axios";
import PropTypes from "prop-types";
import history from "../../../history";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 550
        }
    }
};

const CargoType = ["Import", "Export", "Containers (MTs)"];
const VoyageType = ["Overseas", "Coastal"];
const FlagType = ["Indian Flag", "Foreign Flag"];

class EditImportExport extends Component {
    state = {
        open: false,
        openNewItemAddedConfirmSnackbar: false,
        cargo_type: this.props.itemToEdit[0].cargo_type,
        commodity_objectId: this.props.itemToEdit[0].commodity_objectId,
        qty_in_mts: this.props.itemToEdit[0].qty_in_mts,
        year: this.props.itemToEdit[0].year,
        month: this.props.itemToEdit[0].month,
        no_of_vessels_per_month: this.props.itemToEdit[0].no_of_vessels_per_month,
        voyage_type: this.props.itemToEdit[0].voyage_type,
        flag_type: this.props.itemToEdit[0].flag_type,
        arrowRef: null,
        //Error Message handler
        errorQtyInMts: "",
        errorImportExportYear: "",
        errorImportExportMonth: "",
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node
        });
    };

    handleYearChange = date => {
        this.setState({
            year: date
        });
    };

    handleMonthChange = date => {
        this.setState({
            month: date
        });
    };

    handleToggle = () => {
        this.setState({
            open: !this.state.open
        });
    };

    handleFabOpen = () => {
        this.setState({open: true});
    };

    closeNewItemConfirmSnackbar = () => {
        this.setState({openNewItemAddedConfirmSnackbar: false});
        this.props.unSelectItems();
    };

    handleEditFormSubmit = () => {
        const {editItemToParentState, itemToEdit} = this.props;
        const {
            cargo_type,
            commodity_objectId,
            qty_in_mts,
            year,
            month,
            no_of_vessels_per_month,
            voyage_type,
            flag_type,
        } = this.state;

        qty_in_mts !== "" ? this.setState({errorQtyInMts: ""}) : this.setState({errorQtyInMts: "Enter Quantity"});

        (year === null && month === null) ?
            this.setState({
                errorImportExportYear: "Select at least Year or Year & Month",
                errorImportExportMonth: "Select at least Year or Year & Month",
            })
            :
            (year !== null && month !== null) ?
                this.setState({
                    errorImportExportYear: "Select only Year or Year & Month",
                    errorImportExportMonth: "Select only Year or Year & Month"
                }) :
                this.setState({errorImportExportYear: "", errorImportExportMonth: "",});

        if (qty_in_mts !== "" && ((year !== null && month === null) || (year === null && month !== null))) {
            axios
                .put(`/api/importsExports/${itemToEdit[0]._id}`, {
                    cargo_type,
                    commodity_objectId,
                    qty_in_mts,
                    year,
                    month,
                    no_of_vessels_per_month,
                    voyage_type,
                    flag_type,
                })
                .then((res) => {
                    editItemToParentState(res.data);
                    this.setState(
                        {
                            open: false,
                            openNewItemAddedConfirmSnackbar: true,
                            cargo_type: itemToEdit[0].cargo_type,
                            commodity_objectId: itemToEdit[0].commodity_objectId,
                            qty_in_mts: itemToEdit[0].qty_in_mts,
                            year: itemToEdit[0].year,
                            month: itemToEdit[0].month,
                            no_of_vessels_per_month: itemToEdit[0].no_of_vessels_per_month,
                            voyage_type: itemToEdit[0].voyage_type,
                            flag_type: itemToEdit[0].flag_type,
                            //Error Message handler
                            errorQtyInMts: "",
                            errorImportExportYear: "",
                            errorImportExportMonth: "",
                        },
                        () => {
                            history.push("/dashboard/importsExports");
                        }
                    );
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        history.push("/login");
                        alert("Please Login, session expired");
                    } else {
                        alert("Oops something wrong happened, please try again");
                    }
                });
        }
    };

    handleCancel = () => {
        this.setState({
            open: !this.state.open,
            cargo_type: this.props.itemToEdit[0].cargo_type,
            commodity_objectId: this.props.itemToEdit[0].commodity_objectId,
            qty_in_mts: this.props.itemToEdit[0].qty_in_mts,
            year: this.props.itemToEdit[0].year,
            month: this.props.itemToEdit[0].month,
            no_of_vessels_per_month: this.props.itemToEdit[0].no_of_vessels_per_month,
            voyage_type: this.props.itemToEdit[0].voyage_type,
            flag_type: this.props.itemToEdit[0].flag_type,
            //Error Message handler
            errorQtyInMts: "",
            errorImportExportYear: "",
            errorImportExportMonth: "",
        });
    };

    handleEnterEscapeKeyPress = e => {
        if (e.key === "Enter") {
            this.handleEditFormSubmit();
        } else if (e.key === "Escape") {
            this.handleCancel();
        }
    };

    render() {
        const {classes, themeType, allCommoditiesForSiblingCommunication} = this.props;
        const {
            cargo_type,
            commodity_objectId,
            qty_in_mts,
            year,
            month,
            no_of_vessels_per_month,
            voyage_type,
            flag_type,
            //Error Message handler
            errorQtyInMts,
            errorImportExportYear,
            errorImportExportMonth,
        } = this.state;

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div>
                    <EditIcon
                        onClick={this.handleFabOpen}
                        className={classes.tableToolbarIcon}
                    />
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleToggle}
                        aria-labelledby="form-dialog-title"
                        fullWidth={true}
                        maxWidth={"sm"}
                        variant="contained"
                        PaperProps={{
                            classes: {
                                root: classes.paper
                            }
                        }}
                        onKeyDown={this.handleEnterEscapeKeyPress}
                    >
                        <DialogTitle
                            id="form-dialog-title"
                            disableTypography
                            className={themeType ? classes.dialogTitleEditDark : classes.dialogTitleEditLight}
                        >
                            <div className={classes.displayFlexDialogTitle}>
                                <Typography variant="h5" className={classes.dialogTitleHeading}>Edit Import
                                    Record</Typography>
                                <IconButton
                                    onClick={this.handleToggle}
                                    style={{float: "right"}}
                                    className={classes.button}
                                >
                                    <CancelIcon/>
                                </IconButton>
                            </div>
                        </DialogTitle>

                        <DialogContent required>

                            <TextField
                                select
                                label="Cargo Type"
                                classes={{
                                    root: classes.space
                                }}
                                value={cargo_type}
                                onChange={e => {
                                    this.setState({
                                        cargo_type: e.target.value
                                    })
                                }}
                                menuprops={MenuProps}
                            >
                                {CargoType.map(item => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                select
                                label="Select Commodity"
                                classes={{
                                    root: classes.space
                                }}
                                value={commodity_objectId}
                                onChange={e => {
                                    this.setState({
                                        commodity_objectId: e.target.value
                                    })
                                }}
                                MenuProps={MenuProps}
                            >
                                {allCommoditiesForSiblingCommunication.map(item => (
                                    <MenuItem key={item.name} value={item._id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                required
                                classes={{
                                    root: classes.space
                                }}
                                value={qty_in_mts}
                                onChange={e =>
                                    this.setState({
                                        qty_in_mts: e.target.value
                                    })
                                }
                                label="Quantity (M. Tons)"
                                type="number"
                                inputProps={{min: "0"}}
                                error={errorQtyInMts.length !== 0}
                                helperText={errorQtyInMts}
                            />

                            <DatePicker
                                autoOk
                                clearable
                                classes={{
                                    root: classes.space
                                }}
                                views={["year"]}
                                label="Year"
                                value={year}
                                onChange={this.handleYearChange}
                                error={errorImportExportYear.length !== 0}
                                helperText={errorImportExportYear}
                            />

                            <DatePicker
                                autoOk
                                clearable
                                classes={{
                                    root: classes.space
                                }}
                                views={["year", "month"]}
                                label="Year & Month"
                                value={month}
                                onChange={this.handleMonthChange}
                                error={errorImportExportMonth.length !== 0}
                                helperText={errorImportExportMonth}
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={no_of_vessels_per_month}
                                onChange={e =>
                                    this.setState({
                                        no_of_vessels_per_month: e.target.value
                                    })
                                }
                                label="No of Vessels per Month"
                                type="number"
                                inputProps={{min: "0"}}
                            />

                            <TextField
                                select
                                label="Voyage Type"
                                classes={{
                                    root: classes.space
                                }}
                                value={voyage_type}
                                onChange={e => {
                                    this.setState({
                                        voyage_type: e.target.value
                                    })
                                }}
                                input={<Input id="select-multiple"/>}
                                menuprops={MenuProps}
                            >
                                {VoyageType.map(item => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                select
                                label="Flag Type"
                                classes={{
                                    root: classes.space
                                }}
                                value={flag_type}
                                onChange={e => {
                                    this.setState({
                                        flag_type: e.target.value
                                    })
                                }}
                                input={<Input id="select-multiple"/>}
                                menuprops={MenuProps}
                            >
                                {FlagType.map(item => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </DialogContent>

                        <DialogActions className={classes.dialogActions}>
                            <div className={classes.displayFlexDialogActions}>
                                <Button
                                    onClick={this.handleCancel}
                                    variant="contained"
                                    size="large"
                                    classes={{
                                        root: classes.spaceDialogAction
                                    }}
                                    style={{backgroundColor: "#ee0053"}}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    onClick={this.handleEditFormSubmit}
                                    classes={{
                                        root: classes.spaceDialogAction
                                    }}
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                >
                                    Update
                                </Button>
                            </div>
                        </DialogActions>

                    </Dialog>

                    <EditItemConfirmSnackbar
                        openNewItemAddedConfirmSnackbar={
                            this.state.openNewItemAddedConfirmSnackbar
                        }
                        closeNewItemConfirmSnackbar={this.closeNewItemConfirmSnackbar}
                    />
                </div>
            </MuiPickersUtilsProvider>
        );
    }
}

EditImportExport.propTypes = {
    classes: PropTypes.object.isRequired
};

const combinedStyles = combineStyles(styles, toolbarStyles);

export default withStyles(combinedStyles, {withTheme: true})(EditImportExport);
