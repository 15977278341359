import React, {Component} from "react";
import axios from "axios";
import PropTypes from "prop-types";
import history from "../../../history";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {withStyles} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {styles} from "../commonStyles/AddNewItemStyles";
import toolbarStyles from "../commonStyles/toolbarStyles";
import combineStyles from "../commonStyles/combineStyles";
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import EmptyFieldSnackBar from "./Snackbars/EmptyFieldSnackBar";
import EditItemConfirmSnackbar from "./Snackbars/EditItemConfirmSnackbar";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import Typography from "@material-ui/core/Typography";

class EditOthers extends Component {

    state = {
        open: false,
        openItemEditedConfirmSnackbar: false,
        openEmptyTextFieldSnackbar: false,
        others_year: this.props.itemToEdit[0].others_year,
        others_month: this.props.itemToEdit[0].others_month,
        coastal_in: this.props.itemToEdit[0].coastal_in,
        coastal_out: this.props.itemToEdit[0].coastal_out,
        others: this.props.itemToEdit[0].others,
        container_traffic_handled: this.props.itemToEdit[0].container_traffic_handled,
        percentage_utilization_of_traffic_handling_capacity_of_the_port: this.props.itemToEdit[0].percentage_utilization_of_traffic_handling_capacity_of_the_port,
        average_vehicle_turn_around_time: this.props.itemToEdit[0].average_vehicle_turn_around_time,
        bulk_and_handymax: this.props.itemToEdit[0].bulk_and_handymax,
        bulk_and_panamax: this.props.itemToEdit[0].bulk_and_panamax,
        bulk_and_cape_size: this.props.itemToEdit[0].bulk_and_cape_size,
        containers: this.props.itemToEdit[0].containers,
        tankers: this.props.itemToEdit[0].tankers,
        average_pre_berth_delays: this.props.itemToEdit[0].average_pre_berth_delays,
        total_cargo_evacuation_from_port: this.props.itemToEdit[0].total_cargo_evacuation_from_port,
        by_rail: this.props.itemToEdit[0].by_rail,
        by_road: this.props.itemToEdit[0].by_road,
        by_conveyor: this.props.itemToEdit[0].by_conveyor,
        by_others: this.props.itemToEdit[0].by_others,
        specific_information: this.props.itemToEdit[0].specific_information,
        //Error Message handler
        errorOthersYear: "",
        errorOthersMonth: "",
        arrowRef: null
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node
        });
    };

    handleYearChange = date => {
        this.setState({
            others_year: date
        });
    };

    handleMonthChange = date => {
        this.setState({
            others_month: date
        });
    };

    handleToggle = () => {
        this.setState({
            open: !this.state.open
        });
    };

    handleFabOpen = () => {
        this.setState({open: true});
    };

    closeItemEditedConfirmSnackbar = () => {
        this.setState({openItemEditedConfirmSnackbar: false});
    };

    closeEmptyFieldSnackbar = () => {
        this.setState({openEmptyTextFieldSnackbar: false}, () => {
        });
    };

    handleEditFormSubmit = () => {
        const {editItemToParentState, itemToEdit} = this.props;
        const {
            others_year,
            others_month,
            coastal_in,
            coastal_out,
            others,
            container_traffic_handled,
            percentage_utilization_of_traffic_handling_capacity_of_the_port,
            average_vehicle_turn_around_time,
            bulk_and_handymax,
            bulk_and_panamax,
            bulk_and_cape_size,
            containers,
            tankers,
            average_pre_berth_delays,
            total_cargo_evacuation_from_port,
            by_rail,
            by_road,
            by_conveyor,
            by_others,
            specific_information
        } = this.state;

        others_year === null && others_month === null ?
            this.setState({
                errorOthersYear: "Select at least Year or Year & Month",
                errorOthersMonth: "Select at least Year or Year & Month"
            })
            :
            others_year !== null && others_month !== null ?
                this.setState({
                    errorOthersYear: "Select only Year or Year & Month",
                    errorOthersMonth: "Select only Year or Year & Month"
                }) :
                this.setState({errorOthersYear: "", errorOthersMonth: ""});

        if ((others_year !== null && others_month === null) || (others_year === null && others_month !== null)) {
            axios
                .put(`/api/others/${itemToEdit[0]._id}`, {
                    others_year,
                    others_month,
                    coastal_in,
                    coastal_out,
                    others,
                    container_traffic_handled,
                    percentage_utilization_of_traffic_handling_capacity_of_the_port,
                    average_vehicle_turn_around_time,
                    bulk_and_handymax,
                    bulk_and_panamax,
                    bulk_and_cape_size,
                    containers,
                    tankers,
                    average_pre_berth_delays,
                    total_cargo_evacuation_from_port,
                    by_rail,
                    by_road,
                    by_conveyor,
                    by_others,
                    specific_information
                })
                .then((res) => {
                    editItemToParentState(res.data);
                    this.setState(
                        {
                            open: false,
                            openItemEditedConfirmSnackbar: true,
                            others_year: itemToEdit[0].others_year,
                            others_month: itemToEdit[0].others_month,
                            coastal_in: itemToEdit[0].coastal_in,
                            coastal_out: itemToEdit[0].coastal_out,
                            others: itemToEdit[0].others,
                            container_traffic_handled: itemToEdit[0].container_traffic_handled,
                            percentage_utilization_of_traffic_handling_capacity_of_the_port: itemToEdit[0].percentage_utilization_of_traffic_handling_capacity_of_the_port,
                            average_vehicle_turn_around_time: itemToEdit[0].average_vehicle_turn_around_time,
                            bulk_and_handymax: itemToEdit[0].bulk_and_handymax,
                            bulk_and_panamax: itemToEdit[0].bulk_and_panamax,
                            bulk_and_cape_size: itemToEdit[0].bulk_and_cape_size,
                            containers: itemToEdit[0].containers,
                            tankers: itemToEdit[0].tankers,
                            average_pre_berth_delays: itemToEdit[0].average_pre_berth_delays,
                            total_cargo_evacuation_from_port: itemToEdit[0].total_cargo_evacuation_from_port,
                            by_rail: itemToEdit[0].by_rail,
                            by_road: itemToEdit[0].by_road,
                            by_conveyor: itemToEdit[0].by_conveyor,
                            by_others: itemToEdit[0].by_others,
                            specific_information: itemToEdit[0].specific_information,
                            //Error Message handler
                            errorOthersYear: "",
                            errorOthersMonth: "",
                        },
                        () => {
                            history.push("/dashboard/others");
                        }
                    );
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        history.push("/login");
                        alert("Please Login, session expired");
                    } else {
                        alert("Oops something wrong happened, please try again");
                    }
                });
        } else {
            this.setState({openEmptyTextFieldSnackbar: true});
        }
    };

    handleCancel = () => {
        this.setState({
            open: false,
            others_year: this.props.itemToEdit[0].others_year,
            others_month: this.props.itemToEdit[0].others_month,
            coastal_in: this.props.itemToEdit[0].coastal_in,
            coastal_out: this.props.itemToEdit[0].coastal_out,
            others: this.props.itemToEdit[0].others,
            container_traffic_handled: this.props.itemToEdit[0].container_traffic_handled,
            percentage_utilization_of_traffic_handling_capacity_of_the_port: this.props.itemToEdit[0].percentage_utilization_of_traffic_handling_capacity_of_the_port,
            average_vehicle_turn_around_time: this.props.itemToEdit[0].average_vehicle_turn_around_time,
            bulk_and_handymax: this.props.itemToEdit[0].bulk_and_handymax,
            bulk_and_panamax: this.props.itemToEdit[0].bulk_and_panamax,
            bulk_and_cape_size: this.props.itemToEdit[0].bulk_and_cape_size,
            containers: this.props.itemToEdit[0].containers,
            tankers: this.props.itemToEdit[0].tankers,
            average_pre_berth_delays: this.props.itemToEdit[0].average_pre_berth_delays,
            total_cargo_evacuation_from_port: this.props.itemToEdit[0].total_cargo_evacuation_from_port,
            by_rail: this.props.itemToEdit[0].by_rail,
            by_road: this.props.itemToEdit[0].by_road,
            by_conveyor: this.props.itemToEdit[0].by_conveyor,
            by_others: this.props.itemToEdit[0].by_others,
            specific_information: this.props.itemToEdit[0].specific_information,
            //Error Message handler
            errorOthersYear: "",
            errorOthersMonth: "",
        });
    };

    handleEnterEscapeKeyPress = e => {
        if (e.key === "Enter") {
            this.handleEditFormSubmit();
        } else if (e.key === "Escape") {
            this.handleCancel();
        }
    };

    render() {
        const {classes, themeType} = this.props;
        const {
            others_year,
            others_month,
            coastal_in,
            coastal_out,
            others,
            container_traffic_handled,
            percentage_utilization_of_traffic_handling_capacity_of_the_port,
            average_vehicle_turn_around_time,
            bulk_and_handymax,
            bulk_and_panamax,
            bulk_and_cape_size,
            containers,
            tankers,
            average_pre_berth_delays,
            total_cargo_evacuation_from_port,
            by_rail,
            by_road,
            by_conveyor,
            by_others,
            specific_information,
            //Error Message handler
            errorOthersYear,
            errorOthersMonth,
        } = this.state;

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div>
                    <EditIcon
                        onClick={this.handleFabOpen}
                        className={classes.tableToolbarIcon}
                    />
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleToggle}
                        aria-labelledby="form-dialog-title"
                        fullWidth={true}
                        maxWidth={"sm"}
                        variant="contained"
                        PaperProps={{
                            classes: {
                                root: classes.paper
                            }
                        }}
                        onKeyDown={this.handleEnterEscapeKeyPress}
                    >
                        <DialogTitle
                            id="form-dialog-title"
                            disableTypography
                            className={themeType ? classes.dialogTitleEditDark : classes.dialogTitleEditLight}
                        >
                            <div className={classes.displayFlexDialogTitle}>
                                <Typography variant="h5" className={classes.dialogTitleHeading}>Edit Others
                                    Record</Typography>
                                <IconButton
                                    onClick={this.handleToggle}
                                    style={{float: "right"}}
                                    className={classes.button}
                                >
                                    <CancelIcon/>
                                </IconButton>
                            </div>
                        </DialogTitle>

                        <DialogContent required>

                            <div
                                style={{
                                    display: "flex",
                                    marginTop: "16px"
                                }}
                            >
                                <div style={{display: "flex", margin: "auto", width: "100%"}}>
                                    <DatePicker
                                        autoOk
                                        clearable
                                        views={["year"]}
                                        label="Others Year"
                                        value={others_year}
                                        onChange={this.handleYearChange}
                                        error={errorOthersYear.length !== 0}
                                        helperText={errorOthersYear}
                                        style={{width: "100%"}}
                                    />
                                </div>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    marginTop: "16px"
                                }}
                            >
                                <div style={{display: "flex", margin: "auto", width: "100%"}}>
                                    <DatePicker
                                        autoOk
                                        clearable
                                        views={["year", "month"]}
                                        label="Others Year & Month"
                                        value={others_month}
                                        onChange={this.handleMonthChange}
                                        error={errorOthersMonth.length !== 0}
                                        helperText={errorOthersMonth}
                                        style={{width: "100%"}}
                                    />
                                </div>
                            </div>

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={coastal_in}
                                onChange={e => {
                                    this.setState({
                                        coastal_in: e.target.value
                                    })
                                }}
                                label="Coastal In (MT)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={coastal_out}
                                onChange={e => {
                                    this.setState({
                                        coastal_out: e.target.value
                                    })
                                }}
                                label="Coastal Out (MT)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={others}
                                onChange={e => {
                                    this.setState({
                                        others: e.target.value
                                    })
                                }}
                                label="Others"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={container_traffic_handled}
                                onChange={e => {
                                    this.setState({
                                        container_traffic_handled: e.target.value
                                    })
                                }}
                                label="Container Traffic Handled (TEUs)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={percentage_utilization_of_traffic_handling_capacity_of_the_port}
                                onChange={e => {
                                    this.setState({
                                        percentage_utilization_of_traffic_handling_capacity_of_the_port: e.target.value
                                    })
                                }}
                                label="Percentage utilization of Traffic handling capacity of the port (%)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={average_vehicle_turn_around_time}
                                onChange={e => {
                                    this.setState({
                                        average_vehicle_turn_around_time: e.target.value
                                    })
                                }}
                                label="Average vehicle turn around time(TAT) (Hrs)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={bulk_and_handymax}
                                onChange={e => {
                                    this.setState({
                                        bulk_and_handymax: e.target.value
                                    })
                                }}
                                label="Bulk & Handymax (Hrs)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={bulk_and_panamax}
                                onChange={e => {
                                    this.setState({
                                        bulk_and_panamax: e.target.value
                                    })
                                }}
                                label="Bulk & Panamax (Hrs)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={bulk_and_cape_size}
                                onChange={e => {
                                    this.setState({
                                        bulk_and_cape_size: e.target.value
                                    })
                                }}
                                label="Bulk & cape size (Hrs)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={containers}
                                onChange={e => {
                                    this.setState({
                                        containers: e.target.value
                                    })
                                }}
                                label="Containers (Hrs)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={tankers}
                                onChange={e => {
                                    this.setState({
                                        tankers: e.target.value
                                    })
                                }}
                                label="Tankers (Hrs)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={average_pre_berth_delays}
                                onChange={e => {
                                    this.setState({
                                        average_pre_berth_delays: e.target.value
                                    })
                                }}
                                label="Average pre berth delays (Hrs)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={total_cargo_evacuation_from_port}
                                onChange={e => {
                                    this.setState({
                                        total_cargo_evacuation_from_port: e.target.value
                                    })
                                }}
                                label="Total cargo evacuation from port (MT)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={by_rail}
                                onChange={e => {
                                    this.setState({
                                        by_rail: e.target.value
                                    })
                                }}
                                label="By Rail (MT)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={by_road}
                                onChange={e => {
                                    this.setState({
                                        by_road: e.target.value
                                    })
                                }}
                                label="By Road (MT)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={by_conveyor}
                                onChange={e => {
                                    this.setState({
                                        by_conveyor: e.target.value
                                    })
                                }}
                                label="By Conveyor (MT)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={by_others}
                                onChange={e => {
                                    this.setState({
                                        by_others: e.target.value
                                    })
                                }}
                                label="By Others (MT)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={specific_information}
                                onChange={e => {
                                    this.setState({
                                        specific_information: e.target.value
                                    })
                                }}
                                label="Any other specific information to be shared"
                                type="string"
                                fullWidth
                            />

                        </DialogContent>

                        <DialogActions className={classes.dialogActions}>
                            <div className={classes.displayFlexDialogActions}>
                                <Button
                                    onClick={this.handleCancel}
                                    variant="contained"
                                    size="large"
                                    classes={{
                                        root: classes.spaceDialogAction
                                    }}
                                    style={{backgroundColor: "#ee0053"}}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={this.handleEditFormSubmit}
                                    classes={{
                                        root: classes.spaceDialogAction
                                    }}
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                >
                                    Update
                                </Button>
                            </div>
                        </DialogActions>

                    </Dialog>

                    <EditItemConfirmSnackbar
                        openItemEditedConfirmSnackbar={this.state.openItemEditedConfirmSnackbar}
                        closeItemEditedConfirmSnackbar={this.closeItemEditedConfirmSnackbar}
                    />

                    <EmptyFieldSnackBar
                        openEmptyTextFieldSnackbar={this.state.openEmptyTextFieldSnackbar}
                        closeEmptyFieldSnackbar={this.closeEmptyFieldSnackbar}
                    />

                </div>
            </MuiPickersUtilsProvider>
        );
    }
}

EditOthers.propTypes = {
    classes: PropTypes.object.isRequired
};

const combinedStyles = combineStyles(styles, toolbarStyles);

export default withStyles(combinedStyles, {withTheme: true})(EditOthers);
