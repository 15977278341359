import React, {Component} from "react";
//Material UI
import {withStyles} from "@material-ui/core";
import styles from "./analyticsStyles.js";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {MenuItem} from "material-ui/Menu";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
//Components
import BarChart from "./BarChart";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
//Others
import axios from "axios";
import PropTypes from "prop-types";
import setAxiosAuthheaders from "../UtilFunctions/setAxiosAuthheaders";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import _ from "lodash";
import html2canvas from "html2canvas";
import $ from "jquery";
import JsPdf from "jspdf";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 550
        }
    }
};
const moment = require("moment");

const cargoTypes = ["EXPORT", "IMPORT", "BOTH"];

class AnalyticsPort extends Component {
    state = {
        chartCommodityObjectId: "",
        portListData: [],
        portName: "",
        portNameText: "",
        year: null,
        cargoType: "BOTH",
        scroll: 'paper',

        chartPortChecked: [],
        chartDataSet: [],

        tablePortChecked: [],
        tableCommodityListOpen: false,
        tableCommodityChecked: [],
        tableFromMonth: null,
        tableToMonth: null,
        tableMonthsArray: [],
        tablePortsArray: [],
        tableExportsArray: [],
        tableImportsArray: [],
        tableExportsCommodityTotal: [],
        tableImportsCommodityTotal: [],
        tableExportsSum: 0,
        tableImportsSum: 0,
        tableExportsTotal: [],
        tableImportsTotal: [],

        consolidationPortChecked: [],
        consolidationYearListOpen: false,
        consolidationYearChecked: [],
        consolidationArrayOfYears: [],
        consolidationFromMonth: null,
        consolidationToMonth: null,
        consolidatedTrafficDataSet: [],
        consolidatedTrafficTotal: [],

        cargoTrafficPortChecked: [],
        cargoTrafficFromMonth: null,
        cargoTrafficToMonth: null,
        cargoTrafficDataSet: [],
        cargoTrafficTotal: [],
    };

    componentWillMount() {
        setAxiosAuthheaders();
    }

    componentDidMount() {
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).userType === "port-operator"
            ? this.setState({
                portNameText: JSON.parse(localStorage.getItem("user")).port,
                portName: JSON.parse(localStorage.getItem("user")).port_id
            })
            : axios.get("/api/onBoardPort/").then(res => {
                this.setState({
                    portListData: res.data
                });
            });

        this.generateArrayOfYears();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.state.year !== prevState.year ||
            this.state.chartPortChecked !== prevState.chartPortChecked ||
            this.state.chartCommodityObjectId !== prevState.chartCommodityObjectId
        ) {
            if (this.state.chartPortChecked.length !== 0 && this.state.year !== null && this.state.chartCommodityObjectId) {
                this.monthlyImportsAndExportsChart();
            } else {
                this.setState({
                    chartDataSet: []
                })
            }
        }

        if (
            this.state.tableToMonth !== prevState.tableToMonth ||
            this.state.tableFromMonth !== prevState.tableFromMonth ||
            this.state.cargoType !== prevState.cargoType ||
            this.state.tablePortChecked !== prevState.tablePortChecked ||
            this.state.tableCommodityChecked !== prevState.tableCommodityChecked
        ) {
            if (this.state.tablePortChecked.length !== 0 && this.state.tableToMonth !== null && this.state.tableFromMonth !== null) {
                this.monthlyImportsAndExportsTable();
            } else {
                this.setState({
                    tableMonthsArray: [],
                    tablePortsArray: [],
                    tableExportsArray: [],
                    tableImportsArray: [],
                    tableExportsCommodityTotal: [],
                    tableImportsCommodityTotal: [],
                    tableExportsSum: 0,
                    tableImportsSum: 0,
                    tableExportsTotal: [],
                    tableImportsTotal: []
                })
            }
        }

        if (
            this.state.consolidationToMonth !== prevState.consolidationToMonth ||
            this.state.consolidationFromMonth !== prevState.consolidationFromMonth ||
            this.state.consolidationPortChecked !== prevState.consolidationPortChecked ||
            this.state.consolidationYearChecked !== prevState.consolidationYearChecked
        ) {
            if (this.state.consolidationFromMonth !== null && this.state.consolidationToMonth !== null && this.state.consolidationPortChecked.length !== 0 && this.state.consolidationYearChecked.length !== 0) {
                this.nmhOneCargoTraffic();
            } else {
                this.setState({
                    consolidatedTrafficDataSet: [],
                    consolidatedTrafficTotal: [],
                })
            }
        }

        if (
            this.state.cargoTrafficToMonth !== prevState.cargoTrafficToMonth ||
            this.state.cargoTrafficFromMonth !== prevState.cargoTrafficFromMonth ||
            this.state.cargoTrafficPortChecked !== prevState.cargoTrafficPortChecked
        ) {
            if (this.state.cargoTrafficFromMonth !== null && this.state.cargoTrafficToMonth !== null && this.state.cargoTrafficPortChecked.length !== 0) {
                this.nmhTwoCargoTraffic();
            } else {
                this.setState({
                    cargoTrafficDataSet: [],
                    cargoTrafficTotal: [],
                })
            }
        }
    }

    generateArrayOfYears = () => {
        let max = new Date().getFullYear();
        let min = "1999";
        let years = [];

        for (let i = max; i >= min; i--) {
            years.push(i)
        }
        this.setState({
            consolidationArrayOfYears: years
        })
    };


    monthlyImportsAndExportsChart = () => {
        let temp = new Date(this.state.year).getFullYear();
        let start = moment(new Date(temp, 3, 1)).toISOString();
        let end = moment(new Date(temp + 1, 2, 30)).toISOString();

        let tempDataSet = [];

        axios.get("/api/analytics/getChartMonthlyImportsExports", {
            params: {
                start,
                end,
                ports: this.state.chartPortChecked,
                commodity: this.state.chartCommodityObjectId
            }
        }).then(res => {
            res.data.map(each => {

                let importsArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                let exportsArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

                let imports = {
                    barPercentage: 0.9,
                    categoryPercentage: 0.9,
                    label: 'Imports',
                    backgroundColor: 'rgba(243, 82, 58, .3)',
                    hoverBackgroundColor: '#f56954',
                    hoverBorderColor: '#f3523a',
                    data: [],
                    stack: ''
                };

                let exports = {
                    barPercentage: 0.9,
                    categoryPercentage: 0.9,
                    label: 'Exports',
                    backgroundColor: 'rgba(42, 180, 192, .3)',
                    hoverBackgroundColor: '#2ab4c0',
                    hoverBorderColor: '#2ab4c0',
                    data: [],
                    stack: ''
                };

                each.data.map(type => {
                    if (type.cargo_type === "Import") {
                        if (moment(type.month).format('M') === "1") {
                            importsArray[9] = type.qty_in_mts
                        } else if (moment(type.month).format('M') === "2") {
                            importsArray[10] = type.qty_in_mts
                        } else if (moment(type.month).format('M') === "3") {
                            importsArray[11] = type.qty_in_mts
                        } else {
                            importsArray[moment(type.month).format('M') - 4] = type.qty_in_mts
                        }
                    } else if (type.cargo_type === "Export") {
                        if (moment(type.month).format('M') === "1") {
                            exportsArray[9] = type.qty_in_mts
                        } else if (moment(type.month).format('M') === "2") {
                            exportsArray[10] = type.qty_in_mts
                        } else if (moment(type.month).format('M') === "3") {
                            exportsArray[11] = type.qty_in_mts
                        } else {
                            exportsArray[moment(type.month).format('M') - 4] = type.qty_in_mts
                        }
                    }
                });

                imports.data = importsArray;
                imports.stack = each._id.port;
                exports.data = exportsArray;
                exports.stack = each._id.port;
                tempDataSet.push(imports);
                tempDataSet.push(exports);
            });
            this.setState({
                chartDataSet: tempDataSet
            })
        })
    };

    handleYearChange = date => {
        this.setState({
            year: date
        });
    };

    handleChartPortToggle = (value) => () => {
        const currentIndex = this.state.chartPortChecked.indexOf(value);
        const newChecked = [...this.state.chartPortChecked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            chartPortChecked: newChecked
        });
    };

    handleChartCommodityToggle = e => {
        if (e.target.value !== this.state.chartCommodityObjectId) {
            this.setState({
                chartCommodityObjectId: e.target.value
            });
        }
    };


    monthlyImportsAndExportsTable = () => {
        let start = moment(this.state.tableFromMonth).startOf("month").toISOString();
        let end = moment(this.state.tableToMonth).endOf("month").toISOString();

        let exportsTempDataSet = [];
        let importsTempDataSet = [];

        axios.get("/api/analytics/getTableMonthlyImportsExports", {
            params: {
                start,
                end,
                ports: this.state.tablePortChecked,
                commodity: this.state.tableCommodityChecked
            }
        }).then(res => {
            let exportsCommodityArray = [];
            let importsCommodityArray = [];
            let exportsCommodityTotal = [];
            let importsCommodityTotal = [];
            let exportsSum = 0;
            let importsSum = 0;
            let exportsTotal = [];
            let importsTotal = [];
            let monthsArray = [];
            let portsArray = [];
            let exportsCount = 0;
            let importsCount = 0;

            this.state.portListData.map(port => {
                this.state.tablePortChecked.map(checked => {
                    if (port._id === checked) {
                        portsArray.push(port.port_name)
                    }
                })
            });

            res.data.map(each => {
                monthsArray.push(moment(each._id.month, 'MM').format('MMM') + "-" + each._id.year);
                each.data.map(item => {
                    if (item.cargo_type === "Export") {
                        exportsCommodityArray.push(item.commodity);
                        exportsCommodityTotal.push("-");
                    } else if (item.cargo_type === "Import") {
                        importsCommodityArray.push(item.commodity);
                        importsCommodityTotal.push("-");
                    }
                });
            });

            let uniqueExportsCommodityArray = [...new Set(exportsCommodityArray)];
            let uniqueImportsCommodityArray = [...new Set(importsCommodityArray)];

            uniqueExportsCommodityArray.map((cargo, index) => {
                let count = 0;
                let commodityTotal = 0;
                let tempCargo = {
                    name: cargo,
                    values: []
                };

                for (let i = 0; i < monthsArray.length * portsArray.length; i++) {
                    tempCargo.values.push("-");
                }

                monthsArray.map(month => {
                    res.data.map(each => {
                        if (moment(each._id.month, 'MM').format('MMM') + "-" + each._id.year === month) {
                            portsArray.map(port => {
                                each.data.map(data => {
                                    if (data.cargo_type === "Export" && data.port_name === port && data.commodity === cargo) {
                                        tempCargo.values[count] = data.qty_in_mts;
                                        commodityTotal = commodityTotal + data.qty_in_mts;
                                    }
                                });
                                count++;
                            })
                        }
                    })
                });

                exportsTempDataSet.push(tempCargo);
                exportsCommodityTotal[index] = commodityTotal;
            });

            monthsArray.map(() => {
                portsArray.map(() => {
                    let total = 0;
                    exportsTempDataSet.map(each => {
                        if (each.values[exportsCount] !== "-") {
                            total = total + Number(each.values[exportsCount]);
                        }
                    });
                    exportsCount++;
                    exportsTotal.push(total);
                    exportsSum = exportsSum + total;
                });
            });

            uniqueImportsCommodityArray.map((cargo, index) => {
                let count = 0;
                let commodityTotal = 0;
                let tempCargo = {
                    name: cargo,
                    values: []
                };

                for (let i = 0; i < monthsArray.length * portsArray.length; i++) {
                    tempCargo.values.push("-");
                }

                monthsArray.map(month => {
                    res.data.map(each => {
                        if (moment(each._id.month, 'MM').format('MMM') + "-" + each._id.year === month) {
                            portsArray.map(port => {
                                each.data.map(data => {
                                    if (data.cargo_type === "Import" && data.port_name === port && data.commodity === cargo) {
                                        tempCargo.values[count] = data.qty_in_mts;
                                        commodityTotal = commodityTotal + data.qty_in_mts;
                                    }
                                });
                                count++;
                            })
                        }
                    })
                });

                importsTempDataSet.push(tempCargo);
                importsCommodityTotal[index] = commodityTotal;
            });

            monthsArray.map(() => {
                portsArray.map(() => {
                    let total = 0;
                    importsTempDataSet.map(each => {
                        if (each.values[importsCount] !== "-") {
                            total = total + Number(each.values[importsCount]);
                        }
                    });
                    importsCount++;
                    importsTotal.push(total);
                    importsSum = importsSum + total;
                });
            });

            this.setState({
                tableMonthsArray: monthsArray,
                tablePortsArray: portsArray,
                tableExportsArray: exportsTempDataSet,
                tableImportsArray: importsTempDataSet,
                tableExportsCommodityTotal: exportsCommodityTotal,
                tableImportsCommodityTotal: importsCommodityTotal,
                tableExportsSum: exportsSum,
                tableImportsSum: importsSum,
                tableExportsTotal: exportsTotal,
                tableImportsTotal: importsTotal
            })
        })
    };

    handleTablePortToggle = (value) => () => {
        const currentIndex = this.state.tablePortChecked.indexOf(value);
        const newChecked = [...this.state.tablePortChecked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            tablePortChecked: newChecked
        });
    };

    handleTableFromMonthChange = date => {
        this.setState({
            tableFromMonth: date
        });
    };

    handleTableToMonthChange = date => {
        this.setState({
            tableToMonth: date
        });
    };

    handleCargoTypeChange = e => {
        this.setState({
            cargoType: e.target.value
        });
    };

    handleTableCommodityToggle = (value) => () => {
        const currentIndex = this.state.tableCommodityChecked.indexOf(value);
        const newChecked = [...this.state.tableCommodityChecked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            tableCommodityChecked: newChecked
        });
    };

    handleTableCommodityClickOpen = () => {
        this.setState({
            tableCommodityListOpen: true
        })
    };

    handleTableCommodityOkay = () => {
        this.setState({
            tableCommodityListOpen: false
        })
    };

    handleTableCommodityCancel = () => {
        this.setState({
            tableCommodityListOpen: false,
            tableCommodityChecked: []
        })
    };


    nmhOneCargoTraffic = () => {
        let startDate;
        let endDate;
        let datesList = [];
        let commodityList = [];
        let sortedCommodityList = [];
        let dataset = [];
        let finalDataset = [];
        let finalDatasetTotal = [];

        this.state.consolidationYearChecked.sort().map(year => {
            startDate = moment(new Date(this.state.consolidationFromMonth).setFullYear(year)).startOf("month").toISOString();
            if (new Date(this.state.consolidationToMonth).getMonth() >= 3) {
                endDate = moment(new Date(this.state.consolidationToMonth).setFullYear(year)).endOf("month").toISOString();
            } else {
                endDate = moment(new Date(this.state.consolidationToMonth).setFullYear(year + 1)).endOf("month").toISOString();
            }

            datesList.push({start: startDate, end: endDate});
        });

        datesList.map((each, index) => {
            let start = each.start;
            let end = each.end;

            axios.get("/api/analytics/nmhOne", {
                params: {
                    start,
                    end,
                    ports: this.state.consolidationPortChecked,
                }
            }).then(res => {
                res.data.map(each => {
                    if (each._id.category_name !== undefined) {
                        commodityList.push(each._id.category_name)
                    } else {
                        each.data.map(name => {
                            commodityList.push(name.commodity_name)
                        })
                    }
                });

                dataset.push(res.data);

                if (index === datesList.length - 1) {
                    sortedCommodityList = [...new Set(commodityList)];

                    sortedCommodityList.map(name => {
                        let blankYears = this.state.consolidationYearChecked.sort().map(() => {
                            return "-"
                        });
                        dataset.map((x, Xindex) => {
                            x.map(y => {
                                if (y._id.category_name !== undefined) {
                                    if (y._id.category_name === name) {
                                        blankYears[Xindex] = y.total;
                                    }
                                } else {
                                    y.data.map(z => {
                                        if (z.commodity_name === name) {
                                            blankYears[Xindex] = z.qty_in_mts
                                        }
                                    })
                                }
                            })
                        });
                        finalDataset.push({name: name, value: blankYears})
                    });

                    dataset.map(each => {
                        let total = 0;
                        each.map(item => {
                            total += item.total;
                        });
                        finalDatasetTotal.push(total);
                    });
                }
            });
        });

        this.setState({
            consolidatedTrafficDataSet: finalDataset,
            consolidatedTrafficTotal: finalDatasetTotal
        });
    };

    handleConsolidationPortToggle = (value) => () => {
        const currentIndex = this.state.consolidationPortChecked.indexOf(value);
        const newChecked = [...this.state.consolidationPortChecked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            consolidationPortChecked: newChecked
        });
    };

    handleConsolidatedYearToggle = (value) => () => {
        const currentIndex = this.state.consolidationYearChecked.indexOf(value);
        const newChecked = [...this.state.consolidationYearChecked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            consolidationYearChecked: newChecked
        });
    };

    handleConsolidationFromMonthChange = date => {
        this.setState({
            consolidationFromMonth: date
        });
    };

    handleConsolidationToMonthChange = date => {
        this.setState({
            consolidationToMonth: date
        });
    };

    handleConsolidationYearClickOpen = () => {
        this.setState({
            consolidationYearListOpen: true
        })
    };

    handleConsolidationYearOkay = () => {
        this.setState({
            consolidationYearListOpen: false
        })
    };

    handleConsolidationYearCancel = () => {
        this.setState({
            consolidationYearListOpen: false,
            consolidationYearChecked: []
        })
    };


    nmhTwoCargoTraffic = () => {
        let start = moment(this.state.cargoTrafficFromMonth).startOf("month").toISOString();
        let end = moment(this.state.cargoTrafficToMonth).endOf("month").toISOString();

        axios.get("/api/analytics/nmhTwo", {
            params: {
                start,
                end,
                ports: this.state.cargoTrafficPortChecked,
            }
        }).then(res => {
            let cargoType = ["Import", "Export"];
            let voyageType = ["Overseas", "Coastal"];
            let flagType = ["Indian Flag", "Foreign Flag"];
            let typeList = [];
            let sortedTypeList = [];
            let sortedCategoryList;
            let semiFinalList;
            let finalList;
            let blankTotal = ["-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-"];


            res.data.map(each => {
                each.data.map(item => {
                    typeList.push(item.type_name);
                })
            });

            let sortTypeList = [...new Set(typeList)];
            sortTypeList.reverse();

            sortTypeList.map(type => {
                let temp = [];
                res.data.map(each => {
                    each.data.map(item => {
                        if (item.type_name === type) {
                            temp.push(item.commodity_name);
                        }
                    })
                });
                let dump = [...new Set(temp)];
                sortedTypeList.push({type: type, commodity: dump})
            });

            sortedCategoryList = sortedTypeList.map(each => {
                let test = each.commodity.map(commodity => {
                    let cat_name = "";
                    let check = 0;
                    res.data.some(item => {
                        item.data.some(x => {
                            if (x.commodity_name === commodity) {
                                cat_name = x.category_name;
                                check = 1;
                            }
                            return x.commodity_name === commodity;
                        });
                        return check === 1;
                    });
                    if (cat_name !== undefined) {
                        return {name: commodity, category: cat_name};
                    } else {
                        return {name: commodity, category: "none"};
                    }
                });
                return {type: each.type, commodity: test};
            });

            semiFinalList = sortedCategoryList.map(type => {
                let grouped = _.mapValues(_.groupBy(type.commodity, 'category'),
                    list => list.map(category => _.omit(category, 'category')));
                return {type: type.type, commodity: grouped};
            });

            finalList = semiFinalList;

            semiFinalList.map((each, eachIndex) => {
                for (let category in each.commodity) {
                    each.commodity[category].map((cat, catIndex) => {
                        let blankData = ["-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-"];
                        voyageType.map((voyage, voyageIndex) => {
                            cargoType.map((cargo, cargoIndex) => {
                                flagType.map((flag, flagIndex) => {
                                    res.data.map(item => {
                                        if (item._id.voyage_type === voyage && item._id.cargo_type === cargo && item._id.flag_type === flag) {
                                            item.data.map(x => {
                                                if (x.commodity_name === cat.name) {
                                                    switch (voyageIndex.toString().concat(cargoIndex.toString(), flagIndex.toString())) {
                                                        case "000":
                                                            blankData[0] = x.qty_in_mts;
                                                            break;
                                                        case "001":
                                                            blankData[1] = x.qty_in_mts;
                                                            break;
                                                        case "010":
                                                            blankData[2] = x.qty_in_mts;
                                                            break;
                                                        case "011":
                                                            blankData[3] = x.qty_in_mts;
                                                            break;
                                                        case "100":
                                                            blankData[5] = x.qty_in_mts;
                                                            break;
                                                        case "101":
                                                            blankData[6] = x.qty_in_mts;
                                                            break;
                                                        case "110":
                                                            blankData[7] = x.qty_in_mts;
                                                            break;
                                                        case "111":
                                                            blankData[8] = x.qty_in_mts;
                                                            break;
                                                    }
                                                }
                                            });
                                            switch (voyageIndex.toString().concat(cargoIndex.toString(), flagIndex.toString())) {
                                                case "000":
                                                    blankTotal[0] = item.total;
                                                    break;
                                                case "001":
                                                    blankTotal[1] = item.total;
                                                    break;
                                                case "010":
                                                    blankTotal[2] = item.total;
                                                    break;
                                                case "011":
                                                    blankTotal[3] = item.total;
                                                    break;
                                                case "100":
                                                    blankTotal[5] = item.total;
                                                    break;
                                                case "101":
                                                    blankTotal[6] = item.total;
                                                    break;
                                                case "110":
                                                    blankTotal[7] = item.total;
                                                    break;
                                                case "111":
                                                    blankTotal[8] = item.total;
                                                    break;
                                            }
                                        }
                                    })
                                })
                            })
                        });
                        let firstTotal = 0;
                        let secondTotal = 0;
                        for (let i = 0; i < 4; i++) {
                            if (blankData[i] !== "-") {
                                firstTotal += Number(blankData[i])
                            }
                        }
                        for (let i = 5; i < 9; i++) {
                            if (blankData[i] !== "-") {
                                secondTotal += Number(blankData[i])
                            }
                        }
                        blankData[4] = firstTotal !== 0 ? firstTotal.toString() : "-";
                        blankData[9] = secondTotal !== 0 ? secondTotal.toString() : "-";
                        blankData[10] = (firstTotal + secondTotal).toString();
                        finalList[eachIndex].commodity[category][catIndex] = {name: cat.name, values: blankData};
                    });
                }
            });

            let firstTotal = 0;
            let secondTotal = 0;
            for (let i = 0; i < 4; i++) {
                if (blankTotal[i] !== "-") {
                    firstTotal += Number(blankTotal[i])
                }
            }
            for (let i = 5; i < 9; i++) {
                if (blankTotal[i] !== "-") {
                    secondTotal += Number(blankTotal[i])
                }
            }
            blankTotal[4] = firstTotal !== 0 ? firstTotal.toString() : "-";
            blankTotal[9] = secondTotal !== 0 ? secondTotal.toString() : "-";
            blankTotal[10] = (firstTotal + secondTotal).toString();

            this.setState({
                cargoTrafficDataSet: finalList,
                cargoTrafficTotal: blankTotal
            });
        })
    };

    handleCargoTrafficPortToggle = (value) => () => {
        const currentIndex = this.state.cargoTrafficPortChecked.indexOf(value);
        const newChecked = [...this.state.cargoTrafficPortChecked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            cargoTrafficPortChecked: newChecked
        });
    };

    handleCargoTrafficFromMonthChange = date => {
        this.setState({
            cargoTrafficFromMonth: date
        });
    };

    handleCargoTrafficToMonthChange = date => {
        this.setState({
            cargoTrafficToMonth: date
        });
    };

    render() {
        const {classes, allCommoditiesForSiblingCommunication} = this.props;

        const {
            portNameText,
            portListData,
            chartCommodityObjectId,
            year,
            cargoType,
            scroll,

            chartPortChecked,
            chartDataSet,

            tablePortChecked,
            tableCommodityListOpen,
            tableCommodityChecked,
            tableFromMonth,
            tableToMonth,
            tableMonthsArray,
            tablePortsArray,
            tableExportsArray,
            tableImportsArray,
            tableExportsCommodityTotal,
            tableImportsCommodityTotal,
            tableExportsSum,
            tableImportsSum,
            tableExportsTotal,
            tableImportsTotal,

            consolidationPortChecked,
            consolidationYearListOpen,
            consolidationYearChecked,
            consolidationArrayOfYears,
            consolidationFromMonth,
            consolidationToMonth,
            consolidatedTrafficDataSet,
            consolidatedTrafficTotal,

            cargoTrafficPortChecked,
            cargoTrafficFromMonth,
            cargoTrafficToMonth,
            cargoTrafficDataSet,
            cargoTrafficTotal,
        } = this.state;

        const renderData = commodity => {
            let content = [];
            for (let item in commodity) {
                commodity[item].map(each => {
                    content.push(
                        <>
                            <TableRow>
                                <TableCell className={classes.tableCellBottomRightBorder}> </TableCell>
                                <TableCell align="left"
                                           className={classes.tableCellBottomRightBorder}>&nbsp;&nbsp;&nbsp;
                                    {item !== "none" ? each.name + " (" + item + ")" : each.name}
                                </TableCell>
                                {
                                    each.values.map((value, index) => (
                                        <TableCell align="center" key={index}
                                                   className={classes.tableCellBottomRightBorder}>
                                            {value}
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </>
                    )
                })
            }
            return content;
        };

        let printTableTwo = () => {

            let top_left_X_Y_Coordinates = 0;
            let element = document.getElementById("print-analytics-two");
            let height = element.offsetHeight;
            let width = element.offsetWidth;
            let pdf = "";

            html2canvas($("#print-analytics-two")[0], {allowTaint: true}).then(function (canvas) {
                canvas.getContext('2d');

                let imgData = canvas.toDataURL("image/jpeg", 1.0);
                pdf = new JsPdf('l', 'pt', [width, height]);
                pdf.addImage(imgData, 'JPG', top_left_X_Y_Coordinates, top_left_X_Y_Coordinates, width, height);
                pdf.output('dataurlnewwindow');
            });
        };

        return (
            <MuiThemeProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => printTableTwo()}
                        style={{marginRight: 20}}
                    >
                        Print Table 2
                    </Button>
                    <div>
                        <Paper style={{display: "flex", flexDirection: "column", marginBottom: 20}}>

                            {JSON.parse(localStorage.getItem("user")) &&
                            JSON.parse(localStorage.getItem("user")).userType ===
                            "port-operator" ?
                                <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                    <Typography
                                        variant="h2"
                                        style={{
                                            width: "400px",
                                            margin: "20px 0 20px 0",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                    >
                                        Port Name : {portNameText}
                                    </Typography>
                                    <div
                                        style={{
                                            display: "flex"
                                        }}
                                    >
                                        <div style={{display: "flex", margin: "auto"}}>
                                            <DatePicker
                                                autoOk
                                                required
                                                views={["year"]}
                                                label="Select Financial Year"
                                                value={year}
                                                onChange={this.handleYearChange}
                                                style={{width: 200}}
                                            />
                                        </div>
                                    </div>
                                    <TextField
                                        select
                                        required
                                        autoFocus
                                        label="Select Commodity"
                                        classes={{
                                            root: classes.space
                                        }}
                                        value={chartCommodityObjectId}
                                        onChange={this.handleChartCommodityToggle}
                                        menuprops={MenuProps}
                                        style={{width: "400px", margin: "20px 0 20px 0"}}
                                    >
                                        {allCommoditiesForSiblingCommunication.map(item => (
                                            <MenuItem key={item._id} value={item._id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                :

                                <div style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                                    <div>
                                        <List dense className={classes.root}>
                                            {portListData.map((value) => {
                                                const labelId = `checkbox-list-secondary-label-${value._id}`;
                                                return (
                                                    <ListItem key={value._id} button>
                                                        <ListItemText id={labelId} primary={value.port_name}/>
                                                        <ListItemSecondaryAction>
                                                            <Checkbox
                                                                edge="end"
                                                                onChange={this.handleChartPortToggle(value._id)}
                                                                checked={chartPortChecked.indexOf(value._id) !== -1}
                                                                inputProps={{'aria-labelledby': labelId}}
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex"
                                        }}
                                    >
                                        <div style={{display: "flex", margin: "auto"}}>
                                            <DatePicker
                                                autoOk
                                                required
                                                views={["year"]}
                                                label="Select Financial Year"
                                                value={year}
                                                onChange={this.handleYearChange}
                                                style={{width: 200}}
                                            />
                                        </div>
                                    </div>
                                    <TextField
                                        select
                                        required
                                        autoFocus
                                        label="Select Commodity"
                                        classes={{
                                            root: classes.space
                                        }}
                                        value={chartCommodityObjectId}
                                        onChange={this.handleChartCommodityToggle}
                                        menuprops={MenuProps}
                                        style={{width: "400px", margin: "20px 0 20px 0"}}
                                    >
                                        {allCommoditiesForSiblingCommunication.map(item => (
                                            <MenuItem key={item._id} value={item._id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            }
                        </Paper>

                        <div id="print-analytics-one" className={classes.root}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Paper style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginBottom: 20,
                                        height: 691,
                                        overflow: "auto"
                                    }}>
                                        <div style={{
                                            position: "relative",
                                            margin: "auto",
                                            height: "90vh",
                                            width: chartPortChecked.length <= 2 ? "90vw" : 40 * chartPortChecked.length + "vw"
                                        }}>
                                            <BarChart data={chartDataSet}
                                                      title={"Monthly Imports & Exports Cargo FY " + moment(year).year() + "-" + (moment(year).year() + 1) + " (All values are in MTs only)"}/>
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </div>


                        <Paper style={{display: "flex", flexDirection: "column", marginBottom: 20}}>

                            {JSON.parse(localStorage.getItem("user")) &&
                            JSON.parse(localStorage.getItem("user")).userType ===
                            "port-operator" ?
                                <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                    <Typography
                                        variant="h2"
                                        style={{
                                            width: "400px",
                                            margin: "20px 0px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                    >
                                        Port Name : {portNameText}
                                    </Typography>
                                    <div
                                        style={{
                                            display: "flex"
                                        }}
                                    >
                                        <div style={{display: "flex", margin: "auto"}}>
                                            <DatePicker
                                                autoOk
                                                required
                                                views={["year"]}
                                                label="Select Financial Year"
                                                value={year}
                                                onChange={this.handleYearChange}
                                                style={{width: 200}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                :

                                <div style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                                    <div>
                                        <List dense className={classes.root}>
                                            {portListData.map((value) => {
                                                const labelId = `checkbox-list-secondary-label-${value._id}`;
                                                return (
                                                    <ListItem key={value._id} button>
                                                        <ListItemText id={labelId} primary={value.port_name}/>
                                                        <ListItemSecondaryAction>
                                                            <Checkbox
                                                                edge="end"
                                                                onChange={this.handleTablePortToggle(value._id)}
                                                                checked={tablePortChecked.indexOf(value._id) !== -1}
                                                                inputProps={{'aria-labelledby': labelId}}
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex", flexDirection: "column", margin: "0px 20px"
                                        }}
                                    >
                                        <div style={{display: "flex", marginBottom: 30}}>
                                            <DatePicker
                                                autoOk
                                                required
                                                clearable
                                                views={["year", "month"]}
                                                label="From Month"
                                                value={tableFromMonth}
                                                onChange={this.handleTableFromMonthChange}
                                                style={{width: 200}}
                                            />
                                        </div>
                                        <div style={{display: "flex", marginBottom: 30}}>
                                            <DatePicker
                                                autoOk
                                                required
                                                clearable
                                                views={["year", "month"]}
                                                label="To Month"
                                                value={tableToMonth}
                                                onChange={this.handleTableToMonthChange}
                                                style={{width: 200}}
                                            />
                                        </div>
                                    </div>
                                    <TextField
                                        select
                                        required
                                        autoFocus
                                        label="Select Cargo Type"
                                        classes={{
                                            root: classes.space
                                        }}
                                        value={cargoType}
                                        onChange={this.handleCargoTypeChange}
                                        menuprops={MenuProps}
                                        style={{width: "200px", margin: "20px 0 20px 0"}}
                                    >
                                        {cargoTypes.map(item => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <Button onClick={this.handleTableCommodityClickOpen}>Select Commodity</Button>
                                    <Dialog
                                        open={tableCommodityListOpen}
                                        onClose={this.handleClose}
                                        scroll={scroll}
                                        aria-labelledby="scroll-dialog-title"
                                        aria-describedby="scroll-dialog-description"
                                    >
                                        <DialogTitle id="scroll-dialog-title">Select Commodity</DialogTitle>
                                        <DialogContent dividers={scroll === 'paper'}>
                                            <List className={classes.root}>
                                                {allCommoditiesForSiblingCommunication.map((value) => {
                                                    const labelId = `checkbox-list-label-${value._id}`;

                                                    return (
                                                        <ListItem key={value._id} role={undefined} dense button
                                                                  onClick={this.handleTableCommodityToggle(value._id)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    edge="start"
                                                                    checked={tableCommodityChecked.indexOf(value._id) !== -1}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    inputProps={{'aria-labelledby': labelId}}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText id={labelId}
                                                                          primary={value.name}/>
                                                        </ListItem>
                                                    );
                                                })}
                                            </List>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleTableCommodityOkay} color="primary">
                                                Okay
                                            </Button>
                                            <Button onClick={this.handleTableCommodityCancel} color="primary">
                                                Cancel
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                            }
                        </Paper>

                        <div id="print-analytics-two" style={{display: "flex", flexDirection: "column", marginBottom: 20}}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={tableMonthsArray.length * tablePortsArray.length + 2}
                                                       align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                Monthly Imports & Exports Cargo
                                                between {moment(tableFromMonth).format('MMM YYYY')} - {moment(tableToMonth).format('MMM YYYY')} (All
                                                values are in MTs only)
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center"
                                                       className={classes.tableCellBottomRightBorder}> </TableCell>
                                            {
                                                tableMonthsArray.map(month => (
                                                    <TableCell align="center" colSpan={tablePortsArray.length}
                                                               className={classes.tableCellBottomRightBorder}>{month}</TableCell>
                                                ))
                                            }
                                            <TableCell align="center"
                                                       className={classes.tableCellBottomRightBorder}> </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center"
                                                       className={classes.tableCellBottomRightBorder}> </TableCell>
                                            {
                                                tableMonthsArray.map(() => (
                                                    tablePortsArray.map(port => (
                                                        <TableCell align="center"
                                                                   className={classes.tableCellBottomRightBorder}>{port}</TableCell>
                                                    ))
                                                ))
                                            }
                                            <TableCell align="center" style={{fontWeight: "bold"}}
                                                       className={classes.tableCellBottomRightBorder}>Total</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            cargoType === "EXPORT" || cargoType === "BOTH" ?
                                                <>
                                                    <TableRow>
                                                        <TableCell
                                                            colSpan={tableMonthsArray.length * tablePortsArray.length + 2}
                                                            className={classes.tableCellBottomRightBorder}> </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell align="left" style={{fontWeight: "bold"}}
                                                                   className={classes.tableCellBottomRightBorder}>Exports</TableCell>
                                                        <TableCell
                                                            colSpan={tableMonthsArray.length * tablePortsArray.length + 1}
                                                            align="left"
                                                            className={classes.tableCellBottomRightBorder}> </TableCell>
                                                    </TableRow>
                                                    {
                                                        tableExportsArray.map((cargo, index) => (
                                                            <TableRow>
                                                                <TableCell align="center"
                                                                           className={classes.tableCellBottomRightBorder}>{cargo.name}</TableCell>
                                                                {
                                                                    cargo.values.map(value => (
                                                                        <TableCell align="center"
                                                                                   className={classes.tableCellBottomRightBorder}>{value}</TableCell>
                                                                    ))
                                                                }
                                                                <TableCell align="center" style={{fontWeight: "bold"}}
                                                                           className={classes.tableCellBottomRightBorder}>{tableExportsCommodityTotal[index]}</TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                    <TableRow>
                                                        <TableCell align="left" style={{fontWeight: "bold"}}
                                                                   className={classes.tableCellBottomRightBorder}>Total</TableCell>
                                                        {
                                                            tableExportsTotal.map(each => (
                                                                <TableCell align="center" style={{fontWeight: "bold"}}
                                                                           className={classes.tableCellBottomRightBorder}>{each}</TableCell>
                                                            ))
                                                        }
                                                        <TableCell align="center" style={{fontWeight: "bold"}}
                                                                   className={classes.tableCellBottomRightBorder}>{tableExportsSum}</TableCell>
                                                    </TableRow>
                                                </>
                                                :
                                                null
                                        }

                                        {
                                            cargoType === "IMPORT" || cargoType === "BOTH" ?
                                                <>
                                                    <TableRow>
                                                        <TableCell
                                                            colSpan={tableMonthsArray.length * tablePortsArray.length + 2}
                                                            className={classes.tableCellBottomRightBorder}> </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell align="left" style={{fontWeight: "bold"}}
                                                                   className={classes.tableCellBottomRightBorder}>Imports</TableCell>
                                                        <TableCell
                                                            colSpan={tableMonthsArray.length * tablePortsArray.length + 1}
                                                            align="left"
                                                            className={classes.tableCellBottomRightBorder}> </TableCell>
                                                    </TableRow>
                                                    {
                                                        tableImportsArray.map((cargo, index) => (
                                                            <TableRow>
                                                                <TableCell align="center"
                                                                           className={classes.tableCellBottomRightBorder}>{cargo.name}</TableCell>
                                                                {
                                                                    cargo.values.map(value => (
                                                                        <TableCell align="center"
                                                                                   className={classes.tableCellBottomRightBorder}>{value}</TableCell>
                                                                    ))
                                                                }
                                                                <TableCell align="center" style={{fontWeight: "bold"}}
                                                                           className={classes.tableCellBottomRightBorder}>{tableImportsCommodityTotal[index]}</TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                    <TableRow>
                                                        <TableCell align="left" style={{fontWeight: "bold"}}
                                                                   className={classes.tableCellBottomRightBorder}>Total</TableCell>
                                                        {
                                                            tableImportsTotal.map(each => (
                                                                <TableCell align="center" style={{fontWeight: "bold"}}
                                                                           className={classes.tableCellBottomRightBorder}>{each}</TableCell>
                                                            ))
                                                        }
                                                        <TableCell align="center" style={{fontWeight: "bold"}}
                                                                   className={classes.tableCellBottomRightBorder}>{tableImportsSum}</TableCell>
                                                    </TableRow>
                                                </>
                                                :
                                                null
                                        }

                                        <TableRow>
                                            <TableCell colSpan={tableMonthsArray.length * tablePortsArray.length + 2}
                                                       className={classes.tableCellBottomRightBorder}> </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>


                        <Paper style={{display: "flex", flexDirection: "column", marginBottom: 20}}>

                            {JSON.parse(localStorage.getItem("user")) &&
                            JSON.parse(localStorage.getItem("user")).userType ===
                            "port-operator" ?
                                <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                    <Typography
                                        variant="h2"
                                        style={{
                                            width: "400px",
                                            margin: "20px 0px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                    >
                                        Port Name : {portNameText}
                                    </Typography>
                                    <div
                                        style={{
                                            display: "flex"
                                        }}
                                    >
                                        <div style={{display: "flex", margin: "auto"}}>
                                            <DatePicker
                                                autoOk
                                                required
                                                views={["year"]}
                                                label="Select Financial Year"
                                                value={year}
                                                onChange={this.handleYearChange}
                                                style={{width: 200}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                :

                                <div style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                                    <div>
                                        <List dense className={classes.root}>
                                            {portListData.map((value) => {
                                                const labelId = `checkbox-list-secondary-label-${value._id}`;
                                                return (
                                                    <ListItem key={value._id} button>
                                                        <ListItemText id={labelId} primary={value.port_name}/>
                                                        <ListItemSecondaryAction>
                                                            <Checkbox
                                                                edge="end"
                                                                onChange={this.handleConsolidationPortToggle(value._id)}
                                                                checked={consolidationPortChecked.indexOf(value._id) !== -1}
                                                                inputProps={{'aria-labelledby': labelId}}
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </div>
                                    <Button onClick={this.handleConsolidationYearClickOpen}>Select Years</Button>
                                    <Dialog
                                        open={consolidationYearListOpen}
                                        onClose={this.handleClose}
                                        scroll={scroll}
                                        aria-labelledby="scroll-dialog-title"
                                        aria-describedby="scroll-dialog-description"
                                    >
                                        <DialogTitle id="scroll-dialog-title">Select Years</DialogTitle>
                                        <DialogContent dividers={scroll === 'paper'}>
                                            <List className={classes.root}>
                                                {consolidationArrayOfYears.map((value) => {
                                                    const labelId = `checkbox-list-label-${value}`;

                                                    return (
                                                        <ListItem key={value} role={undefined} dense button
                                                                  onClick={this.handleConsolidatedYearToggle(value)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    edge="start"
                                                                    checked={consolidationYearChecked.indexOf(value) !== -1}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    inputProps={{'aria-labelledby': labelId}}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText id={labelId}
                                                                          primary={value}/>
                                                        </ListItem>
                                                    );
                                                })}
                                            </List>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleConsolidationYearOkay} color="primary">
                                                Okay
                                            </Button>
                                            <Button onClick={this.handleConsolidationYearCancel} color="primary">
                                                Cancel
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <div
                                        style={{
                                            display: "flex", flexDirection: "column", margin: "0px 20px"
                                        }}
                                    >
                                        <div style={{display: "flex", marginBottom: 30}}>
                                            <DatePicker
                                                autoOk
                                                required
                                                clearable
                                                views={["month"]}
                                                label="From Month"
                                                value={consolidationFromMonth}
                                                onChange={this.handleConsolidationFromMonthChange}
                                                minDate={new Date().getFullYear() + "-04-01"}
                                                maxDate={new Date().getFullYear() + "-12-30"}
                                                style={{width: 200}}
                                            />
                                        </div>
                                        <div style={{display: "flex", marginBottom: 30}}>
                                            <DatePicker
                                                autoOk
                                                required
                                                clearable
                                                views={["month"]}
                                                label="To Month"
                                                value={consolidationToMonth}
                                                onChange={this.handleConsolidationToMonthChange}
                                                style={{width: 200}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </Paper>

                        <div id="print-analytics-three" style={{display: "flex", flexDirection: "column", marginBottom: 20}}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={consolidationYearChecked + 2}
                                                       align="left" style={{background: "#979797"}}
                                                       className={classes.tableCellBottomRightBorder}>
                                                &nbsp;NMH-I
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={consolidationYearChecked + 2}
                                                       align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                TRAFFIC HANDLED AT NON MAJOR PORT (CONSOLIDATED)
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={consolidationYearChecked + 2}
                                                       align="right"
                                                       className={classes.tableCellBottomRightBorder}>
                                                (In 000 tonnes)
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell rowSpan={2}
                                                       align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                Sl.No.
                                            </TableCell>
                                            <TableCell rowSpan={2}
                                                       align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                Commodity
                                            </TableCell>
                                            <TableCell colSpan={consolidationYearChecked + 2}
                                                       align="center" style={{fontWeight: "bold"}}
                                                       className={classes.tableCellBottomRightBorder}>
                                                {moment(consolidationFromMonth).format('MMMM') === moment(consolidationToMonth).format('MMMM') ?
                                                    moment(consolidationToMonth).format('MMMM')
                                                    :
                                                    moment(consolidationFromMonth).format('MMMM') + "-" + moment(consolidationToMonth).format('MMMM')}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            {
                                                consolidationYearChecked.map(year => (
                                                    <TableCell align="center" key={year} style={{fontWeight: "bold"}}
                                                               colSpan={tablePortsArray.length}
                                                               className={classes.tableCellBottomRightBorder}>{year}-{year + 1}</TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            <>
                                                {
                                                    consolidatedTrafficDataSet.map((each, index) => (
                                                        <TableRow>
                                                            <TableCell align="center"
                                                                       className={classes.tableCellBottomRightBorder}>{index + 1}</TableCell>
                                                            <TableCell align="left"
                                                                       className={classes.tableCellBottomRightBorder}>&nbsp;{each.name}</TableCell>
                                                            {
                                                                each.value.map((item, index) => (
                                                                    <TableCell align="center" key={index}
                                                                               className={classes.tableCellBottomRightBorder}>{item}</TableCell>
                                                                ))
                                                            }
                                                        </TableRow>
                                                    ))
                                                }
                                                <TableRow>
                                                    <TableCell align="left"
                                                               className={classes.tableCellBottomRightBorder}> </TableCell>
                                                    <TableCell align="left" style={{fontWeight: "bold"}}
                                                               className={classes.tableCellBottomRightBorder}>&nbsp;Total</TableCell>
                                                    {
                                                        consolidatedTrafficTotal.map((each, index) => (
                                                            <TableCell align="center" key={index} style={{fontWeight: "bold"}}
                                                                       className={classes.tableCellBottomRightBorder}>{each}</TableCell>
                                                        ))
                                                    }
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={consolidationYearChecked + 2}
                                                               className={classes.tableCellBottomRightBorder}> </TableCell>
                                                </TableRow>
                                            </>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>


                        <Paper style={{display: "flex", flexDirection: "column", marginBottom: 20}}>

                            {JSON.parse(localStorage.getItem("user")) &&
                            JSON.parse(localStorage.getItem("user")).userType ===
                            "port-operator" ?
                                <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                    <Typography
                                        variant="h2"
                                        style={{
                                            width: "400px",
                                            margin: "20px 0px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                    >
                                        Port Name : {portNameText}
                                    </Typography>
                                    <div
                                        style={{
                                            display: "flex"
                                        }}
                                    >
                                        <div style={{display: "flex", margin: "auto"}}>
                                            <DatePicker
                                                autoOk
                                                required
                                                views={["year"]}
                                                label="Select Financial Year"
                                                value={year}
                                                onChange={this.handleYearChange}
                                                style={{width: 200}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                :

                                <div style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                                    <div>
                                        <List dense className={classes.root}>
                                            {portListData.map((value) => {
                                                const labelId = `checkbox-list-secondary-label-${value._id}`;
                                                return (
                                                    <ListItem key={value._id} button>
                                                        <ListItemText id={labelId} primary={value.port_name}/>
                                                        <ListItemSecondaryAction>
                                                            <Checkbox
                                                                edge="end"
                                                                onChange={this.handleCargoTrafficPortToggle(value._id)}
                                                                checked={cargoTrafficPortChecked.indexOf(value._id) !== -1}
                                                                inputProps={{'aria-labelledby': labelId}}
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex", flexDirection: "column", margin: "0px 20px"
                                        }}
                                    >
                                        <div style={{display: "flex", marginBottom: 30}}>
                                            <DatePicker
                                                autoOk
                                                required
                                                clearable
                                                views={["year", "month"]}
                                                label="From Month"
                                                value={cargoTrafficFromMonth}
                                                onChange={this.handleCargoTrafficFromMonthChange}
                                                style={{width: 200}}
                                            />
                                        </div>
                                        <div style={{display: "flex", marginBottom: 30}}>
                                            <DatePicker
                                                autoOk
                                                required
                                                clearable
                                                views={["year", "month"]}
                                                label="To Month"
                                                value={cargoTrafficToMonth}
                                                onChange={this.handleCargoTrafficToMonthChange}
                                                style={{width: 200}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </Paper>

                        <div id="print-analytics-four" style={{display: "flex", flexDirection: "column", marginBottom: 20}}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={13}
                                                       align="left" style={{background: "#979797"}}
                                                       className={classes.tableCellBottomRightBorder}>
                                                &nbsp;NMH-II
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={13}
                                                       align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                CARGO TRAFFIC HANDLED
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={7}
                                                       align="left"
                                                       className={classes.tableCellBottomRightBorder}>
                                                &nbsp;Non-Major Ports of A.P. (Consolidated)
                                            </TableCell>
                                            <TableCell colSpan={6}
                                                       align="right"
                                                       className={classes.tableCellBottomRightBorder}>
                                                Period : From {moment(cargoTrafficFromMonth).format('MMMM YYYY')} to {moment(cargoTrafficToMonth).format('MMMM YYYY')}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell rowSpan={4}
                                                       align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                Sl.No
                                            </TableCell>
                                            <TableCell rowSpan={4}
                                                       align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                Commodity
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={5}
                                                       align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                Overseas
                                            </TableCell>
                                            <TableCell colSpan={5}
                                                       align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                Coastal
                                            </TableCell>
                                            <TableCell rowSpan={3}
                                                       align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                Grand Total
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={2}
                                                       align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                Unloaded
                                            </TableCell>
                                            <TableCell colSpan={2}
                                                       align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                Loaded
                                            </TableCell>
                                            <TableCell rowSpan={2}
                                                       align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                Total
                                            </TableCell>
                                            <TableCell colSpan={2}
                                                       align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                Unloaded
                                            </TableCell>
                                            <TableCell colSpan={2}
                                                       align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                Loaded
                                            </TableCell>
                                            <TableCell rowSpan={2}
                                                       align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                Total
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                IF
                                            </TableCell>
                                            <TableCell align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                FF
                                            </TableCell>
                                            <TableCell align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                IF
                                            </TableCell>
                                            <TableCell align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                FF
                                            </TableCell>
                                            <TableCell align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                IF
                                            </TableCell>
                                            <TableCell align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                FF
                                            </TableCell>
                                            <TableCell align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                IF
                                            </TableCell>
                                            <TableCell align="center"
                                                       className={classes.tableCellBottomRightBorder}>
                                                FF
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            cargoTrafficDataSet.map((data, index) => (
                                                <>
                                                    <TableRow>
                                                        <TableCell align="center"
                                                                   className={classes.tableCellBottomRightBorder}>
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell align="left" style={{fontWeight: "bold"}}
                                                                   colSpan={12}
                                                                   className={classes.tableCellBottomRightBorder}>
                                                            &nbsp;{data.type}
                                                        </TableCell>
                                                    </TableRow>
                                                    {renderData(data.commodity)}
                                                </>
                                            ))
                                        }
                                        {
                                            <TableRow>
                                                <TableCell className={classes.tableCellBottomRightBorder}> </TableCell>
                                                <TableCell align="left" style={{fontWeight: "bold"}}
                                                           className={classes.tableCellBottomRightBorder}>
                                                    &nbsp;ALL
                                                </TableCell>
                                                {
                                                    cargoTrafficTotal.map((each, index) => (
                                                        <TableCell align="center" key={index}  style={{fontWeight: "bold"}}
                                                                   className={classes.tableCellBottomRightBorder}>
                                                            {each}
                                                        </TableCell>
                                                    ))
                                                }
                                            </TableRow>
                                        }
                                        <TableRow>
                                            <TableCell colSpan={13}
                                                       className={classes.tableCellBottomRightBorder}> </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        );
    }
}

AnalyticsPort.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AnalyticsPort);
