import React, {Component} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import toolbarStyles from "../commonStyles/toolbarStyles";
import {CSVLink} from "react-csv";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EditCommodity from "./EditCommodity";
import SearchFilterCommodity from "./SearchFilterCommodity";

class TableToolbarCommodity extends Component {
    state = {
        shouldSearchFilterCompOpen: false,
        searchValue: "",
        searchSelection: "",
        textFilterCancelTooltip: "",
    };

    setTextFilterTooltip = tooltipfromchild => {
        this.setState({
            textFilterCancelTooltip: tooltipfromchild
        });
    };

    clearTextFilterTooltip = () => {
        this.setState({
            textFilterCancelTooltip: ""
        });
    };

    handleSearchInput = (e, {action}) => {
        if (
            action === "menu-close" ||
            action === "input-blur" ||
            action === "set-value"
        ) {
            return;
        } else {
            this.setState({searchValue: e});
        }
    };

    // In this TableToolbarCommodity component, I set the searchValue for the query, and with the handleQueryString() function I pass that value up to the parent component CommodityList
    handleSearchSelection = ({value}) => {
        this.setState(
            {
                searchSelection: value,
                searchValue: value
            },
            () => {
                if (this.state.searchValue) {
                    this.props.handleQueryString(this.state.searchValue);
                }
            }
        );
    };

    openSearchFilterCompOnClick = () => {
        if (this.state.shouldSearchFilterCompOpen === false) {
            this.setState({
                shouldSearchFilterCompOpen: true
            });
        }
    };

    closeSearchFilterCompOnClick = () => {
        this.setState(
            {
                shouldSearchFilterCompOpen: false,
                searchValue: ""
            },
            () => {
                this.props.clearAllQueryString();
                this.clearTextFilterTooltip();
            }
        );
    };

    render() {
        const {
            numSelected,
            confirmDeleteCustom,
            checkedItems,
            commodityToEdit,
            editItem,
            unSelectItems,
            classes,
            themeType
        } = this.props;

        const {searchValue, searchSelection} = this.state;

        return (
            <Toolbar
                className={classNames(classes.root, {
                    [classes.highlight]: numSelected > 0
                })}
            >
                <div className={classes.title}>
                    {numSelected === 1 ? (
                        <Typography color="inherit" variant="h3">
                            {numSelected} item selected
                        </Typography>
                    ) : numSelected > 1 ? (
                        <Typography color="inherit" variant="h3">
                            {numSelected} items selected
                        </Typography>
                    ) : (
                        null
                    )}
                </div>
                <div className={classes.spacer}/>
                <div className={classes.actions}>
                    {numSelected > 0 ? (
                        <div style={{display: "flex", flexDirection: "row"}}>
                            {numSelected === 1 ? (
                                <Tooltip title="Download data for the selected item(s)">
                                    <CSVLink
                                        data={this.props.downloadSelectedItems}
                                        className={classes.csvDownloadSingle}
                                    >
                                        <FontAwesomeIcon icon="download" size="1x" color="black"/>
                                    </CSVLink>
                                </Tooltip>
                            ) : null}
                            {numSelected > 1 ? (
                                <Tooltip title="Download data for the selected item(s)">
                                    <CSVLink
                                        data={this.props.downloadSelectedItems}
                                        style={{marginRight: "10px", paddingTop: "13px"}}
                                    >
                                        <FontAwesomeIcon icon="download" size="1x" color="black"/>
                                    </CSVLink>
                                </Tooltip>
                            ) : null}
                            <Tooltip title="Delete">
                                <IconButton
                                    disableRipple={true}
                                    disableFocusRipple={true}
                                    aria-label="Delete"
                                    onClick={confirmDeleteCustom.bind(null, checkedItems)}
                                    variant="contained"
                                    className={classes.button}
                                    style={{width: "40px"}}
                                >
                                    <DeleteIcon className={classes.rightIcon}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit">
                                <IconButton
                                    disableRipple={true}
                                    disableFocusRipple={true}
                                    aria-label="Edit"
                                    variant="contained"
                                    className={classes.button}
                                >
                                    {numSelected === 1 ? (
                                        <EditCommodity
                                            editItemToParentState={editItem}
                                            commodityToEdit={commodityToEdit}
                                            unSelectItems={unSelectItems}
                                            themeType={themeType}
                                        />
                                    ) : null}
                                </IconButton>
                            </Tooltip>
                        </div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                margin: "auto",
                                width: "100%",
                                paddingRight: "10px"
                            }}
                        >
                            {!this.state.shouldSearchFilterCompOpen ? (
                                <Tooltip title="Download entire data">
                                    <CSVLink
                                        data={this.props.allCommodities}
                                        style={{marginRight: "20px", paddingTop: "12px"}}
                                    >
                                        <FontAwesomeIcon
                                            icon="download"
                                            size="1x"
                                            color="#0099e5"
                                        />
                                    </CSVLink>
                                </Tooltip>
                            ) : null}
                            <Tooltip title={this.state.textFilterCancelTooltip === "" ? "Filter/Search Table" : null}>
                                <IconButton
                                    disableRipple={true}
                                    disableFocusRipple={true}
                                    className={classes.button}
                                    aria-label="Filter list"
                                    onClick={this.openSearchFilterCompOnClick}
                                >
                                    {this.state.shouldSearchFilterCompOpen ? (
                                        <SearchFilterCommodity
                                            closeSearchFilterCompOnClick={
                                                this.closeSearchFilterCompOnClick
                                            }
                                            allCommodities={this.props.allCommodities}
                                            handleColumnToQuery={this.props.handleColumnToQuery}
                                            value={searchSelection}
                                            onChange={e => this.handleSearchSelection(e)}
                                            inputValue={searchValue}
                                            onInputChange={(e, action) =>
                                                this.handleSearchInput(e, action)
                                            }
                                            style={{marginRight: "10px"}}
                                            setTextFilterTooltip={this.setTextFilterTooltip}
                                            clearTextFilterTooltip={this.clearTextFilterTooltip}
                                        />
                                    ) : (
                                        <FontAwesomeIcon icon="filter" color="#0099e5" size="1x"/>
                                    )}
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}
                </div>
            </Toolbar>
        );
    }
}

TableToolbarCommodity.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired
};

export default withStyles(toolbarStyles)(TableToolbarCommodity);
