import React, {Component} from "react";
import axios from "axios";
import PropTypes from "prop-types";
import history from "../../../history";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {withStyles} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {styles} from "../commonStyles/AddNewItemStyles";
import EmptyFieldSnackBar from "./Snackbars/EmptyFieldSnackBar";
import EditItemConfirmSnackbar from "./Snackbars/EditItemConfirmSnackbar";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import Typography from "@material-ui/core/Typography";
import {MenuItem} from "material-ui/Menu";
import setAxiosAuthheaders from "../UtilFunctions/setAxiosAuthheaders";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 550
        }
    }
};

class EditCommodity extends Component {
    state = {
        open: false,
        openEditItemConfirmSnackbar: false,
        openEmptyTextFieldSnackbar: false,
        vertical: "top",
        horizontal: "center",
        categoryList: [],
        typeList: [],
        name: this.props.commodityToEdit[0].name,
        type: this.props.commodityToEdit[0].type !== undefined ? this.props.commodityToEdit[0].type !== null ? this.props.commodityToEdit[0].type._id : "" : "",
        category: this.props.commodityToEdit[0].category !== undefined ? this.props.commodityToEdit[0].category !== null ? this.props.commodityToEdit[0].category._id : "" : "",
        arrowRef: null,
        //Error Message handler
        errorName: "",
        errorType: ""
    };

    componentWillMount() {
        setAxiosAuthheaders();
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem("user")) &&
            JSON.parse(localStorage.getItem("user")).userType === "port-operator") {
            axios
                .get("/api/commodity/categories")
                .then(res => {
                    this.setState({
                        categoryList: res.data
                    });
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        history.push("/login");
                        alert("Please Login, session expired");
                    } else {
                        alert("Oops loading commodity categories failed, please try again");
                    }
                });

            axios
                .get("/api/commodity/types")
                .then(res => {
                    this.setState({
                        typeList: res.data
                    });
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        history.push("/login");
                        alert("Please Login, session expired");
                    } else {
                        alert("Oops loading commodity types failed, please try again");
                    }
                });
        }
    }

    handleArrowRef = node => {
        this.setState({
            arrowRef: node
        });
    };

    handleToggle = () => {
        this.setState({
            open: !this.state.open
        });
    };

    handleFabOpen = () => {
        this.setState({open: true});
    };

    closeNewItemConfirmSnackbar = () => {
        this.setState({openEditItemConfirmSnackbar: false});
        this.props.unSelectItems();
    };

    closeEmptyFieldSnackbar = () => {
        this.setState({openEmptyTextFieldSnackbar: false}, () => {
        });
    };

    handleEditFormSubmit = () => {
        const {editItemToParentState} = this.props;
        const {name, type, category} = this.state;

        name !== "" ? this.setState({errorName: ""}) : this.setState({errorName: "Enter Commodity Name"});
        type !== "" ? this.setState({errorType: ""}) : this.setState({errorType: "Select Commodity Type"});

        if (name !== "" && type !== "") {
            axios
                .put(`/api/commodity/${this.props.commodityToEdit[0]._id}`, {
                    name,
                    type,
                    category
                })
                .then(() => {
                    editItemToParentState();
                    this.setState(
                        {
                            open: false,
                            openEditItemConfirmSnackbar: true,
                            vertical: "top",
                            horizontal: "center"
                        },
                        () => {
                            history.push("/dashboard/commodities");
                        }
                    );
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        history.push("/login");
                        alert("Please Login, session expired");
                    } else {
                        alert("Oops something wrong happened, please try again");
                    }
                });
        } else {
            this.setState({openEmptyTextFieldSnackbar: true});
        }
    };

    handleCancel = () => {
        this.setState({
            open: !this.state.open,
            name: this.props.commodityToEdit[0].name,
            type: this.props.commodityToEdit[0].type !== undefined ? this.props.commodityToEdit[0].type.typeName : null,
            category: this.props.commodityToEdit[0].category !== undefined ? this.props.commodityToEdit[0].category.categoryName : null,
            //Error Message handler
            errorName: "",
            errorType: ""
        });
    };

    handleEnterEscapeKeyPress = e => {
        if (e.key === "Enter") {
            this.handleEditFormSubmit();
        } else if (e.key === "Escape") {
            this.handleCancel();
        }
    };

    render() {
        const {classes, themeType} = this.props;
        const {
            categoryList,
            typeList,
            name,
            type,
            category,
            //Error Message handler
            errorName,
            errorType
        } = this.state;

        return (
            <div>
                <EditIcon
                    onClick={this.handleFabOpen}
                    style={{width: "40px"}}
                    aria-label="Edit Checked"
                />
                <Dialog
                    open={this.state.open}
                    onClose={this.handleToggle}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth={"sm"}
                    variant="contained"
                    PaperProps={{
                        classes: {
                            root: classes.paper
                        }
                    }}
                    onKeyDown={this.handleEnterEscapeKeyPress}
                >
                    <DialogTitle
                        id="form-dialog-title"
                        disableTypography
                        className={themeType ? classes.dialogTitleEditDark : classes.dialogTitleEditLight}
                    >
                        <div className={classes.displayFlexDialogTitle}>
                            <Typography variant="h5" className={classes.dialogTitleHeading}>Edit Commodity</Typography>
                            <IconButton
                                className={classes.button}
                                onClick={this.handleToggle}
                                style={{float: "right"}}
                            >
                                <CancelIcon/>
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent required>

                        <TextField
                            required
                            classes={{
                                root: classes.space
                            }}
                            value={name}
                            onChange={e =>
                                this.setState({
                                    name: e.target.value
                                })
                            }
                            label="Name"
                            type="string"
                            error={errorName.length !== 0}
                            helperText={errorName}
                            fullWidth
                        />

                        <TextField
                            select
                            required
                            label="Select Commodity Type"
                            className={classes.textFieldDropDown}
                            value={type !== null ? type : ""}
                            onChange={e => {
                                this.setState({
                                    type: e.target.value
                                })
                            }}
                            menuprops={MenuProps}
                            error={errorType.length !== 0}
                            helperText={errorType}
                            margin="normal"
                            fullWidth
                        >
                            {typeList.map(item => (
                                <MenuItem key={item._id} value={item._id}>
                                    {item.typeName}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            select
                            label="Select Commodity Category"
                            className={classes.textFieldDropDown}
                            value={category !== null ? category : ""}
                            onChange={e => {
                                this.setState({
                                    category: e.target.value
                                })
                            }}
                            menuprops={MenuProps}
                            error={errorType.length !== 0}
                            helperText={errorType}
                            margin="normal"
                            fullWidth
                        >
                            {categoryList.map(item => (
                                <MenuItem key={item._id} value={item._id}>
                                    {item.categoryName}
                                </MenuItem>
                            ))}
                        </TextField>

                    </DialogContent>

                    <DialogActions
                        className={classes.dialogActions}
                    >
                        <div className={classes.displayFlexDialogActions}>
                            <Button
                                onClick={this.handleCancel}
                                variant="contained"
                                size="large"
                                classes={{
                                    root: classes.spaceDialogAction
                                }}
                                style={{backgroundColor: "#ee0053"}}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={this.handleEditFormSubmit}
                                classes={{
                                    root: classes.spaceDialogAction
                                }}
                                color="primary"
                                variant="contained"
                                size="large"
                                disabled={name === "" || type === ""}
                            >
                                Update
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
                <EditItemConfirmSnackbar
                    openEditItemConfirmSnackbar={this.state.openEditItemConfirmSnackbar}
                    closeNewItemConfirmSnackbar={this.closeNewItemConfirmSnackbar}
                />

                <EmptyFieldSnackBar
                    openEmptyTextFieldSnackbar={this.state.openEmptyTextFieldSnackbar}
                    closeEmptyFieldSnackbar={this.closeEmptyFieldSnackbar}
                />
            </div>
        );
    }
}

EditCommodity.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EditCommodity);

/* The Cancel button's color was done initially implementing MUI override - https://material-ui.com/customization/overrides/
But later changed to regular inline style, as I was not able to differentiate the coloring the Cancel button with Save
*/
