import React, {Component} from "react";
import axios from "axios";
import PropTypes from "prop-types";
import history from "../../../history";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {withStyles} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {styles} from "../commonStyles/AddNewItemStyles";
import toolbarStyles from "../commonStyles/toolbarStyles";
import combineStyles from "../commonStyles/combineStyles";
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import EmptyFieldSnackBar from "./Snackbars/EmptyFieldSnackBar";
import EditItemConfirmSnackbar from "./Snackbars/EditItemConfirmSnackbar";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import Typography from "@material-ui/core/Typography";

class EditRevenue extends Component {

    state = {
        open: false,
        openItemEditedConfirmSnackbar: false,
        openEmptyTextFieldSnackbar: false,
        vertical: "top",
        horizontal: "center",
        revenue_year: this.props.itemToEdit[0].revenue_year,
        revenue_month: this.props.itemToEdit[0].revenue_month,
        revenue_amount: this.props.itemToEdit[0].revenue_amount,
        //Error Message handler
        errorRevenueYear: "",
        errorRevenueMonth: "",
        errorRevenueAmount: "",
        arrowRef: null
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node
        });
    };

    handleYearChange = date => {
        this.setState({
            revenue_year: date
        });
    };

    handleMonthChange = date => {
        this.setState({
            revenue_month: date
        });
    };

    handleToggle = () => {
        this.setState({
            open: !this.state.open
        });
    };

    handleFabOpen = () => {
        this.setState({open: true});
    };

    closeItemEditedConfirmSnackbar = () => {
        this.setState({openItemEditedConfirmSnackbar: false});
    };

    closeEmptyFieldSnackbar = () => {
        this.setState({openEmptyTextFieldSnackbar: false}, () => {
        });
    };

    handleEditFormSubmit = () => {
        const {editItemToParentState, itemToEdit} = this.props;

        const {
            revenue_year,
            revenue_month,
            revenue_amount,
        } = this.state;

        revenue_amount !== "" ? this.setState({errorRevenueAmount: ""}) : this.setState({errorRevenueAmount: "Amount should be > 0"});

        revenue_year === null && revenue_month === null ?
            this.setState({
                errorRevenueYear: "Select at least Year or Year & Month",
                errorRevenueMonth: "Select at least Year or Year & Month"
            })
            :
            revenue_year !== null && revenue_month !== null ?
                this.setState({
                    errorRevenueYear: "Select only Year or Year & Month",
                    errorRevenueMonth: "Select only Year or Year & Month"
                }) :
                this.setState({errorRevenueYear: "", errorRevenueMonth: ""});

        if (revenue_amount > 0 && ((revenue_year !== null && revenue_month === null) || (revenue_year === null && revenue_month !== null))) {
            axios
                .put(`/api/revenue/${itemToEdit[0]._id}`, {
                    revenue_year,
                    revenue_month,
                    revenue_amount,
                })
                .then((res) => {
                    editItemToParentState(res.data);
                    this.setState(
                        {
                            open: false,
                            openItemEditedConfirmSnackbar: true,
                            revenue_year: this.props.itemToEdit[0].revenue_year,
                            revenue_month: this.props.itemToEdit[0].revenue_month,
                            revenue_amount: this.props.itemToEdit[0].revenue_amount,
                            //Error Message handler
                            errorRevenueYear: "",
                            errorRevenueMonth: "",
                            errorRevenueAmount: "",
                        },
                        () => {
                            history.push("/dashboard/revenue");
                        }
                    );
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        history.push("/login");
                        alert("Please Login, session expired");
                    } else {
                        alert("Oops something wrong happened, please try again");
                    }
                });
        } else {
            this.setState({openEmptyTextFieldSnackbar: true});
        }
    };

    handleCancel = () => {
        this.setState({
            open: false,
            revenue_year: this.props.itemToEdit[0].revenue_year,
            revenue_month: this.props.itemToEdit[0].revenue_month,
            revenue_amount: this.props.itemToEdit[0].revenue_amount,
            //Error Message handler
            errorRevenueYear: "",
            errorRevenueMonth: "",
            errorRevenueAmount: "",
        });
    };

    handleEnterEscapeKeyPress = e => {
        if (e.key === "Enter") {
            this.handleEditFormSubmit();
        } else if (e.key === "Escape") {
            this.handleCancel();
        }
    };

    render() {
        const {classes, themeType} = this.props;
        const {
            revenue_year,
            revenue_month,
            revenue_amount,
            //Error Message handler
            errorRevenueYear,
            errorRevenueMonth,
            errorRevenueAmount,
        } = this.state;

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div>
                    <EditIcon
                        onClick={this.handleFabOpen}
                        className={classes.tableToolbarIcon}
                    />
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleToggle}
                        aria-labelledby="form-dialog-title"
                        fullWidth={true}
                        maxWidth={"sm"}
                        variant="contained"
                        PaperProps={{
                            classes: {
                                root: classes.paper
                            }
                        }}
                        onKeyDown={this.handleEnterEscapeKeyPress}
                    >
                        <DialogTitle
                            id="form-dialog-title"
                            disableTypography
                            className={themeType ? classes.dialogTitleEditDark : classes.dialogTitleEditLight}
                        >
                            <div className={classes.displayFlexDialogTitle}>
                                <Typography variant="h5" className={classes.dialogTitleHeading}>Edit Revenue
                                    Record</Typography>
                                <IconButton
                                    onClick={this.handleToggle}
                                    style={{float: "right"}}
                                    className={classes.button}
                                >
                                    <CancelIcon/>
                                </IconButton>
                            </div>
                        </DialogTitle>

                        <DialogContent required>

                            <div
                                style={{
                                    display: "flex",
                                    marginTop: "16px"
                                }}
                            >
                                <div style={{display: "flex", margin: "auto", width: "100%"}}>
                                    <DatePicker
                                        autoOk
                                        clearable
                                        views={["year"]}
                                        label="Revenue Year"
                                        value={revenue_year}
                                        onChange={this.handleYearChange}
                                        error={errorRevenueYear.length !== 0}
                                        helperText={errorRevenueYear}
                                        style={{width: "100%"}}
                                    />
                                </div>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    marginTop: "16px"
                                }}
                            >
                                <div style={{display: "flex", margin: "auto", width: "100%"}}>
                                    <DatePicker
                                        autoOk
                                        clearable
                                        views={["year", "month"]}
                                        label="Revenue Year & Month"
                                        value={revenue_month}
                                        onChange={this.handleMonthChange}
                                        error={errorRevenueMonth.length !== 0}
                                        helperText={errorRevenueMonth}
                                        style={{width: "100%"}}
                                    />
                                </div>
                            </div>

                            <TextField
                                required
                                classes={{
                                    root: classes.space
                                }}
                                value={revenue_amount}
                                onChange={e => {
                                    this.setState({
                                        revenue_amount: e.target.value
                                    })
                                }}
                                label="Export Amount"
                                type="string"
                                error={errorRevenueAmount.length !== 0}
                                helperText={errorRevenueAmount}
                                fullWidth
                            />

                        </DialogContent>

                        <DialogActions className={classes.dialogActions}>
                            <div className={classes.displayFlexDialogActions}>
                                <Button
                                    onClick={this.handleCancel}
                                    variant="contained"
                                    size="large"
                                    classes={{
                                        root: classes.spaceDialogAction
                                    }}
                                    style={{backgroundColor: "#ee0053"}}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={this.handleEditFormSubmit}
                                    classes={{
                                        root: classes.spaceDialogAction
                                    }}
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                >
                                    Update
                                </Button>
                            </div>
                        </DialogActions>

                    </Dialog>

                    <EditItemConfirmSnackbar
                        openItemEditedConfirmSnackbar={this.state.openItemEditedConfirmSnackbar}
                        closeItemEditedConfirmSnackbar={this.closeItemEditedConfirmSnackbar}
                    />

                    <EmptyFieldSnackBar
                        openEmptyTextFieldSnackbar={this.state.openEmptyTextFieldSnackbar}
                        closeEmptyFieldSnackbar={this.closeEmptyFieldSnackbar}
                    />

                </div>
            </MuiPickersUtilsProvider>
        );
    }
}

EditRevenue.propTypes = {
    classes: PropTypes.object.isRequired
};

const combinedStyles = combineStyles(styles, toolbarStyles);

export default withStyles(combinedStyles, {withTheme: true})(EditRevenue);
