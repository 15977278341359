import React, {Component} from "react";
//Material UI
import {withStyles} from "@material-ui/core";
import {styles} from "../commonStyles/AddNewItemStyles";
// import theme from "../commonStyles/AddNewItemThemes";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fab from "@material-ui/core/Fab";
// import {MenuItem} from "material-ui/Menu";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
//Date Pickers
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
// import {MuiPickersUtilsProvider} from "@material-ui/pickers";
//Icons
import CancelIcon from "@material-ui/icons/Cancel";
import AddIcon from "@material-ui/icons/Add";
//Snack Bars
import EmptyFieldSnackBar from "./Snackbars/EmptyFieldSnackBar";
import NewItemAddedConfirmSnackbar from "./Snackbars/NewItemAddedConfirmSnackbar";
//Others
import axios from "axios";
import PropTypes from "prop-types";
import history from "../../../history";

class AddNewOthers extends Component {

    state = {
        open: false,
        openNewItemAddedConfirmSnackbar: false,
        openEmptyTextFieldSnackbar: false,
        others_year: null,
        others_month: null,
        coastal_in: "",
        coastal_out: "",
        others: "",
        container_traffic_handled: "",
        percentage_utilization_of_traffic_handling_capacity_of_the_port: "",
        average_vehicle_turn_around_time: "",
        bulk_and_handymax: "",
        bulk_and_panamax: "",
        bulk_and_cape_size: "",
        containers: "",
        tankers: "",
        average_pre_berth_delays: "",
        total_cargo_evacuation_from_port: "",
        by_rail: "",
        by_road: "",
        by_conveyor: "",
        by_others: "",
        specific_information: "",
        //Error Message handler
        errorOthersYear: "",
        errorOthersMonth: "",
    };

    handleYearChange = date => {
        this.setState({
            others_year: date
        });
    };

    handleMonthChange = date => {
        this.setState({
            others_month: date
        });
    };

    handleToggle = () => {
        this.setState({
            open: !this.state.open
        });
    };

    handleFabOpen = () => {
        this.setState({open: true});
    };

    closeNewItemConfirmSnackbar = () => {
        this.setState({openNewItemAddedConfirmSnackbar: false});
    };

    // This below function may not be in use
    closeEmptyFieldSnackbar = () => {
        this.setState({openEmptyTextFieldSnackbar: false});
    };

    handleFormSubmit = () => {
        const {addNewItemToParentState} = this.props;
        const {
            others_year,
            others_month,
            coastal_in,
            coastal_out,
            others,
            container_traffic_handled,
            percentage_utilization_of_traffic_handling_capacity_of_the_port,
            average_vehicle_turn_around_time,
            bulk_and_handymax,
            bulk_and_panamax,
            bulk_and_cape_size,
            containers,
            tankers,
            average_pre_berth_delays,
            total_cargo_evacuation_from_port,
            by_rail,
            by_road,
            by_conveyor,
            by_others,
            specific_information
        } = this.state;

        (others_year === null && others_month === null) ?
            this.setState({
                errorOthersYear: "Select at least Year or Year & Month",
                errorOthersMonth: "Select at least Year or Year & Month"
            })
            :
            (others_year !== null && others_month !== null) ?
                this.setState({
                    errorOthersYear: "Select only Year or Year & Month",
                    errorOthersMonth: "Select only Year or Year & Month"
                }) :
                this.setState({errorOthersYear: "", errorOthersMonth: ""});

        if ((others_year !== null && others_month === null) || (others_year === null && others_month !== null)) {
            axios
                .post("/api/others/", {
                    others_year,
                    others_month,
                    coastal_in,
                    coastal_out,
                    others,
                    container_traffic_handled,
                    percentage_utilization_of_traffic_handling_capacity_of_the_port,
                    average_vehicle_turn_around_time,
                    bulk_and_handymax,
                    bulk_and_panamax,
                    bulk_and_cape_size,
                    containers,
                    tankers,
                    average_pre_berth_delays,
                    total_cargo_evacuation_from_port,
                    by_rail,
                    by_road,
                    by_conveyor,
                    by_others,
                    specific_information
                })
                .then(() => {
                    addNewItemToParentState({});
                    this.setState({
                        open: false,
                        openNewItemAddedConfirmSnackbar: true,
                        others_year: null,
                        others_month: null,
                        coastal_in: "",
                        coastal_out: "",
                        others: "",
                        container_traffic_handled: "",
                        percentage_utilization_of_traffic_handling_capacity_of_the_port: "",
                        average_vehicle_turn_around_time: "",
                        bulk_and_handymax: "",
                        bulk_and_panamax: "",
                        bulk_and_cape_size: "",
                        containers: "",
                        tankers: "",
                        average_pre_berth_delays: "",
                        total_cargo_evacuation_from_port: "",
                        by_rail: "",
                        by_road: "",
                        by_conveyor: "",
                        by_others: "",
                        specific_information: "",
                        //Error Message handler
                        errorOthersYear: "",
                        errorOthersMonth: "",
                    });
                    history.push("/dashboard/others");
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        history.push("/login");
                        alert("Please Login, session expired");
                    } else {
                        alert("Oops something wrong happened, please try again");
                    }
                });
        }
    };

    handleCancel = () => {
        this.setState({
            open: !this.state.open,
            others_year: null,
            others_month: null,
            coastal_in: "",
            coastal_out: "",
            others: "",
            container_traffic_handled: "",
            percentage_utilization_of_traffic_handling_capacity_of_the_port: "",
            average_vehicle_turn_around_time: "",
            bulk_and_handymax: "",
            bulk_and_panamax: "",
            bulk_and_cape_size: "",
            containers: "",
            tankers: "",
            average_pre_berth_delays: "",
            total_cargo_evacuation_from_port: "",
            by_rail: "",
            by_road: "",
            by_conveyor: "",
            by_others: "",
            specific_information: "",
            //Error Message handler
            errorOthersYear: "",
            errorOthersMonth: "",
        });
    };

    handleEnterEscapeKeyPress = e => {
        if (e.key === "Enter") {
            this.handleFormSubmit();
        } else if (e.key === "Escape") {
            this.handleCancel();
        }
    };

    render() {
        const {classes} = this.props;
        const {
            others_year,
            others_month,
            coastal_in,
            coastal_out,
            others,
            container_traffic_handled,
            percentage_utilization_of_traffic_handling_capacity_of_the_port,
            average_vehicle_turn_around_time,
            bulk_and_handymax,
            bulk_and_panamax,
            bulk_and_cape_size,
            containers,
            tankers,
            average_pre_berth_delays,
            total_cargo_evacuation_from_port,
            by_rail,
            by_road,
            by_conveyor,
            by_others,
            specific_information,
            //Error Message handler
            errorOthersYear,
            errorOthersMonth,
        } = this.state;

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div>
                    <Tooltip title="Add Others">
                        <Fab
                            onClick={this.handleFabOpen}
                            aria-pressed="true"
                            color="secondary"
                            size="large"
                            aria-label="Add"
                            className={classes.fabButton}
                        >
                            <AddIcon style={{width: 30, height: 30}}/>
                        </Fab>
                    </Tooltip>

                    <Dialog
                        open={this.state.open}
                        onClose={this.handleToggle}
                        aria-labelledby="form-dialog-title"
                        fullWidth={true}
                        maxWidth={"sm"}
                        variant="contained"
                        PaperProps={{
                            classes: {
                                root: classes.paper
                            }
                        }}
                        onKeyDown={this.handleEnterEscapeKeyPress}
                        disableBackdropClick
                    >
                        <DialogTitle
                            id="form-dialog-title"
                            disableTypography
                            className={classes.dialogTitleAdd}
                        >
                            <div className={classes.displayFlexDialogTitle}>
                                <Typography variant="h5" className={classes.dialogTitleHeading}>
                                    New Others
                                </Typography>
                                <IconButton
                                    onClick={this.handleToggle}
                                    style={{
                                        float: "right"
                                    }}
                                    className={classes.button}
                                >
                                    <CancelIcon/>
                                </IconButton>
                            </div>
                        </DialogTitle>

                        <DialogContent required>

                            <div
                                style={{
                                    display: "flex",
                                    marginTop: "16px"
                                }}
                            >
                                <div style={{display: "flex", margin: "auto", width: "100%"}}>
                                    <DatePicker
                                        autoOk
                                        clearable
                                        views={["year"]}
                                        label="Others Year"
                                        value={others_year}
                                        onChange={this.handleYearChange}
                                        error={errorOthersYear.length !== 0}
                                        helperText={errorOthersYear}
                                        style={{width: "100%"}}
                                    />
                                </div>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    marginTop: "16px"
                                }}
                            >
                                <div style={{display: "flex", margin: "auto", width: "100%"}}>
                                    <DatePicker
                                        autoOk
                                        clearable
                                        views={["year", "month"]}
                                        label="Others Year & Month"
                                        value={others_month}
                                        onChange={this.handleMonthChange}
                                        error={errorOthersMonth.length !== 0}
                                        helperText={errorOthersMonth}
                                        style={{width: "100%"}}
                                    />
                                </div>
                            </div>

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={coastal_in}
                                onChange={e => {
                                    this.setState({
                                        coastal_in: e.target.value
                                    })
                                }}
                                label="Coastal In (MT)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={coastal_out}
                                onChange={e => {
                                    this.setState({
                                        coastal_out: e.target.value
                                    })
                                }}
                                label="Coastal Out (MT)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={others}
                                onChange={e => {
                                    this.setState({
                                        others: e.target.value
                                    })
                                }}
                                label="Others"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={container_traffic_handled}
                                onChange={e => {
                                    this.setState({
                                        container_traffic_handled: e.target.value
                                    })
                                }}
                                label="Container Traffic Handled (TEUs)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={percentage_utilization_of_traffic_handling_capacity_of_the_port}
                                onChange={e => {
                                    this.setState({
                                        percentage_utilization_of_traffic_handling_capacity_of_the_port: e.target.value
                                    })
                                }}
                                label="Percentage utilization of Traffic handling capacity of the port (%)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={average_vehicle_turn_around_time}
                                onChange={e => {
                                    this.setState({
                                        average_vehicle_turn_around_time: e.target.value
                                    })
                                }}
                                label="Average vehicle turn around time(TAT) (Hrs)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={bulk_and_handymax}
                                onChange={e => {
                                    this.setState({
                                        bulk_and_handymax: e.target.value
                                    })
                                }}
                                label="Bulk & Handymax (Hrs)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={bulk_and_panamax}
                                onChange={e => {
                                    this.setState({
                                        bulk_and_panamax: e.target.value
                                    })
                                }}
                                label="Bulk & Panamax (Hrs)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={bulk_and_cape_size}
                                onChange={e => {
                                    this.setState({
                                        bulk_and_cape_size: e.target.value
                                    })
                                }}
                                label="Bulk & cape size (Hrs)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={containers}
                                onChange={e => {
                                    this.setState({
                                        containers: e.target.value
                                    })
                                }}
                                label="Containers (Hrs)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={tankers}
                                onChange={e => {
                                    this.setState({
                                        tankers: e.target.value
                                    })
                                }}
                                label="Tankers (Hrs)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={average_pre_berth_delays}
                                onChange={e => {
                                    this.setState({
                                        average_pre_berth_delays: e.target.value
                                    })
                                }}
                                label="Average pre berth delays (Hrs)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={total_cargo_evacuation_from_port}
                                onChange={e => {
                                    this.setState({
                                        total_cargo_evacuation_from_port: e.target.value
                                    })
                                }}
                                label="Total cargo evacuation from port (MT)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={by_rail}
                                onChange={e => {
                                    this.setState({
                                        by_rail: e.target.value
                                    })
                                }}
                                label="By Rail (MT)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={by_road}
                                onChange={e => {
                                    this.setState({
                                        by_road: e.target.value
                                    })
                                }}
                                label="By Road (MT)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={by_conveyor}
                                onChange={e => {
                                    this.setState({
                                        by_conveyor: e.target.value
                                    })
                                }}
                                label="By Conveyor (MT)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={by_others}
                                onChange={e => {
                                    this.setState({
                                        by_others: e.target.value
                                    })
                                }}
                                label="By Others (MT)"
                                type="string"
                                fullWidth
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={specific_information}
                                onChange={e => {
                                    this.setState({
                                        specific_information: e.target.value
                                    })
                                }}
                                label="Any other specific information to be shared"
                                type="string"
                                fullWidth
                            />

                        </DialogContent>

                        <DialogActions className={classes.dialogActions}>
                            <div className={classes.displayFlexDialogActions}>
                                <Button
                                    onClick={this.handleCancel}
                                    variant="contained"
                                    size="large"
                                    classes={{
                                        root: classes.spaceDialogAction
                                    }}
                                    style={{backgroundColor: "#ee0053"}}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    onClick={this.handleFormSubmit}
                                    classes={{
                                        root: classes.spaceDialogAction
                                    }}
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </div>
                        </DialogActions>

                    </Dialog>

                    <NewItemAddedConfirmSnackbar
                        openNewItemAddedConfirmSnackbar={
                            this.state.openNewItemAddedConfirmSnackbar
                        }
                        closeNewItemConfirmSnackbar={this.closeNewItemConfirmSnackbar}
                    />

                    <EmptyFieldSnackBar
                        openEmptyTextFieldSnackbar={this.state.openEmptyTextFieldSnackbar}
                        closeEmptyFieldSnackbar={this.closeEmptyFieldSnackbar}
                    />
                </div>
            </MuiPickersUtilsProvider>
        );
    }
}

AddNewOthers.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles, {withTheme: true})(AddNewOthers);
