import React, {Component} from "react";
//Material UI
import {withStyles} from "@material-ui/core";
import {styles} from "../commonStyles/AddNewItemStyles";
//import theme from "../commonStyles/AddNewItemThemes";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fab from "@material-ui/core/Fab";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
//Date Pickers
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
//Icons
import CancelIcon from "@material-ui/icons/Cancel";
import AddIcon from "@material-ui/icons/Add";
import UploadIcon from "@material-ui/icons/Publish";
//Snack Bars
import NewItemAddedConfirmSnackbar from "./Snackbars/NewItemAddedConfirmSnackbar";
//Others
import axios from "axios";
import PropTypes from "prop-types";
import history from "../../../history";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import DropZone from "../UtilFunctions/dropZone";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 550
        }
    }
};

const CargoType = ["Import", "Export", "Containers (MTs)"];
const VoyageType = ["Overseas", "Coastal"];
const FlagType = ["Indian Flag", "Foreign Flag"];

class AddNewImportExport extends Component {

    state = {
        speedDial: false,
        addRecord: false,
        excelUpload: false,

        cargo_type: "",
        commodity_objectId: "",
        qty_in_mts: "",
        year: null,
        month: null,
        no_of_vessels_per_month: null,
        voyage_type: "",
        flag_type: "",

        openNewItemAddedConfirmSnackbar: false,
        //Error Message handler
        errorQtyInMts: "",
        errorImportExportYear: "",
        errorImportExportMonth: "",
    };

    handleYearChange = date => {
        this.setState({
            year: date
        });
    };

    handleMonthChange = date => {
        this.setState({
            month: date
        });
    };


    handleSpeedDialOpen = () => {
        this.setState({
            speedDial: true
        });
    };

    handleSpeedDialClose = () => {
        this.setState({
            speedDial: false
        });
    };


    addRecordOpen = () => {
        this.setState({
            addRecord: true,
            speedDial: false
        });
    };

    addRecordSubmit = () => {
        const {addNewItemToParentState} = this.props;
        const {
            cargo_type,
            commodity_objectId,
            qty_in_mts,
            year,
            month,
            no_of_vessels_per_month,
            voyage_type,
            flag_type,
        } = this.state;

        qty_in_mts !== "" ? this.setState({errorQtyInMts: ""}) : this.setState({errorQtyInMts: "Enter Quantity"});

        (year === null && month === null) ?
            this.setState({
                errorImportExportYear: "Select at least Year or Year & Month",
                errorImportExportMonth: "Select at least Year or Year & Month"
            })
            :
            (year !== null && month !== null) ?
                this.setState({
                    errorImportExportYear: "Select only Year or Year & Month",
                    errorImportExportMonth: "Select only Year or Year & Month"
                }) :
                this.setState({errorImportExportYear: "", errorImportExportMonth: ""});

        if (qty_in_mts !== "" && ((year !== null && month === null) || (year === null && month !== null))) {
            axios
                .post("/api/importsExports/", {
                    cargo_type,
                    commodity_objectId,
                    qty_in_mts,
                    year,
                    month,
                    no_of_vessels_per_month,
                    voyage_type,
                    flag_type,
                })
                .then(() => {
                    addNewItemToParentState({});
                    this.setState({
                        addRecord: false,
                        openNewItemAddedConfirmSnackbar: true,
                        cargo_type: null,
                        commodity_objectId: null,
                        qty_in_mts: "",
                        year: null,
                        month: null,
                        no_of_vessels_per_month: null,
                        voyage_type: "",
                        flag_type: "",
                        //Error Message handler
                        errorQtyInMts: "",
                        errorImportExportYear: "",
                        errorImportExportMonth: "",
                    });
                    history.push("/dashboard/importsExports");
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        history.push("/login");
                        alert("Please Login, session expired");
                    } else {
                        alert("Oops something wrong happened, please try again");
                    }
                });
        }
    };

    addRecordCancel = () => {
        this.setState({
            addRecord: false,
            cargo_type: "",
            commodity_objectId: "",
            qty_in_mts: "",
            year: null,
            month: null,
            no_of_vessels_per_month: null,
            voyage_type: "",
            flag_type: "",
            //Error Message handler
            errorQtyInMts: "",
            errorImportExportYear: "",
            errorImportExportMonth: "",
        });
    };


    excelUploadOpen = () => {
        this.setState({
            excelUpload: true,
            speedDial: false
        });
    };

    excelUploadCancel = () => {
        this.setState({
            excelUpload: false,
        });
    };


    closeNewItemConfirmSnackbar = () => {
        this.setState({openNewItemAddedConfirmSnackbar: false});
    };

    render() {
        const {classes, allCommoditiesForSiblingCommunication} = this.props;
        const {
            speedDial,
            addRecord,
            excelUpload,
            cargo_type,
            commodity_objectId,
            qty_in_mts,
            year,
            month,
            no_of_vessels_per_month,
            voyage_type,
            flag_type,
            //Error Message handler
            errorQtyInMts,
            errorImportExportYear,
            errorImportExportMonth,
        } = this.state;

        const actions = [
            {icon: <UploadIcon/>, name: 'Excel Upload', action: this.excelUploadOpen},
            {icon: <AddIcon/>, name: 'Add Record', action: this.addRecordOpen},
        ];

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div>
                    <SpeedDial
                        ariaLabel="SpeedDial example"
                        className={classes.speedDial}
                        icon={<AddIcon style={{width: 30, height: 30}}/>}
                        onOpen={this.handleSpeedDialOpen}
                        onClose={this.handleSpeedDialClose}
                        open={speedDial}
                    >
                        {actions.map((action) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={action.action}
                            />
                        ))}
                    </SpeedDial>

                    <Dialog
                        open={addRecord}
                        aria-labelledby="add-record-dialog"
                        fullWidth={true}
                        maxWidth={"sm"}
                        variant="contained"
                        PaperProps={{
                            classes: {
                                root: classes.paper
                            }
                        }}
                        disableBackdropClick
                    >
                        <DialogTitle
                            id="add-record-dialog-title"
                            disableTypography
                            className={classes.dialogTitleAdd}
                        >
                            <div className={classes.displayFlexDialogTitle}>
                                <Typography variant="h5" className={classes.dialogTitleHeading}>
                                    Add Record
                                </Typography>
                            </div>
                        </DialogTitle>

                        <DialogContent required>

                            <TextField
                                select
                                label="Cargo Type"
                                classes={{
                                    root: classes.space
                                }}
                                value={cargo_type}
                                onChange={e => {
                                    this.setState({
                                        cargo_type: e.target.value
                                    })
                                }}
                                input={<Input id="select-multiple"/>}
                                menuprops={MenuProps}
                            >
                                {CargoType.map(item => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                select
                                label="Select Commodity"
                                classes={{
                                    root: classes.space
                                }}
                                value={commodity_objectId}
                                onChange={e => {
                                    this.setState({
                                        commodity_objectId: e.target.value
                                    })
                                }}
                                input={<Input id="select-multiple"/>}
                                menuprops={MenuProps}
                            >
                                {allCommoditiesForSiblingCommunication.map(item => (
                                    <MenuItem key={item.name} value={item._id}>
                                        {item.name} ({item.type !== undefined ? item.type !== null ? item.type.typeName : "" : ""} - {item.category !== undefined ? item.category !== null ? item.category.categoryName : "None" : "None"})
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                required
                                classes={{
                                    root: classes.space
                                }}
                                value={qty_in_mts}
                                onChange={e => {
                                    this.setState({
                                        qty_in_mts: e.target.value
                                    })
                                }}
                                label="Quantity (M. Tons)"
                                type="number"
                                inputProps={{min: "0"}}
                                error={errorQtyInMts.length !== 0}
                                helperText={errorQtyInMts}
                            />

                            <DatePicker
                                autoOk
                                clearable
                                views={["year"]}
                                label="Year"
                                value={year}
                                onChange={this.handleYearChange}
                                classes={{
                                    root: classes.space
                                }}
                                error={errorImportExportYear.length !== 0}
                                helperText={errorImportExportYear}
                            />

                            <DatePicker
                                autoOk
                                clearable
                                views={["year", "month"]}
                                label="Year & Month"
                                value={month}
                                onChange={this.handleMonthChange}
                                classes={{
                                    root: classes.space
                                }}
                                error={errorImportExportMonth.length !== 0}
                                helperText={errorImportExportMonth}
                            />

                            <TextField
                                classes={{
                                    root: classes.space
                                }}
                                value={no_of_vessels_per_month}
                                onChange={e =>
                                    this.setState({
                                        no_of_vessels_per_month: e.target.value
                                    })
                                }
                                label="No of Vessels per Month"
                                type="number"
                                inputProps={{min: "0"}}
                            />

                            <TextField
                                select
                                label="Voyage Type"
                                classes={{
                                    root: classes.space
                                }}
                                value={voyage_type}
                                onChange={e => {
                                    this.setState({
                                        voyage_type: e.target.value
                                    })
                                }}
                                input={<Input id="select-multiple"/>}
                                menuprops={MenuProps}
                            >
                                {VoyageType.map(item => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                select
                                label="Flag Type"
                                classes={{
                                    root: classes.space
                                }}
                                value={flag_type}
                                onChange={e => {
                                    this.setState({
                                        flag_type: e.target.value
                                    })
                                }}
                                input={<Input id="select-multiple"/>}
                                menuprops={MenuProps}
                            >
                                {FlagType.map(item => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </DialogContent>

                        <DialogActions className={classes.dialogActions}>
                            <div className={classes.displayFlexDialogActions}>
                                <Button
                                    onClick={this.addRecordCancel}
                                    variant="contained"
                                    size="large"
                                    classes={{
                                        root: classes.spaceDialogAction
                                    }}
                                    style={{backgroundColor: "#ee0053"}}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    onClick={this.addRecordSubmit}
                                    classes={{
                                        root: classes.spaceDialogAction
                                    }}
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </div>
                        </DialogActions>

                    </Dialog>

                    <Dialog
                        open={excelUpload}
                        aria-labelledby="upload-excel-dialog"
                        fullWidth={true}
                        maxWidth={"sm"}
                        variant="contained"
                        PaperProps={{
                            classes: {
                                root: classes.paper
                            }
                        }}
                        disableBackdropClick
                    >
                        <DialogTitle
                            id="upload-excel-dialog-title"
                            disableTypography
                            className={classes.dialogTitleAdd}
                        >
                            <div className={classes.displayFlexDialogTitle}>
                                <Typography variant="h5" className={classes.dialogTitleHeading}>
                                    Upload Excel
                                </Typography>
                            </div>
                        </DialogTitle>

                        <DialogContent required>
                            <DropZone/>
                        </DialogContent>

                        <DialogActions className={classes.dialogActions}>
                            <div className={classes.displayFlexDialogActions}>
                                <Button
                                    onClick={this.excelUploadCancel}
                                    variant="contained"
                                    size="large"
                                    classes={{
                                        root: classes.spaceDialogAction
                                    }}
                                    style={{backgroundColor: "#ee0053"}}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    // onClick={this.handleFormSubmit}
                                    classes={{
                                        root: classes.spaceDialogAction
                                    }}
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                >
                                    Upload
                                </Button>
                            </div>
                        </DialogActions>

                    </Dialog>

                    <NewItemAddedConfirmSnackbar
                        openNewItemAddedConfirmSnackbar={
                            this.state.openNewItemAddedConfirmSnackbar
                        }
                        closeNewItemConfirmSnackbar={this.closeNewItemConfirmSnackbar}
                    />
                </div>
            </MuiPickersUtilsProvider>
        );
    }
}

export default withStyles(styles, {withTheme: true})(AddNewImportExport);
