import React, {Component} from "react";
import axios from "axios";
import classNames from "classnames";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import toolbarStyles from "../commonStyles/toolbarStyles";
import {CSVLink} from "react-csv";
import EmailAlreadyInUseSnackBar from "./Snackbars/EmailAlreadyInUseSnackBar";
import InvitationSentConfirmSnackbar from "./Snackbars/InvitationSentConfirmSnackbar";
import ShowOnBoardPort from "./ShowOnBoardPort";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import TextField from "@material-ui/core/TextField";
import random from "randomatic";
import DownloadIcon from "@material-ui/icons/GetApp";

const _ = require("lodash");

const OnBoardPortHeaders = [
    {label: "S.No", key: "sno"},
    {label: "Port Name", key: "name"},
    {label: "Port Operator", key: "key_contact"},
    {label: "Port Email", key: "email"},
    {label: "Contact Number", key: "phone_number"},
    {label: "Address", key: "address"},
];

class TableToolbarOnBoardPort extends Component {
    state = {
        shouldSearchFilterCompOpen: false,
        openInvitationSentConfirmSnackbar: false,
        openEmailAlreadyInUseSnackbar: false,
        searchValue: "",
        searchSelection: "",
        dateRangeTooltip: "",
        textFilterCancelTooltip: "",
        invitationDialog: false,
        portName: "",
        portEmail: "",
        errorPortName: "",
        errorPortEmail: "",
        arrowRef: null,
        dataToDownload: [],
        downloadFileName: "",
        downloadFileHeaders: []
    };

    // function to handle the placement of the arrow on top of the Tooltip
    handleArrowRef = node => {
        this.setState({
            arrowRef: node
        });
    };

    closeInvitationSentConfirmSnackbar = () => {
        this.setState({openInvitationSentConfirmSnackbar: false});
    };

    closeEmailAlreadyInUseSnackbar = () => {
        this.setState({openEmailAlreadyInUseSnackbar: false});
    };

    handleInviteOpen = () => {
        this.setState({
            invitationDialog: true
        });
    };

    handleInviteClose = () => {
        this.setState({
            invitationDialog: false,
            portName: "",
            portEmail: "",
        });
    };

    validateEmail = emailField => {
        let reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;

        if (reg.test(emailField) === false) {
            this.setState({
                errorPortEmail: "Please enter valid Email Id",
                portEmail: ""
            });
            return false;
        }
        return true;
    };

    handleInvitationSubmit = () => {
        const {portName, portEmail} = this.state;
        const defaultPassword = random("aA0", 10);
        if (portName !== "" && portEmail !== "") {
            if (this.validateEmail(portEmail)) {
                axios.post("/api/portInvite/", {
                    port_name: portName,
                    port_email: portEmail,
                    password: defaultPassword
                }).then((res) => {
                    if (res.data.status === "registered" ) {
                         this.setState({
                             openEmailAlreadyInUseSnackbar: true,
                             portName: "",
                             portEmail: ""
                         })
                    } else {
                        console.log("Before Default");
                        axios
                            .post("/api/portInvite/sendDefaultPasswordToPort", {
                                portName,
                                portEmail,
                                defaultPassword
                            })
                            .then(() => {
                                this.setState({
                                    openInvitationSentConfirmSnackbar: true,
                                    invitationDialog: false,
                                    portName: "",
                                    portEmail: ""
                                });
                            });
                    }
                });
            }
        }
    };

    handleDataDownload = (data) => {
        if (data.length !== 0) {
            const onBoardPortData = data.map(item => Object.assign({}, item));

            _.forEach(onBoardPortData, (object, index) => {
                object.sno = index + 1;
            });

            this.setState({
                dataToDownload: onBoardPortData,
                downloadFileName: "OnBoardPort.csv",
                downloadFileHeaders: OnBoardPortHeaders
            }, () => {
                this.csvLink.link.click();
            });
        }
    };

    render() {
        const {
            numSelected,
            onBoardPortToEdit,
            classes,
            fullScreen,
            themeType
        } = this.props;
        const {
            invitationDialog,
            portName,
            portEmail,
            errorPortName,
            errorPortEmail,
            dataToDownload,
            downloadFileName,
            downloadFileHeaders,
        } = this.state;

        return (
            <Toolbar
                className={classNames(classes.root, {
                    [classes.highlight]: numSelected > 0
                })}
            >
                <div className={classes.title}>
                    {numSelected === 1 ? (
                        <Typography color="inherit" variant="h3">
                            {numSelected} item selected
                        </Typography>
                    ) : numSelected > 1 ? (
                        <Typography color="inherit" variant="h3">
                            {numSelected} items selected
                        </Typography>
                    ) : null}
                </div>

                <div className={classes.spacer}/>

                <div className={classes.actions}>
                    {numSelected > 0 ? (
                        <div style={{display: "flex", flexDirection: "row"}}>

                            {numSelected !== 0 ? (
                                <Tooltip title="Download data for the selected item(s)">
                                    <div
                                        className={classes.csvDownloadSingle}
                                        onClick={() => this.handleDataDownload(this.props.downloadSelectedItems)}
                                    >
                                        <IconButton>
                                            <DownloadIcon
                                                className={classes.tableToolbarIcon}
                                            />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            ) : null}

                            {numSelected === 1 ? (
                                <Tooltip title="View Item Details">
                                    <IconButton>
                                        <ShowOnBoardPort
                                            onBoardPortToEdit={onBoardPortToEdit}
                                            checkedItems={this.props.checkedItems}
                                            themeType={themeType}
                                        />
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                        </div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                margin: "auto",
                                width: "100%",
                            }}
                        >
                            <Button
                                color="primary"
                                className={classes.buttonInvitation}
                                onClick={this.handleInviteOpen}
                            >
                                Send Invitation
                            </Button>

                            <Dialog
                                fullScreen={fullScreen}
                                open={invitationDialog}
                                onClose={this.handleInviteClose}
                                aria-labelledby="responsive-dialog-title"
                                fullWidth={true}
                                maxWidth={"sm"}
                                variant="contained"
                            >
                                <DialogTitle id="responsive-dialog-title">
                                    {"Send Invitation"}
                                </DialogTitle>
                                <DialogContent>
                                    <div
                                        style={{
                                            width: "552px",
                                            height: "144px"
                                        }}
                                    >
                                        <TextField
                                            required
                                            id="standard-required"
                                            label="Port Name (Display Name)"
                                            value={portName}
                                            className={classes.textFieldInvitation}
                                            margin="normal"
                                            onChange={e => {
                                                if (e.target.value === "") {
                                                    this.setState({
                                                        errorPortName: "Please enter Port Name",
                                                        portName: ""
                                                    });
                                                } else {
                                                    this.setState({
                                                        portName: e.target.value,
                                                        errorPortName: ""
                                                    });
                                                }
                                            }}
                                            error={errorPortName.length !== 0}
                                            helperText={errorPortName}
                                            type="string"
                                            fullWidth
                                        />
                                        <TextField
                                            required
                                            id="standard-required"
                                            label="Port Email (Official Mail)"
                                            value={portEmail}
                                            className={classes.textFieldInvitation}
                                            margin="normal"
                                            onChange={e => {
                                                if (e.target.value === "") {
                                                    this.setState({
                                                        errorPortEmail: "Please enter Port Email",
                                                        portEmail: ""
                                                    });
                                                } else {
                                                    this.setState({
                                                        portEmail: e.target.value,
                                                        errorPortEmail: ""
                                                    });
                                                }
                                            }}
                                            error={errorPortEmail.length !== 0}
                                            helperText={errorPortEmail}
                                            type="string"
                                            fullWidth
                                        />
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={this.handleInviteClose}
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        style={{
                                            backgroundColor: "#ee0053"
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={this.handleInvitationSubmit}
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        type="submit"
                                        disabled={portName === "" || portEmail === ""}
                                        autoFocus
                                    >
                                        Send
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    )}

                    <InvitationSentConfirmSnackbar
                        openInvitationSentConfirmSnackbar={
                            this.state.openInvitationSentConfirmSnackbar
                        }
                        closeInvitationSentConfirmSnackbar={
                            this.closeInvitationSentConfirmSnackbar
                        }
                    />

                    <EmailAlreadyInUseSnackBar
                        openEmailAlreadyInUseSnackbar={this.state.openEmailAlreadyInUseSnackbar}
                        closeEmailAlreadyInUseSnackbar={this.closeEmailAlreadyInUseSnackbar}
                    />

                    <CSVLink
                        data={dataToDownload}
                        headers={downloadFileHeaders}
                        filename={downloadFileName}
                        ref={r => (this.csvLink = r)}
                    />

                </div>
            </Toolbar>
        );
    }
}

TableToolbarOnBoardPort.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withMobileDialog()(
    withStyles(toolbarStyles)(TableToolbarOnBoardPort)
);
