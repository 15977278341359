import React, {Component} from "react";
import {Route, BrowserRouter, Switch, Router} from "react-router-dom";
import Login from "./Components/DashBoardViewComponents/Login/Login";
import AppDashBoard from "./Components/DashBoardViewComponents/App";
import PortRegistration from "./Components/DashBoardViewComponents/PortInviteRegistration/PortInviteRegistration";
import PasswordReset from "./Components/DashBoardViewComponents/Login/PasswordReset";
import history from "./history";
import NotFound from "./Components/DashBoardViewComponents/NotFound";

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <Router history={history}>
                    <div>
                        <Switch>
                            <Route exact path={"/login"} component={Login}/>

                            <Route
                                exact
                                path={"/port-registration/:email/:id"}
                                component={PortRegistration}
                            />

                            <Route path={"/password-reset"} component={PasswordReset}/>

                            <Route path={"/"} component={AppDashBoard}/>

                            <Route component={NotFound}/>
                        </Switch>
                    </div>
                </Router>
            </BrowserRouter>
        );
    }
}

export default App;
