import React, {Component} from "react";
import axios from "axios";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core";
import {styles} from "../commonStyles/ModuleItemListStyles";
import toolbarStyles from "../commonStyles/toolbarStyles";
import combineStyles from "../commonStyles/combineStyles";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Table from "@material-ui/core/Table";

import Paper from "@material-ui/core/Paper";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import {Helmet} from "react-helmet";
import setAxiosAuthheaders from "../UtilFunctions/setAxiosAuthheaders";
import {Col, Row} from "reactstrap";

import EnhancedTableHead from "./EnhancedTableHead";
import CommonTableBody from "./CommonTableBody";
import NoRecordsFoundSnackbar from "./Snackbars/NoRecordsFoundSnackbar";
import SearchBeforeDownload from "./Snackbars/SearchBeforeDownloadSnackbar";

import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import {CSVLink} from "react-csv";

const _ = require("lodash");
const moment = require("moment");

let TabContainer = props => {
    return (
        <Typography component="div" style={{padding: 8 * 2}}>
            {props.children}
        </Typography>
    );
};

const ImportHeaders = [
    {label: "S.No", key: "sno"},
    {label: "Commodity", key: "commodity"},
    {label: "Quantity (M.Tons)", key: "tons"},
    {label: "No of Vessels", key: "vessels"}
];

const ExportHeaders = [
    {label: "S.No", key: "sno"},
    {label: "Commodity", key: "commodity"},
    {label: "Quantity (M.Tons)", key: "tons"},
    {label: "No of Vessels", key: "vessels"}
];

const RevenueHeaders = [
    {label: "S.No", key: "sno"},
    {label: "Revenue Year", key: "revenue_year"},
    {label: "Revenue Month", key: "revenue_month"},
    {label: "Revenue Amount", key: "revenue_amount"},
];

// const OtherVesselHeaders = [
//     {label: "S.No", key: "sno"},
//     {label: "Vessel Type", key: "vesselType"},
//     {label: "No of Vessels", key: "noOfVessels"}
// ];
//
// const ParcelSizeHeaders = [
//     {label: "S.No", key: "sno"},
//     {label: "Commodity", key: "commodity"},
//     {label: "No of Vessels", key: "noOfVessels"},
//     {label: "Total Cargo (In Tonnes)", key: "totalCargo"},
//     {label: "Avg. Parcel Size (In Tonnes)", key: "avgParcelSize"},
//     {label: "Total Ship Size (DWT) in Tonnes", key: "totalShipSize"},
//     {label: "Avg. Ship Size (DWT) in Tonnes", key: "avgShipSize"}
// ];
//
// const PreBerthingWaitingHeaders = [
//     {label: "S.No", key: "sno"},
//     {label: "Commodity", key: "commodity"},
//     {
//         label: "Avg. Pre Berthing Waiting Time of Ships(in hh:mm:ss) Port Account",
//         key: "avgPreBerthingWaitingTimePort"
//     },
//     {
//         label:
//             "Avg. Pre Berthing Waiting Time of Ships(in hh:mm:ss) On-Non Port Account",
//         key: "avgPreBerthingWaitingTimeNonPort"
//     }
// ];
//
// const BerthThroughPutHeaders = [
//     {label: "S.No", key: "sno"},
//     {label: "Commodity", key: "commodity"},
//     {
//         label: "Avg. Ship Berth Throughput(in Tonnes)",
//         key: "avgShipBerthThroughPut"
//     },
//     {
//         label: "Standard Ship Berth Throughput (in Tonnes)",
//         key: "shipBerthThroughPut"
//     }
// ];
//
// const IncomeAndPortDuesHeaders = [
//     {label: "S.No", key: "sno"},
//     {label: "Berth Hire", key: "berthHire"},
//     {label: "Wharfage", key: "wharfage"},
//     {label: "Other Services", key: "otherServices"},
//     {label: "Port Dues", key: "portDues"},
//     {label: "Total", key: "total"}
// ];
//
// const DevelopmentWorkHeaders = [
//     {label: "S.No", key: "sno"},
//     {label: "Location", key: "location"},
//     {label: "Work Description", key: "workDescription"},
//     {label: "Status of Work", key: "statusOfWork"},
//     {label: "Date of Commencement", key: "dateOfCommencement"},
//     {label: "Date of Completion", key: "dateOfCompletion"}
// ];
//
// const IncomeHeaders = [
//     {label: "S.No", key: "sno"},
//     {label: "Commodity Name", key: "commodityName"},
//     {label: "Imports Amount (Rs)", key: "importsAmount"},
//     {label: "Exports Amount (Rs)", key: "exportsAmount"},
// ];
//
// const BerthingTurnAroundTimeHeaders = [
//     {label: "S.No", key: "sno"},
//     {label: "Vessel Name", key: "vesselName"},
//     {label: "Date of Arrival", key: "dateOfArrival"},
//     {label: "Berthing Time", key: "berthingTime"},
//     {label: "Un Berthing Time", key: "unBerthingTme"},
//     {label: "Turn Around Time", key: "turnAroundTime"},
//     {label: "Date of Departure", key: "dateOfDeparture"},
// ];
//
// const TruckingTripsHeaders = [
//     {label: "S.No", key: "sno"},
//     {label: "No of Trucks in a month", key: "noOfTrucksInMonth"},
//     {label: "Total No of Trips in a month", key: "totalNoOfTripsInMonth"},
//     {label: "Trips Month", key: "tripsMonth"},
// ];

class ReportsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            order: "desc",
            orderBy: "s_no",

            fromDate: new Date(),
            toDate: new Date(),

            portName: "",
            selectPortValue: "",

            portListData: [],

            dataToDownload: [],
            downloadFileName: "",
            downloadFileHeaders: [],

            allImportsData: [],
            allExportsData: [],
            allRevenueData: [],
            // allOtherVesselsData: [],
            // allParcelSizeData: [],
            // allPreBerthingWaitingData: [],
            // allBerthThroughPutData: [],
            // allIncomeAndPortDuesData: [],
            // allDevelopmentWorksData: [],
            // allIncomeData: [],
            // allBerthingTurnAroundTimeData: [],
            // allTruckingTripsData: [],

            errorToDate: "",
            errorFromDate: "",
            errorSelectPortValue: "",

            openNoRecordFoundSnackbar: false,
            openSearchBeforeDownloadSnackbar: false
        };
    }

    componentWillMount() {
        setAxiosAuthheaders();
    }

    componentDidMount() {
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).userType === "port-operator"
            ? this.setState({
                portName: JSON.parse(localStorage.getItem("user")).port,
                selectPortValue: JSON.parse(localStorage.getItem("user")).port_id
            })
            : axios.get("/api/onBoardPort/").then(res => {
                this.setState({
                    portListData: res.data
                });
            });
    }

    handleChange = (event, value) => {
        this.setState({value});
    };

    handleFromDateChange = date => {
        this.setState({
            fromDate: date,
            errorFromDate: "",
            errorToDate: ""
        });
    };

    handleToDateChange = date => {
        this.setState({
            toDate: date,
            errorToDate: ""
        });
    };

    handleDataDownload = () => {
        const {
            allImportsData,
            allExportsData,
            allRevenueData,
            // allOtherVesselsData,
            // allParcelSizeData,
            // allPreBerthingWaitingData,
            // allBerthThroughPutData,
            // allIncomeAndPortDuesData,
            // allDevelopmentWorksData,
            // allIncomeData,
            // allBerthingTurnAroundTimeData,
            // allTruckingTripsData,
        } = this.state;

        if (this.state.value === 0 && allImportsData.length !== 0) {
            const importData = allImportsData.map(item => Object.assign({}, item));

            _.forEach(importData, (object, index) => {
                object.sno = index + 1;
            });

            this.setState(
                {
                    dataToDownload: importData,
                    downloadFileName: "Imports.csv",
                    downloadFileHeaders: ImportHeaders
                },
                () => {
                    this.csvLink.link.click();
                }
            );
        } else if (this.state.value === 1 && allExportsData.length !== 0) {
            const exportsData = allExportsData.map(item => Object.assign({}, item));

            _.forEach(exportsData, (object, index) => {
                object.sno = index + 1;
            });

            this.setState(
                {
                    dataToDownload: exportsData,
                    downloadFileName: "Exports.csv",
                    downloadFileHeaders: ExportHeaders
                },
                () => {
                    this.csvLink.link.click();
                }
            );
        } else if (this.state.value === 1 && allRevenueData.length !== 0) {
            const revenueData = allRevenueData.map(item => Object.assign({}, item));

            _.forEach(revenueData, (object, index) => {
                object.sno = index + 1;
            });

            this.setState(
                {
                    dataToDownload: revenueData,
                    downloadFileName: "Revenue.csv",
                    downloadFileHeaders: RevenueHeaders
                },
                () => {
                    this.csvLink.link.click();
                }
            );
        }
            // else if (this.state.value === 2 && allOtherVesselsData.length !== 0) {
            //     const otherVesselsData = allOtherVesselsData.map(item =>
            //         Object.assign({}, item)
            //     );
            //
            //     _.forEach(otherVesselsData, (object, index) => {
            //         object.sno = index + 1;
            //     });
            //
            //     this.setState(
            //         {
            //             dataToDownload: otherVesselsData,
            //             downloadFileName: "OtherVessels.csv",
            //             downloadFileHeaders: OtherVesselHeaders
            //         },
            //         () => {
            //             this.csvLink.link.click();
            //         }
            //     );
            // } else if (this.state.value === 3 && allParcelSizeData.length !== 0) {
            //     const parcelSize = allParcelSizeData.map(item => Object.assign({}, item));
            //
            //     _.forEach(parcelSize, (object, index) => {
            //         object.sno = index + 1;
            //     });
            //
            //     this.setState(
            //         {
            //             dataToDownload: parcelSize,
            //             downloadFileName: "ParcelSize.csv",
            //             downloadFileHeaders: ParcelSizeHeaders
            //         },
            //         () => {
            //             this.csvLink.link.click();
            //         }
            //     );
            // } else if (
            //     this.state.value === 4 &&
            //     allPreBerthingWaitingData.length !== 0
            // ) {
            //     const preBerthingWaitingData = allPreBerthingWaitingData.map(item =>
            //         Object.assign({}, item)
            //     );
            //
            //     _.forEach(preBerthingWaitingData, (object, index) => {
            //         let port = object.avgPreBerthingWaitingTimePort;
            //         let nonPort = object.avgPreBerthingWaitingTimeNonPort;
            //
            //         object.sno = index + 1;
            //         object.avgPreBerthingWaitingTimePort =
            //             ~~(port / 3600) +
            //             " Hour, " +
            //             ~~((port % 3600) / 60) +
            //             " Minutes, " +
            //             ~~(port % 60) +
            //             " Seconds";
            //
            //         object.avgPreBerthingWaitingTimeNonPort =
            //             ~~(nonPort / 3600) +
            //             " Hour, " +
            //             ~~((nonPort % 3600) / 60) +
            //             " Minutes, " +
            //             ~~(nonPort % 60) +
            //             " Seconds";
            //     });
            //
            //     this.setState(
            //         {
            //             dataToDownload: preBerthingWaitingData,
            //             downloadFileName: "PreBerthingWaiting.csv",
            //             downloadFileHeaders: PreBerthingWaitingHeaders
            //         },
            //         () => {
            //             this.csvLink.link.click();
            //         }
            //     );
            // } else if (this.state.value === 5 && allBerthThroughPutData.length !== 0) {
            //     const berthThroughPut = allBerthThroughPutData.map(item =>
            //         Object.assign({}, item)
            //     );
            //
            //     _.forEach(berthThroughPut, (object, index) => {
            //         object.sno = index + 1;
            //     });
            //
            //     this.setState(
            //         {
            //             dataToDownload: berthThroughPut,
            //             downloadFileName: "BerthThroughPut.csv",
            //             downloadFileHeaders: BerthThroughPutHeaders
            //         },
            //         () => {
            //             this.csvLink.link.click();
            //         }
            //     );
            // } else if (
            //     this.state.value === 6 &&
            //     allIncomeAndPortDuesData.length !== 0
            // ) {
            //     const incomeAndPortDues = allIncomeAndPortDuesData.map(item =>
            //         Object.assign({}, item)
            //     );
            //
            //     _.forEach(incomeAndPortDues, (object, index) => {
            //         object.sno = index + 1;
            //     });
            //
            //     this.setState(
            //         {
            //             dataToDownload: incomeAndPortDues,
            //             downloadFileName: "IncomeAndPortDues.csv",
            //             downloadFileHeaders: IncomeAndPortDuesHeaders
            //         },
            //         () => {
            //             this.csvLink.link.click();
            //         }
            //     );
            // } else if (this.state.value === 7 && allDevelopmentWorksData !== 0) {
            //     const developmentWorksData = allDevelopmentWorksData.map(item =>
            //         Object.assign({}, item)
            //     );
            //
            //     _.forEach(developmentWorksData, (object, index) => {
            //         let comm = object.dateOfCommencement;
            //         let comp = object.dateOfCompletion;
            //
            //         object.sno = index + 1;
            //         object.dateOfCommencement =
            //             new Date(comm).getFullYear() +
            //             "-" +
            //             (new Date(comm).getMonth() + 1) +
            //             "-" +
            //             new Date(comm).getDate();
            //
            //         object.dateOfCompletion =
            //             new Date(comp).getFullYear() +
            //             "-" +
            //             (new Date(comp).getMonth() + 1) +
            //             "-" +
            //             new Date(comp).getDate();
            //     });
            //
            //     this.setState(
            //         {
            //             dataToDownload: developmentWorksData,
            //             downloadFileName: "DevelopmentWorks.csv",
            //             downloadFileHeaders: DevelopmentWorkHeaders
            //         },
            //         () => {
            //             this.csvLink.link.click();
            //         }
            //     );
            // } else if (this.state.value === 8 && allIncomeData !== 0) {
            //     const incomeData = allIncomeData.map(item =>
            //         Object.assign({}, item)
            //     );
            //
            //     _.forEach(incomeData, (object, index) => {
            //
            //         object.sno = index + 1;
            //     });
            //
            //     this.setState(
            //         {
            //             dataToDownload: incomeData,
            //             downloadFileName: "Revenue.csv",
            //             downloadFileHeaders: IncomeHeaders
            //         },
            //         () => {
            //             this.csvLink.link.click();
            //         }
            //     );
            // } else if (this.state.value === 9 && allBerthingTurnAroundTimeData.length !== 0) {
            //     const berthingTurnAroundTime = allBerthingTurnAroundTimeData.map(item =>
            //         Object.assign({}, item)
            //     );
            //
            //     _.forEach(berthingTurnAroundTime, (object, index) => {
            //         object.sno = index + 1;
            //     });
            //
            //     this.setState(
            //         {
            //             dataToDownload: berthingTurnAroundTime,
            //             downloadFileName: "BerthingTurnAroundTime.csv",
            //             downloadFileHeaders: BerthingTurnAroundTimeHeaders
            //         },
            //         () => {
            //             this.csvLink.link.click();
            //         }
            //     );
            // } else if (this.state.value === 10 && allTruckingTripsData.length !== 0) {
            //     const truckingTrips = allTruckingTripsData.map(item =>
            //         Object.assign({}, item)
            //     );
            //
            //     _.forEach(truckingTrips, (object, index) => {
            //         object.sno = index + 1;
            //     });
            //
            //     this.setState(
            //         {
            //             dataToDownload: truckingTrips,
            //             downloadFileName: "TruckingTrips.csv",
            //             downloadFileHeaders: TruckingTripsHeaders
            //         },
            //         () => {
            //             this.csvLink.link.click();
            //         }
            //     );
        // }
        else {
            this.setState({
                openSearchBeforeDownloadSnackbar: true
            });
        }
    };

    closeNoRecordFoundSnackbar = () => {
        this.setState({openNoRecordFoundSnackbar: false}, () => {
        });
    };

    closeSearchBeforeDownloadSnackbar = () => {
        this.setState({openSearchBeforeDownloadSnackbar: false}, () => {
        });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }
        // In below I am setting the state with destructuring, given both key-value is the same word. So in setState, I just mention the key from the state variable.
        this.setState({order, orderBy});
    };

    handleSearch = () => {
        const {fromDate, toDate, selectPortValue, value} = this.state;
        if (moment(toDate).isAfter(fromDate)) {
            if (selectPortValue !== "") {
                if (value === 0) {
                    axios
                        .get("/api/reports/imports/", {
                            params: {
                                toDate,
                                fromDate,
                                selectPortValue
                            }
                        })
                        .then(res => {
                            if (res.data.length === 0) {
                                this.setState({
                                    openNoRecordFoundSnackbar: true,
                                    allImportsData: [],
                                    allExportsData: [],
                                    allRevenueData: [],
                                    // allOtherVesselsData: [],
                                    // allParcelSizeData: [],
                                    // allPreBerthingWaitingData: [],
                                    // allBerthThroughPutData: [],
                                    // allIncomeAndPortDuesData: [],
                                    // allDevelopmentWorksData: [],
                                    // allIncomeData: [],
                                    // allBerthingTurnAroundTimeData: [],
                                    // allTruckingTripsData: [],
                                });
                            } else {
                                this.setState({
                                    allImportsData: res.data,
                                    allExportsData: [],
                                    allRevenueData: [],
                                    // allOtherVesselsData: [],
                                    // allParcelSizeData: [],
                                    // allPreBerthingWaitingData: [],
                                    // allBerthThroughPutData: [],
                                    // allIncomeAndPortDuesData: [],
                                    // allDevelopmentWorksData: [],
                                    // allIncomeData: [],
                                    // allBerthingTurnAroundTimeData: [],
                                    // allTruckingTripsData: [],
                                });
                            }
                        });
                } else if (value === 1) {
                    axios
                        .get("/api/reports/exports/", {
                            params: {
                                toDate,
                                fromDate,
                                selectPortValue
                            }
                        })
                        .then(res => {
                            if (res.data.length === 0) {
                                this.setState({
                                    openNoRecordFoundSnackbar: true,
                                    allImportsData: [],
                                    allExportsData: [],
                                    allRevenueData: [],
                                    // allOtherVesselsData: [],
                                    // allParcelSizeData: [],
                                    // allPreBerthingWaitingData: [],
                                    // allBerthThroughPutData: [],
                                    // allIncomeAndPortDuesData: [],
                                    // allDevelopmentWorksData: [],
                                    // allIncomeData: [],
                                    // allBerthingTurnAroundTimeData: [],
                                    // allTruckingTripsData: [],
                                });
                            } else {
                                this.setState({
                                    allExportsData: res.data,
                                    allImportsData: [],
                                    allRevenueData: [],
                                    // allOtherVesselsData: [],
                                    // allParcelSizeData: [],
                                    // allPreBerthingWaitingData: [],
                                    // allBerthThroughPutData: [],
                                    // allIncomeAndPortDuesData: [],
                                    // allDevelopmentWorksData: [],
                                    // allIncomeData: [],
                                    // allBerthingTurnAroundTimeData: [],
                                    // allTruckingTripsData: [],
                                });
                            }
                        });
                } else if (value === 2) {
                    axios
                        .get("/api/reports/revenue/", {
                            params: {
                                toDate,
                                fromDate,
                                selectPortValue
                            }
                        })
                        .then(res => {
                            if (res.data.length === 0) {
                                this.setState({
                                    openNoRecordFoundSnackbar: true,
                                    allImportsData: [],
                                    allExportsData: [],
                                    allRevenueData: [],
                                    // allOtherVesselsData: [],
                                    // allParcelSizeData: [],
                                    // allPreBerthingWaitingData: [],
                                    // allBerthThroughPutData: [],
                                    // allIncomeAndPortDuesData: [],
                                    // allDevelopmentWorksData: [],
                                    // allIncomeData: [],
                                    // allBerthingTurnAroundTimeData: [],
                                    // allTruckingTripsData: [],
                                });
                            } else {
                                this.setState({
                                    allRevenueData: res.data,
                                    allImportsData: [],
                                    allExportsData: [],
                                    // allOtherVesselsData: [],
                                    // allParcelSizeData: [],
                                    // allPreBerthingWaitingData: [],
                                    // allBerthThroughPutData: [],
                                    // allIncomeAndPortDuesData: [],
                                    // allDevelopmentWorksData: [],
                                    // allIncomeData: [],
                                    // allBerthingTurnAroundTimeData: [],
                                    // allTruckingTripsData: [],
                                });
                            }
                        });
                }
                // else if (value === 2) {
                //     axios
                //         .get("/api/reports/other-vessels/", {
                //             params: {
                //                 toDate,
                //                 fromDate,
                //                 selectPortValue
                //             }
                //         })
                //         .then(res => {
                //             if (res.data.length === 0) {
                //                 this.setState({
                //                     openNoRecordFoundSnackbar: true,
                //                     allImportsData: [],
                //                     allExportsData: [],
                //                     allOtherVesselsData: [],
                //                     allParcelSizeData: [],
                //                     allPreBerthingWaitingData: [],
                //                     allBerthThroughPutData: [],
                //                     allIncomeAndPortDuesData: [],
                //                     allDevelopmentWorksData: [],
                //                     allIncomeData: [],
                //                     allBerthingTurnAroundTimeData: [],
                //                     allTruckingTripsData: [],
                //                 });
                //             } else {
                //                 this.setState({
                //                     allOtherVesselsData: res.data,
                //                     allImportsData: [],
                //                     allExportsData: [],
                //                     allParcelSizeData: [],
                //                     allPreBerthingWaitingData: [],
                //                     allBerthThroughPutData: [],
                //                     allIncomeAndPortDuesData: [],
                //                     allDevelopmentWorksData: [],
                //                     allIncomeData: [],
                //                     allBerthingTurnAroundTimeData: [],
                //                     allTruckingTripsData: [],
                //                 });
                //             }
                //         });
                // } else if (value === 3) {
                //     axios
                //         .get("/api/reports/parcel-size/", {
                //             params: {
                //                 toDate,
                //                 fromDate,
                //                 selectPortValue
                //             }
                //         })
                //         .then(res => {
                //             if (res.data.length === 0) {
                //                 this.setState({
                //                     openNoRecordFoundSnackbar: true,
                //                     allImportsData: [],
                //                     allExportsData: [],
                //                     allOtherVesselsData: [],
                //                     allParcelSizeData: [],
                //                     allPreBerthingWaitingData: [],
                //                     allBerthThroughPutData: [],
                //                     allIncomeAndPortDuesData: [],
                //                     allDevelopmentWorksData: [],
                //                     allIncomeData: [],
                //                     allBerthingTurnAroundTimeData: [],
                //                     allTruckingTripsData: [],
                //                 });
                //             } else {
                //                 this.setState({
                //                     allParcelSizeData: res.data,
                //                     allImportsData: [],
                //                     allExportsData: [],
                //                     allOtherVesselsData: [],
                //                     allPreBerthingWaitingData: [],
                //                     allBerthThroughPutData: [],
                //                     allIncomeAndPortDuesData: [],
                //                     allDevelopmentWorksData: [],
                //                     allIncomeData: [],
                //                     allBerthingTurnAroundTimeData: [],
                //                     allTruckingTripsData: [],
                //                 });
                //             }
                //         });
                // } else if (value === 4) {
                //     axios
                //         .get("/api/reports/pre-berthing-waiting/", {
                //             params: {
                //                 toDate,
                //                 fromDate,
                //                 selectPortValue
                //             }
                //         })
                //         .then(res => {
                //             if (res.data.length === 0) {
                //                 this.setState({
                //                     openNoRecordFoundSnackbar: true,
                //                     allImportsData: [],
                //                     allExportsData: [],
                //                     allOtherVesselsData: [],
                //                     allParcelSizeData: [],
                //                     allPreBerthingWaitingData: [],
                //                     allBerthThroughPutData: [],
                //                     allIncomeAndPortDuesData: [],
                //                     allDevelopmentWorksData: [],
                //                     allIncomeData: [],
                //                     allBerthingTurnAroundTimeData: [],
                //                     allTruckingTripsData: [],
                //                 });
                //             } else {
                //                 this.setState({
                //                     allPreBerthingWaitingData: res.data,
                //                     allImportsData: [],
                //                     allExportsData: [],
                //                     allOtherVesselsData: [],
                //                     allParcelSizeData: [],
                //                     allBerthThroughPutData: [],
                //                     allIncomeAndPortDuesData: [],
                //                     allDevelopmentWorksData: [],
                //                     allIncomeData: [],
                //                     allBerthingTurnAroundTimeData: [],
                //                     allTruckingTripsData: [],
                //                 });
                //             }
                //         });
                // } else if (value === 5) {
                //     axios
                //         .get("/api/reports/berth-through-put/", {
                //             params: {
                //                 toDate,
                //                 fromDate,
                //                 selectPortValue
                //             }
                //         })
                //         .then(res => {
                //             if (res.data.length === 0) {
                //                 this.setState({
                //                     openNoRecordFoundSnackbar: true,
                //                     allImportsData: [],
                //                     allExportsData: [],
                //                     allOtherVesselsData: [],
                //                     allParcelSizeData: [],
                //                     allPreBerthingWaitingData: [],
                //                     allBerthThroughPutData: [],
                //                     allIncomeAndPortDuesData: [],
                //                     allDevelopmentWorksData: [],
                //                     allIncomeData: [],
                //                     allBerthingTurnAroundTimeData: [],
                //                     allTruckingTripsData: [],
                //                 });
                //             } else {
                //                 this.setState({
                //                     allBerthThroughPutData: res.data,
                //                     allImportsData: [],
                //                     allExportsData: [],
                //                     allOtherVesselsData: [],
                //                     allParcelSizeData: [],
                //                     allPreBerthingWaitingData: [],
                //                     allIncomeAndPortDuesData: [],
                //                     allDevelopmentWorksData: [],
                //                     allIncomeData: [],
                //                     allBerthingTurnAroundTimeData: [],
                //                     allTruckingTripsData: [],
                //                 });
                //             }
                //         });
                // } else if (value === 6) {
                //     axios
                //         .get("/api/reports/income-and-port-dues/", {
                //             params: {
                //                 toDate,
                //                 fromDate,
                //                 selectPortValue
                //             }
                //         })
                //         .then(res => {
                //             if (res.data.length === 0) {
                //                 this.setState({
                //                     openNoRecordFoundSnackbar: true,
                //                     allImportsData: [],
                //                     allExportsData: [],
                //                     allOtherVesselsData: [],
                //                     allParcelSizeData: [],
                //                     allPreBerthingWaitingData: [],
                //                     allIncomeAndPortDuesData: [],
                //                     allBerthThroughPutData: [],
                //                     allDevelopmentWorksData: [],
                //                     allIncomeData: [],
                //                     allBerthingTurnAroundTimeData: [],
                //                     allTruckingTripsData: [],
                //                 });
                //             } else {
                //                 this.setState({
                //                     allIncomeAndPortDuesData: res.data,
                //                     allImportsData: [],
                //                     allExportsData: [],
                //                     allOtherVesselsData: [],
                //                     allParcelSizeData: [],
                //                     allPreBerthingWaitingData: [],
                //                     allBerthThroughPutData: [],
                //                     allDevelopmentWorksData: [],
                //                     allIncomeData: [],
                //                     allBerthingTurnAroundTimeData: [],
                //                     allTruckingTripsData: [],
                //                 });
                //             }
                //         });
                // } else if (value === 7) {
                //     axios
                //         .get("/api/reports/development-works/", {
                //             params: {
                //                 toDate,
                //                 fromDate,
                //                 selectPortValue
                //             }
                //         })
                //         .then(res => {
                //             if (res.data.length === 0) {
                //                 this.setState({
                //                     openNoRecordFoundSnackbar: true,
                //                     allImportsData: [],
                //                     allExportsData: [],
                //                     allOtherVesselsData: [],
                //                     allParcelSizeData: [],
                //                     allPreBerthingWaitingData: [],
                //                     allBerthThroughPutData: [],
                //                     allDevelopmentWorksData: [],
                //                     allIncomeAndPortDuesData: [],
                //                     allIncomeData: [],
                //                     allBerthingTurnAroundTimeData: [],
                //                     allTruckingTripsData: [],
                //                 });
                //             } else {
                //                 this.setState({
                //                     allDevelopmentWorksData: res.data,
                //                     allImportsData: [],
                //                     allExportsData: [],
                //                     allOtherVesselsData: [],
                //                     allParcelSizeData: [],
                //                     allPreBerthingWaitingData: [],
                //                     allBerthThroughPutData: [],
                //                     allIncomeAndPortDuesData: [],
                //                     allIncomeData: [],
                //                     allBerthingTurnAroundTimeData: [],
                //                     allTruckingTripsData: [],
                //                 });
                //             }
                //         });
                // } else if (value === 8) {
                //     axios
                //         .get("/api/reports/income/", {
                //             params: {
                //                 toDate,
                //                 fromDate,
                //                 selectPortValue
                //             }
                //         })
                //         .then(res => {
                //             if (res.data.length === 0) {
                //                 this.setState({
                //                     openNoRecordFoundSnackbar: true,
                //                     allImportsData: [],
                //                     allExportsData: [],
                //                     allOtherVesselsData: [],
                //                     allParcelSizeData: [],
                //                     allPreBerthingWaitingData: [],
                //                     allBerthThroughPutData: [],
                //                     allDevelopmentWorksData: [],
                //                     allIncomeAndPortDuesData: [],
                //                     allIncomeData: [],
                //                     allBerthingTurnAroundTimeData: [],
                //                     allTruckingTripsData: [],
                //                 });
                //             } else {
                //                 this.setState({
                //                     allIncomeData: res.data,
                //                     allDevelopmentWorksData: [],
                //                     allImportsData: [],
                //                     allExportsData: [],
                //                     allOtherVesselsData: [],
                //                     allParcelSizeData: [],
                //                     allPreBerthingWaitingData: [],
                //                     allBerthThroughPutData: [],
                //                     allIncomeAndPortDuesData: [],
                //                     allBerthingTurnAroundTimeData: [],
                //                     allTruckingTripsData: [],
                //                 });
                //             }
                //         });
                // } else if (value === 9) {
                //     axios
                //         .get("/api/reports/berthingTurnAroundTime/", {
                //             params: {
                //                 toDate,
                //                 fromDate,
                //                 selectPortValue
                //             }
                //         })
                //         .then(res => {
                //             if (res.data.length === 0) {
                //                 this.setState({
                //                     openNoRecordFoundSnackbar: true,
                //                     allImportsData: [],
                //                     allExportsData: [],
                //                     allOtherVesselsData: [],
                //                     allParcelSizeData: [],
                //                     allPreBerthingWaitingData: [],
                //                     allBerthThroughPutData: [],
                //                     allDevelopmentWorksData: [],
                //                     allIncomeAndPortDuesData: [],
                //                     allIncomeData: [],
                //                     allBerthingTurnAroundTimeData: [],
                //                     allTruckingTripsData: [],
                //                 });
                //             } else {
                //                 this.setState({
                //                     allBerthingTurnAroundTimeData: res.data,
                //                     allIncomeData: [],
                //                     allDevelopmentWorksData: [],
                //                     allImportsData: [],
                //                     allExportsData: [],
                //                     allOtherVesselsData: [],
                //                     allParcelSizeData: [],
                //                     allPreBerthingWaitingData: [],
                //                     allBerthThroughPutData: [],
                //                     allIncomeAndPortDuesData: [],
                //                     allTruckingTripsData: [],
                //                 });
                //             }
                //         });
                // } else if (value === 10) {
                //     axios
                //         .get("/api/reports/truckingTrips/", {
                //             params: {
                //                 toDate,
                //                 fromDate,
                //                 selectPortValue
                //             }
                //         })
                //         .then(res => {
                //             if (res.data.length === 0) {
                //                 this.setState({
                //                     openNoRecordFoundSnackbar: true,
                //                     allImportsData: [],
                //                     allExportsData: [],
                //                     allOtherVesselsData: [],
                //                     allParcelSizeData: [],
                //                     allPreBerthingWaitingData: [],
                //                     allBerthThroughPutData: [],
                //                     allDevelopmentWorksData: [],
                //                     allIncomeAndPortDuesData: [],
                //                     allIncomeData: [],
                //                     allBerthingTurnAroundTimeData: [],
                //                     allTruckingTripsData: [],
                //                 });
                //             } else {
                //                 this.setState({
                //                     allTruckingTripsData: res.data,
                //                     allIncomeData: [],
                //                     allDevelopmentWorksData: [],
                //                     allImportsData: [],
                //                     allExportsData: [],
                //                     allOtherVesselsData: [],
                //                     allParcelSizeData: [],
                //                     allPreBerthingWaitingData: [],
                //                     allBerthThroughPutData: [],
                //                     allIncomeAndPortDuesData: [],
                //                     allBerthingTurnAroundTimeData: [],
                //                 });
                //             }
                //         });
                // }
            } else {
                this.setState({
                    errorSelectPortValue: "Please select a Port"
                });
            }
        } else {
            this.setState({
                errorToDate: "To date should be greater than from date"
            });
        }
    };

    render() {
        const {classes} = this.props;
        const {
            value,
            order,
            orderBy,
            fromDate,
            toDate,
            portListData,
            portName,
            selectPortValue,
            errorToDate,
            errorFromDate,
            errorSelectPortValue,
            dataToDownload,
            downloadFileName,
            downloadFileHeaders,
            allImportsData,
            allExportsData,
            allRevenueData,
            // allOtherVesselsData,
            // allParcelSizeData,
            // allPreBerthingWaitingData,
            // allBerthThroughPutData,
            // allIncomeAndPortDuesData,
            // allDevelopmentWorksData,
            // allIncomeData,
            // allBerthingTurnAroundTimeData,
            // allTruckingTripsData,
        } = this.state;
        return (
            <MuiThemeProvider>
                <div>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>Ap Ports Reports</title>
                        <meta name="description" content="AP Maritime Board"/>
                        <meta name="theme-color" content="#008f68"/>
                        <body/>
                    </Helmet>
                    <Row>
                        <Col xs="12">
                            <Paper className={classes.rootPaperOne}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className={classes.dateFromAndTo}>
                                        <Grid
                                            container
                                            className={classes.grid}
                                            justify="space-around"
                                        >
                                            <DatePicker
                                                autoOk
                                                margin="none"
                                                label="From Date Picker"
                                                value={fromDate}
                                                onChange={this.handleFromDateChange}
                                                error={errorFromDate.length !== 0}
                                                helperText={errorFromDate}
                                            />
                                        </Grid>

                                        <Grid
                                            container
                                            className={classes.grid}
                                            justify="space-around"
                                        >
                                            <DatePicker
                                                autoOk
                                                margin="none"
                                                label="To Date picker"
                                                value={toDate}
                                                onChange={this.handleToDateChange}
                                                error={errorToDate.length !== 0}
                                                helperText={errorToDate}
                                            />
                                        </Grid>

                                        {JSON.parse(localStorage.getItem("user")) &&
                                        JSON.parse(localStorage.getItem("user")).userType ===
                                        "port-operator" ? (
                                            <Typography variant="h3" className={classes.portNameText}>
                                                Port Name : {portName}
                                            </Typography>
                                        ) : (
                                            <TextField
                                                id="standard-select-currency"
                                                select
                                                label="Select"
                                                className={classes.textFieldPort}
                                                value={selectPortValue}
                                                onChange={e => {
                                                    if (e.target.value === "") {
                                                        this.setState({
                                                            errorSelectPortValue: "Please Select a Port",
                                                            selectPortValue: e.target.value
                                                        });
                                                    } else {
                                                        this.setState({
                                                            selectPortValue: e.target.value,
                                                            errorSelectPortValue: ""
                                                        });
                                                    }
                                                }}
                                                SelectProps={{
                                                    MenuProps: {
                                                        className: classes.menu
                                                    }
                                                }}
                                                error={errorSelectPortValue.length !== 0}
                                                helperText={errorSelectPortValue}
                                                margin="none"
                                            >
                                                {portListData.map(option => (
                                                    <MenuItem key={option._id} value={option._id}>
                                                        <Typography variant="h3">{option.port_name}</Typography>
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}

                                        <Button
                                            variant="contained"
                                            size="medium"
                                            color="primary"
                                            className={classes.buttonMargin}
                                            onClick={this.handleSearch}
                                        >
                                            Search
                                        </Button>
                                    </div>
                                </MuiPickersUtilsProvider>
                            </Paper>

                            <Paper className={classes.rootTabsTable}>
                                <div className={classes.rootPaperTwo}>
                                    <AppBar
                                        position="static"
                                        color="default"
                                        className={classes.tabsAppBar}
                                    >
                                        <Tabs
                                            value={value}
                                            onChange={this.handleChange}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            variant="scrollable"
                                            scrollButtons="on"
                                        >
                                            <Tab label={<Typography variant="h3">IMPORTS</Typography>}/>
                                            <Tab label={<Typography variant="h3">EXPORTS</Typography>}/>
                                            <Tab label={<Typography variant="h3">REVENUE</Typography>}/>
                                            {/*<Tab label={<Typography variant="h3">OTHER VESSELS</Typography>}/>*/}
                                            {/*<Tab label={<Typography variant="h3">PARCEL SIZE</Typography>}/>*/}
                                            {/*<Tab label={<Typography variant="h3">AVG PRE-BERTHING WAITING</Typography>}/>*/}
                                            {/*<Tab label={<Typography variant="h3">AVG. SHIP BERTH THROUGHPUT</Typography>}/>*/}
                                            {/*<Tab label={<Typography variant="h3">INCOME AND PORT DUE</Typography>}/>*/}
                                            {/*<Tab label={<Typography variant="h3">DEVELOPMENT WORKS</Typography>}/>*/}
                                            {/*<Tab label={<Typography variant="h3">INCOME</Typography>}/>*/}
                                            {/*<Tab label={<Typography variant="h3">BERTHING TURN AROUND TIME</Typography>}/>*/}
                                            {/*<Tab label={<Typography variant="h3">TRUCKING TRIPS</Typography>}/>*/}
                                        </Tabs>
                                    </AppBar>

                                    {value === 0 && (
                                        <TabContainer>
                                            <Table className={classes.table}>
                                                <EnhancedTableHead
                                                    tabValue={value}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={this.handleRequestSort}
                                                />
                                                <CommonTableBody
                                                    tabValue={value}
                                                    dataToRender={allImportsData}
                                                    order={order}
                                                    orderBy={orderBy}
                                                />
                                            </Table>
                                        </TabContainer>
                                    )}

                                    {value === 1 && (
                                        <TabContainer>
                                            <Table className={classes.table}>
                                                <EnhancedTableHead
                                                    tabValue={value}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={this.handleRequestSort}
                                                />
                                                <CommonTableBody
                                                    tabValue={value}
                                                    dataToRender={allExportsData}
                                                    order={order}
                                                    orderBy={orderBy}
                                                />
                                            </Table>
                                        </TabContainer>
                                    )}

                                    {value === 2 && (
                                        <TabContainer>
                                            <Table className={classes.table}>
                                                <EnhancedTableHead
                                                    tabValue={value}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={this.handleRequestSort}
                                                />
                                                <CommonTableBody
                                                    tabValue={value}
                                                    dataToRender={allRevenueData}
                                                    order={order}
                                                    orderBy={orderBy}
                                                />
                                            </Table>
                                        </TabContainer>
                                    )}

                                    {/*{value === 2 && (*/}
                                    {/*    <TabContainer>*/}
                                    {/*        <Table className={classes.table}>*/}
                                    {/*            <EnhancedTableHead*/}
                                    {/*                tabValue={value}*/}
                                    {/*                order={order}*/}
                                    {/*                orderBy={orderBy}*/}
                                    {/*                onRequestSort={this.handleRequestSort}*/}
                                    {/*            />*/}
                                    {/*            <CommonTableBody*/}
                                    {/*                tabValue={value}*/}
                                    {/*                dataToRender={allOtherVesselsData}*/}
                                    {/*                order={order}*/}
                                    {/*                orderBy={orderBy}*/}
                                    {/*            />*/}
                                    {/*        </Table>*/}
                                    {/*    </TabContainer>*/}
                                    {/*)}*/}

                                    {/*{value === 3 && (*/}
                                    {/*    <TabContainer>*/}
                                    {/*        <Table className={classes.table}>*/}
                                    {/*            <EnhancedTableHead*/}
                                    {/*                tabValue={value}*/}
                                    {/*                order={order}*/}
                                    {/*                orderBy={orderBy}*/}
                                    {/*                onRequestSort={this.handleRequestSort}*/}
                                    {/*            />*/}
                                    {/*            <CommonTableBody*/}
                                    {/*                tabValue={value}*/}
                                    {/*                dataToRender={allParcelSizeData}*/}
                                    {/*                order={order}*/}
                                    {/*                orderBy={orderBy}*/}
                                    {/*            />*/}
                                    {/*        </Table>*/}
                                    {/*    </TabContainer>*/}
                                    {/*)}*/}

                                    {/*{value === 4 && (*/}
                                    {/*    <TabContainer>*/}
                                    {/*        <Table className={classes.table}>*/}
                                    {/*            <EnhancedTableHead*/}
                                    {/*                tabValue={value}*/}
                                    {/*                order={order}*/}
                                    {/*                orderBy={orderBy}*/}
                                    {/*                onRequestSort={this.handleRequestSort}*/}
                                    {/*            />*/}
                                    {/*            <CommonTableBody*/}
                                    {/*                tabValue={value}*/}
                                    {/*                dataToRender={allPreBerthingWaitingData}*/}
                                    {/*                order={order}*/}
                                    {/*                orderBy={orderBy}*/}
                                    {/*            />*/}
                                    {/*        </Table>*/}
                                    {/*    </TabContainer>*/}
                                    {/*)}*/}

                                    {/*{value === 5 && (*/}
                                    {/*    <TabContainer>*/}
                                    {/*        <Table className={classes.table}>*/}
                                    {/*            <EnhancedTableHead*/}
                                    {/*                tabValue={value}*/}
                                    {/*                order={order}*/}
                                    {/*                orderBy={orderBy}*/}
                                    {/*                onRequestSort={this.handleRequestSort}*/}
                                    {/*            />*/}
                                    {/*            <CommonTableBody*/}
                                    {/*                tabValue={value}*/}
                                    {/*                dataToRender={allBerthThroughPutData}*/}
                                    {/*                order={order}*/}
                                    {/*                orderBy={orderBy}*/}
                                    {/*            />*/}
                                    {/*        </Table>*/}
                                    {/*    </TabContainer>*/}
                                    {/*)}*/}

                                    {/*{value === 6 && (*/}
                                    {/*    <TabContainer>*/}
                                    {/*        <Table className={classes.table}>*/}
                                    {/*            <EnhancedTableHead*/}
                                    {/*                tabValue={value}*/}
                                    {/*                order={order}*/}
                                    {/*                orderBy={orderBy}*/}
                                    {/*                onRequestSort={this.handleRequestSort}*/}
                                    {/*            />*/}
                                    {/*            <CommonTableBody*/}
                                    {/*                tabValue={value}*/}
                                    {/*                dataToRender={allIncomeAndPortDuesData}*/}
                                    {/*                order={order}*/}
                                    {/*                orderBy={orderBy}*/}
                                    {/*            />*/}
                                    {/*        </Table>*/}
                                    {/*    </TabContainer>*/}
                                    {/*)}*/}

                                    {/*{value === 7 && (*/}
                                    {/*    <TabContainer>*/}
                                    {/*        <Table className={classes.table}>*/}
                                    {/*            <EnhancedTableHead*/}
                                    {/*                tabValue={value}*/}
                                    {/*                order={order}*/}
                                    {/*                orderBy={orderBy}*/}
                                    {/*                onRequestSort={this.handleRequestSort}*/}
                                    {/*            />*/}
                                    {/*            <CommonTableBody*/}
                                    {/*                tabValue={value}*/}
                                    {/*                dataToRender={allDevelopmentWorksData}*/}
                                    {/*                order={order}*/}
                                    {/*                orderBy={orderBy}*/}
                                    {/*            />*/}
                                    {/*        </Table>*/}
                                    {/*    </TabContainer>*/}
                                    {/*)}*/}

                                    {/*{value === 8 && (*/}
                                    {/*    <TabContainer>*/}
                                    {/*        <Table className={classes.table}>*/}
                                    {/*            <EnhancedTableHead*/}
                                    {/*                tabValue={value}*/}
                                    {/*                order={order}*/}
                                    {/*                orderBy={orderBy}*/}
                                    {/*                onRequestSort={this.handleRequestSort}*/}
                                    {/*            />*/}
                                    {/*            <CommonTableBody*/}
                                    {/*                tabValue={value}*/}
                                    {/*                dataToRender={allIncomeData}*/}
                                    {/*                order={order}*/}
                                    {/*                orderBy={orderBy}*/}
                                    {/*            />*/}
                                    {/*        </Table>*/}
                                    {/*    </TabContainer>*/}
                                    {/*)}*/}

                                    {/*{value === 9 && (*/}
                                    {/*    <TabContainer>*/}
                                    {/*        <Table className={classes.table}>*/}
                                    {/*            <EnhancedTableHead*/}
                                    {/*                tabValue={value}*/}
                                    {/*                order={order}*/}
                                    {/*                orderBy={orderBy}*/}
                                    {/*                onRequestSort={this.handleRequestSort}*/}
                                    {/*            />*/}
                                    {/*            <CommonTableBody*/}
                                    {/*                tabValue={value}*/}
                                    {/*                dataToRender={allBerthingTurnAroundTimeData}*/}
                                    {/*                order={order}*/}
                                    {/*                orderBy={orderBy}*/}
                                    {/*            />*/}
                                    {/*        </Table>*/}
                                    {/*    </TabContainer>*/}
                                    {/*)}*/}

                                    {/*{value === 10 && (*/}
                                    {/*    <TabContainer>*/}
                                    {/*        <Table className={classes.table}>*/}
                                    {/*            <EnhancedTableHead*/}
                                    {/*                tabValue={value}*/}
                                    {/*                order={order}*/}
                                    {/*                orderBy={orderBy}*/}
                                    {/*                onRequestSort={this.handleRequestSort}*/}
                                    {/*            />*/}
                                    {/*            <CommonTableBody*/}
                                    {/*                tabValue={value}*/}
                                    {/*                dataToRender={allTruckingTripsData}*/}
                                    {/*                order={order}*/}
                                    {/*                orderBy={orderBy}*/}
                                    {/*            />*/}
                                    {/*        </Table>*/}
                                    {/*    </TabContainer>*/}
                                    {/*)}*/}

                                </div>
                            </Paper>
                        </Col>
                    </Row>
                    <NoRecordsFoundSnackbar
                        openNoRecordFoundSnackbar={this.state.openNoRecordFoundSnackbar}
                        closeNoRecordFoundSnackbar={this.closeNoRecordFoundSnackbar}
                    />
                    <SearchBeforeDownload
                        openSearchBeforeDownloadSnackbar={
                            this.state.openSearchBeforeDownloadSnackbar
                        }
                        closeSearchBeforeDownloadSnackbar={
                            this.closeSearchBeforeDownloadSnackbar
                        }
                    />
                    <div className="float-sm-right m-b-sm">
                        <Tooltip title="Download">
                            <Fab
                                onClick={this.handleDataDownload}
                                aria-pressed="true"
                                color="secondary"
                                size="large"
                                aria-label="Download"
                                fontSize="large"
                                className={classes.fabButton}
                            >
                                <DownloadIcon/>
                            </Fab>
                        </Tooltip>
                    </div>
                    <CSVLink
                        data={dataToDownload}
                        headers={downloadFileHeaders}
                        filename={downloadFileName}
                        ref={r => (this.csvLink = r)}
                    />
                </div>
            </MuiThemeProvider>
        );
    }
}

ReportsList.propTypes = {
    classes: PropTypes.object.isRequired
};

const combinedStyles = combineStyles(styles, toolbarStyles);

export default withStyles(combinedStyles)(ReportsList);
